import React from "react";
import { PDFViewer, Document, Page, Text, Font } from "@react-pdf/renderer";
import backIcon from "../../assets/back_icon.svg";
import BarcodeComponent from "./LabelComponentsForPDFS/BarcodeComponent";
import RectangleComponent from "./LabelComponentsForPDFS/Rectangle";
import TextComponent from "./LabelComponentsForPDFS/TextComponent";
import RepeaterComponent from "./LabelComponentsForPDFS/RepeaterComponent";
import ImageComponent from "./LabelComponentsForPDFS/ImageComponent";
import styled from "styled-components";

// Register fonts
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/fonts/Poppins-Regular.ttf",
      fontWeight: "normal",
    },
    {
      src: "/fonts/Poppins-Light.ttf",
      fontWeight: "300",
    },
  ],
});

//region CSS
const MyView = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const GeneratePDFLabels = ({
  arrayOfRowsForPrint,
  mappedLabels,
  clickedLabel,
  onGeneratePDFLabels,
  barcodeStringSVGS,
}) => {
  const PDF_SIZE_FACTOR = 0.76;

  const title = clickedLabel?.name;

  const getRectangleComponent = (node, children, selectedObjectBorderColor) => {
    return (
      <RectangleComponent key={node.nodeId} node={node}>
        {children}
      </RectangleComponent>
    );
  };

  const getBarcodeComponent = (node, children) => {
    return (
      <BarcodeComponent
        key={node.nodeId}
        node={node}
        barcodeStringSVGS={barcodeStringSVGS}
        PDF_SIZE_FACTOR={PDF_SIZE_FACTOR}
      >
        {children}
      </BarcodeComponent>
    );
  };

  const getTextComponent = (node, children) => {
    return (
      <TextComponent
        key={node.nodeId}
        node={node}
        PDF_SIZE_FACTOR={PDF_SIZE_FACTOR}
      >
        {children}
      </TextComponent>
    );
  };

  const getRepeaterComponent = (
    node,
    children,
    ghost = false,
    ghostIndex = -1
  ) => {
    return (
      <RepeaterComponent
        key={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        keyValue={ghostIndex === -1 ? node.nodeId : `ghost-${ghostIndex}`}
        node={node}
        children={children}
        ghost={ghost}
        ghostIndex={ghostIndex}
        arrayOfRowsForPrint={arrayOfRowsForPrint}
      >
        {children}
      </RepeaterComponent>
    );
  };

  const getImageComponent = (node, children) => {
    return (
      <ImageComponent
        key={node.nodeId}
        node={node}
        PDF_SIZE_FACTOR={PDF_SIZE_FACTOR}
      >
        {children}
      </ImageComponent>
    );
  };

  // Traverse node tree recursively, starting with label child nodes
  const traverseLabelChildren = (node) => {
    if (node.childNodes.length) {
      const NodeComponents = node.childNodes.map((childNode) => {
        if (childNode.type === "childRectangle") {
          return getRectangleComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        }
        if (childNode.type === "barcode") {
          return getBarcodeComponent(
            childNode,
            traverseLabelChildren(childNode)
          );
        } else if (childNode.type === "text") {
          return getTextComponent(childNode, traverseLabelChildren(childNode));
        } else if (childNode.type === "repeater") {
          let content = [
            getRepeaterComponent(
              childNode,
              traverseLabelChildren(childNode),
              false,
              -1,
              true
            ),
          ];
          for (let i = 0; i < childNode.repeatCount; i++) {
            content.push(
              getRepeaterComponent(
                childNode,
                traverseLabelChildren(childNode),
                true,
                i
              )
            );
          }
          return content;
        } else if (childNode.type === "image") {
          return getImageComponent(childNode, traverseLabelChildren(childNode));
        }
      });
      return NodeComponents;
    } else {
      return;
    }
  };

  return (
    // create label and start recursive traversal across all child nodes

    <MyView>
      <Header>
        <TitleGroup>
          <BackButton
            src={backIcon}
            onClick={() => onGeneratePDFLabels(false)}
          />
          <ReportTitle>PDF Viewer</ReportTitle>
        </TitleGroup>
      </Header>
      <PDFViewer style={{ height: "100%" }}>
        <Document title={title} style={{ height: "100%" }}>
          {mappedLabels.map((mappedLabel, index) => {
            return (
              <Page
                key={index}
                size="LETTER"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  boxSizing: "border-box",
                  // padding: "22px",
                }}
              >
                {getRectangleComponent(
                  mappedLabel.template,
                  traverseLabelChildren(mappedLabel.template)
                )}
                <Text
                  style={{
                    margin: 0,
                    fontStyle: "normal",
                    fontWeight: 300,
                    fontSize: "8px",
                    fontFamily: "Poppins",
                    color: "#121A2D",
                    position: "absolute",
                    bottom: 4,
                    left: 0,
                    right: 0,
                    textAlign: "center",
                  }}
                  render={() => `${index + 1} / ${mappedLabels.length}`}
                  fixed
                />
              </Page>
            );
          })}
        </Document>
      </PDFViewer>
    </MyView>
  );
};

export default GeneratePDFLabels;
