import React, { useEffect, useState, Fragment } from "react";
import TableMain from "./TableMain";
import TableSub from "./TableSub";
import SummaryBagReportPDF from "./SummaryBagReportPDF";
import Loader from "react-loader-spinner";
import SearchBar from "../../../../components/SearchBar";
import SFDropDownList from "../../../../components/SFDropDownList/SFDropDownList";
import styles from "./SummaryBagReport.module.css";

const SummaryBagReport = ({
  reportObj,
  reportData,
  onShowPDFViewer,
  selectionRange,
  onResetSelectionDateRange,
  fetchingCustomReportData,
  onFetchingCustomReportData,
}) => {
  function compare(a, b) {
    if (a[0] < b[0]) {
      return -1;
    }
    if (a[0] > b[0]) {
      return 1;
    }
    if (a[0] === b[0]) {
      if (a[1] < b[1]) {
        return -1;
      }
      if (a[1] > b[1]) {
        return 1;
      }
      if (a[1] === b[1]) {
        if (a[5].toUpperCase() < b[5].toUpperCase()) {
          return -1;
        }
        if (a[5].toUpperCase() > b[5].toUpperCase()) {
          return 1;
        }
        return 0;
      }
    }
    return 0;
  }
  const [sortedReportData, setSortedReportData] = useState(null);
  const [viewData, setViewData] = useState([]);
  const [selectedBatchIndex, setSelectedBatchIndex] = useState(-1);
  const [filterText, setFilterText] = useState("");
  const [barcodeValueType, setBarcodeValueType] = useState("Rx Number");
  const [barcodeSymbology, setBarcodeSymbology] = useState("Code 128B");

  useEffect(() => {
    if (reportData) {
      let tempSortedReportData = [...reportData].sort(compare);
      let checker = (arr, target) =>
        target.slice(0, 11).every((v) => arr.slice(0, 11).includes(v));
      let tempSortedReportDataWithoutRepeats = [];
      let parentIndex = 0;
      tempSortedReportData.forEach((dataArray, dataArrayIndex) => {
        if (dataArrayIndex === 0 && parentIndex === 0) {
          tempSortedReportDataWithoutRepeats.push(dataArray);
        } else {
          if (checker(dataArray, tempSortedReportData[parentIndex])) {
            tempSortedReportData[parentIndex][12] += dataArray[12];
          } else {
            tempSortedReportDataWithoutRepeats.push(dataArray);
            parentIndex = dataArrayIndex;
          }
        }
      });

      // Filter out all data except with packaging type of POUCH
      const tempSortedReportDataFiltered =
        tempSortedReportDataWithoutRepeats.filter((dataRow) => {
          return dataRow[13].toUpperCase() === "POUCH";
        });

      setSortedReportData(tempSortedReportDataFiltered);
    }
  }, [reportData]);

  let symbology;

  switch (barcodeSymbology) {
    case "Code 128B":
      symbology = "Code128B";
      break;
    case "Code 39":
      symbology = "Code39";
      break;
    case "Data Matrix":
      symbology = "DataMatrix";
      break;
    case "QR Barcode":
      symbology = "QRBarcode";
      break;
    default:
    // code block
  }

  let barcodeIndex;
  if (barcodeValueType === "Rx Number") {
    barcodeIndex = 7;
  } else {
    barcodeIndex = 8;
  }

  useEffect(() => {
    let tempViewData = [];
    if (sortedReportData) {
      sortedReportData.forEach((dataItem, index) => {
        const packagedOn = dataItem[11];
        const packagedOnFormatted = `${packagedOn.split("T")[0]}, ${packagedOn
          .split("T")[1]
          .slice(0, 5)}`;

        let viewDataObj = {};
        let batchFound = false;
        for (let i = 0; i < tempViewData.length; i++) {
          if (index !== 0 && dataItem[0] === tempViewData[i].batch) {
            tempViewData[i].data = [
              ...tempViewData[i].data,
              [...dataItem.slice(1), 0],
            ];
            batchFound = true;
            break;
          }
        }
        if (!batchFound) {
          viewDataObj.batch = dataItem[0];
          viewDataObj.packagedOnFormatted = packagedOnFormatted;
          viewDataObj.data = [[...dataItem.slice(1), 0]];
          tempViewData.push(viewDataObj);
        }
      });
      setViewData([...tempViewData]);
      onFetchingCustomReportData(false);
    }
  }, [sortedReportData]);

  useEffect(() => {
    handleResetSortedReportData();
    handleResetViewData();
  }, [selectionRange]);

  const loader = (
    <div className={styles.loader}>
      <Loader type="Rings" color="#089BAB" height={110} width={110} />
      <p>Fetching data...</p>
    </div>
  );

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const handlePrintBatch = (batchIndex) => {
    setSelectedBatchIndex(batchIndex);
  };

  const handleUpdateQtyDestroyed = (batchIndex, dataIndex, qtyDestroyed) => {
    let tempViewData = [...viewData];
    tempViewData[batchIndex].data[dataIndex][10] = Number(qtyDestroyed);
    setViewData(tempViewData);
  };

  const handleResetSortedReportData = () => {
    setSortedReportData(null);
  };

  const handleResetViewData = () => {
    setViewData([]);
  };

  const handleSelectedBatchIndex = (val) => {
    setSelectedBatchIndex(val);
  };

  const handleSelectedBarcodeValue = (barcodeValue) => {
    setBarcodeValueType(barcodeValue);
  };

  const handleSelectedBarcodeSymbology = (barcodeSymbology) => {
    setBarcodeSymbology(barcodeSymbology);
  };

  const barcodeValueSelectionDropdownList = [
    { Value: 1, Item: "NDC" },
    { Value: 2, Item: "Rx Number" },
  ];

  const barcodeSymbologySelectionDropdownList = [
    { Item: "Code 128B", Value: "Code128B" },
    { Item: "Code 39", Value: "Code39" },
    { Item: "Data Matrix", Value: "DataMatrix" },
    { Item: "QR Barcode", Value: "QRBarcode" },
  ];

  let SearchBarContainer = (
    <div className={styles.searchBarContainer}>
      <SearchBar
        placeholder={"Search by batch"}
        parentFilterText={filterText}
        onSearch={handleSearch}
        focus={true}
      />
    </div>
  );

  let BarcodeValueSelection = (
    <div className={styles.barcodeValueSelectionContainer}>
      <SFDropDownList
        disabled={true}
        id={"barcodeValueSelection"}
        label={"Barcode Value"}
        defaultValue={barcodeValueType}
        placeHolder={"Select Site"}
        dropDownList={barcodeValueSelectionDropdownList}
        onSelection={handleSelectedBarcodeValue}
      />
    </div>
  );

  let BarcodeSymbologySelection = (
    <div className={styles.barcodeSymbologySelectionContainer}>
      <SFDropDownList
        disabled={true}
        id={"barcodeSymbologySelection"}
        label={"Barcode Symbology"}
        defaultValue={barcodeSymbology}
        placeHolder={"Select Site"}
        dropDownList={barcodeSymbologySelectionDropdownList}
        onSelection={handleSelectedBarcodeSymbology}
      />
    </div>
  );

  if (selectedBatchIndex === -1) {
    return (
      <div className={styles.reportContainer}>
        {SearchBarContainer}
        {BarcodeValueSelection}
        {BarcodeSymbologySelection}
        {fetchingCustomReportData && loader}
        {viewData
          .filter(
            (viewDataRow) =>
              !filterText || viewDataRow.batch.includes(filterText)
          )
          .map((viewDataRow, batchIndex) => {
            let showHeadings = true;
            return (
              <div
                key={viewDataRow.batch}
                className={styles.batchContainer}
                style={
                  fetchingCustomReportData ? { visibility: "hidden" } : null
                }
              >
                {viewDataRow.data.map((viewDataRowDataArray, dataIndex) => {
                  const packagedOn = viewDataRow.data[dataIndex][10];
                  const packagedOnFormatted = `${
                    packagedOn.split("T")[0]
                  }, ${packagedOn.split("T")[1].slice(0, 5)}`;
                  if (
                    dataIndex > 0 &&
                    viewDataRowDataArray[0] ===
                      viewDataRow.data[dataIndex - 1][0]
                  ) {
                    showHeadings = false;
                  } else {
                    showHeadings = true;
                  }
                  return (
                    <Fragment key={viewDataRowDataArray[1] + dataIndex}>
                      <TableMain
                        showBatch={dataIndex === 0 ? true : false}
                        showHeadings={showHeadings}
                        batch={viewDataRow.batch}
                        batchIndex={batchIndex}
                        onPrintBatch={handlePrintBatch}
                        patientName={viewDataRow.data[dataIndex][0]}
                        patientId={viewDataRow.data[dataIndex][1]}
                        facility={viewDataRow.data[dataIndex][2]}
                        room={viewDataRow.data[dataIndex][3]}
                        packagedOnFormatted={packagedOnFormatted}
                      />
                      <TableSub
                        showHeadings={showHeadings}
                        product={viewDataRow.data[dataIndex][4]}
                        productStrength={viewDataRow.data[dataIndex][5]}
                        rxNumber={viewDataRow.data[dataIndex][6]}
                        packageId={viewDataRow.data[dataIndex][7]}
                        physician={viewDataRow.data[dataIndex][8]}
                        productComments={viewDataRow.data[dataIndex][9]}
                        qtyNeeded={viewDataRow.data[dataIndex][11]}
                        onUpdateQtyDestroyed={handleUpdateQtyDestroyed}
                        batchIndex={batchIndex}
                        dataIndex={dataIndex}
                        viewData={viewData}
                      />
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
      </div>
    );
  }

  return (
    <SummaryBagReportPDF
      selectedViewData={
        viewData.filter(
          (viewDataRow) => !filterText || viewDataRow.batch.includes(filterText)
        )[selectedBatchIndex]
      }
      onShowPDFViewer={onShowPDFViewer}
      onSelectedBatchIndex={handleSelectedBatchIndex}
      selectionRange={selectionRange}
      barcodeIndex={barcodeIndex - 1}
      symbology={symbology}
      mainHeadings={[
        "Batch",
        "Patient's Name",
        "Patient ID",
        "Facility",
        "Room",
      ]}
      subHeadings={[
        "Product",
        "Rx Number",
        "NDC",
        "Barcode",
        "Prescriber",
        "Total Qty",
        "Qty Destroyed",
      ]}
    />
  );
};

export default SummaryBagReport;
