import React, { useState, useEffect, useRef, useContext } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { useTranslation } from "react-i18next";
import ProductService from "../../../services/ProductService";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import ErrorBanner from "../../../components/ErrorBanner";
import styles from "./SubstituteProduct.module.css";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const SubstituteProduct = ({
  user,
  pendDisOrderControllerRef,
  error,
  updateTable,
  handleUpdateTable,
  selectedItem,
  modifiedItem,
  handleModifiedItem,
  onCloseSubstituteProductModal,
  onUpdateItem,
}) => {
  const [equivalencies, setEquivalencies] = useState([]);
  const [
    equivalencyControllerCallFinished,
    setEquivalencyControllerCallFinished,
  ] = useState(false);
  const [listOfEquivProductDescriptions, setListOfEquivProductDescriptions] =
    useState([]);
  const [userError, setUserError] = useState("");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    // handleUpdateTable(false);
    const TE = selectedItem.product?.therapeuticEquivalencyCode
      ? selectedItem.product.therapeuticEquivalencyCode
      : null;

    if (TE) {
      ProductService.getTherapeuticEquivalentProducts(user, TE)
        .then((results) => {
          setEquivalencies(results);
          setEquivalencyControllerCallFinished(true);
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  }, []);

  useEffect(() => {
    if (equivalencies.length) {
      setListOfEquivProductDescriptions(
        equivalencies.map((equivProduct) => {
          let dropdownDescription = `${equivProduct.primaryName.trim()} (${
            equivProduct.packageId
          })`;
          return {
            type: "product",
            description: dropdownDescription,
            data: equivProduct,
          };
        })
      );
    }
  }, [equivalencies]);

  useEffect(() => {
    if (updateTable) {
      handleUpdateTable(false);
      onUpdateItem(selectedItem, modifiedItem);
      onCloseSubstituteProductModal();
    } else if (updateTable === false && error) {
      onCloseSubstituteProductModal();
    }
  }, [updateTable, error]);

  const { t, i18n } = useTranslation("pendingDispensingOrder");

  const [selectedEquivProductDescription, setSelectedEquivProductDescription] =
    useState("");

  const [selectedEquivProduct, setSelectedEquivProduct] = useState("");

  const handleSubmit = () => {
    if (selectedEquivProduct) {
      let modifiedDispensingOrderItem = { ...selectedItem };
      modifiedDispensingOrderItem.product = selectedEquivProduct;

      handleModifiedItem(modifiedDispensingOrderItem);

      pendDisOrderControllerRef.substituteProduct(
        selectedItem,
        modifiedDispensingOrderItem
      );
      handleUpdateTable(true);
    } else {
      setUserError("An equivalent product was not selected.");
    }
  };

  // en is English; es is Spanish; fr is French;
  const languageDetected = i18n.language;

  let packageIdClass;
  let defaultDrugNameClass;

  switch (languageDetected) {
    case "en":
      packageIdClass = "SubstituteProduct__basicInfo--packageIdEn";
      defaultDrugNameClass = "SubstituteProduct__basicInfo--defaultNameEn";
      break;
    case "es":
      packageIdClass = "SubstituteProduct__basicInfo--packageIdEs";
      defaultDrugNameClass = "SubstituteProduct__basicInfo--defaultNameEs";
      break;
    case "fr":
      packageIdClass = "SubstituteProduct__basicInfo--packageIdFr";
      defaultDrugNameClass = "SubstituteProduct__basicInfo--defaultNameFr";
      break;
    default:
      packageIdClass = "SubstituteProduct__basicInfo--packageIdEn";
      defaultDrugNameClass = "SubstituteProduct__basicInfo--defaultNameEn";
  }

  const goBackHandler = () => {
    onCloseSubstituteProductModal();
  };

  return (
    <div className={styles.SubstituteProduct__overlay}>
      <div className={styles.SubstituteProduct__view}>
        <div className={styles.SubstituteProduct__container}>
          <div className={styles.SubstituteProduct__formCloseContainer}>
            {!smScreen && (
              <CloseIcon
                fill={"#121A2D"}
                style={{ cursor: "pointer" }}
                onClick={() => onCloseSubstituteProductModal()}
              />
            )}
          </div>

          {smScreen && (
            <section className={styles.SubstituteProduct__header}>
              <div
                className={styles.SubstituteProduct__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.SubstituteProduct__backArrowIcon}
                />
                <p className={styles.SubstituteProduct__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <h1 className={styles.SubstituteProduct__title}>
                {t("substituteProductLabel")}
              </h1>
            </section>
          )}

          {!smScreen && (
            <section className={styles.SubstituteProduct__header}>
              <p className={styles.SubstituteProduct__title}>
                {t("substituteProductLabel")}
              </p>
            </section>
          )}

          {userError && (
            <div className={styles.SubstituteProduct__errorBannerContainer}>
              <ErrorBanner
                message={userError}
                onClose={() => setUserError("")}
              />
            </div>
          )}

          <section
            className={styles.SubstituteProduct__productSelectionContainer}
          >
            <p
              className={[
                styles["SubstituteProduct__basicInfo"],
                styles["SubstituteProduct__basicInfo--id"],
                styles[packageIdClass],
              ].join(" ")}
            >
              {`${t("originalPackageIdLabel")}: `}
              <span>{selectedItem.product.packageId}</span>
            </p>
            <p
              className={[
                styles["SubstituteProduct__basicInfo"],
                styles["SubstituteProduct__basicInfo--drugName"],
                styles[defaultDrugNameClass],
              ].join(" ")}
            >
              {smScreen ? "" : `${t("defaultDrugNameLabel")}: `}
              <span>{selectedItem.product.defaultName}</span>
            </p>
          </section>

          {equivalencies && equivalencies.length > 0 && (
            <div className={styles.SubstituteProduct__dropdown}>
              <DropdownInput
                labelName={t("subProductTypeLabel")}
                value={
                  selectedEquivProductDescription
                    ? selectedEquivProductDescription
                    : ""
                }
                validationText={""}
                placeholder={""}
                isEnabled={true}
                listElements={listOfEquivProductDescriptions}
                onSelection={(value) => {
                  setSelectedEquivProductDescription(value.description);
                  setSelectedEquivProduct(value.data);
                }}
              />
            </div>
          )}

          {(equivalencyControllerCallFinished && !equivalencies) ||
            (equivalencyControllerCallFinished &&
              equivalencies &&
              equivalencies.length === 0 && (
                <div
                  className={styles.SubstituteProduct__noEquivalenciesMessage}
                >
                  <p>{t("substituteProductNotFoundMsg")}</p>
                </div>
              ))}

          <section
            className={
              smScreen && equivalencies && equivalencies.length === 0
                ? `${styles.SubstituteProduct__footer} ${styles["SubstituteProduct__footer--noEquivalencies"]}`
                : styles.SubstituteProduct__footer
            }
          >
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => onCloseSubstituteProductModal()}
              minWidth={
                smScreen
                  ? equivalencies && equivalencies.length > 0
                    ? 122
                    : "100%"
                  : null
              }
            />
            <Spacer space={20} unit={"px"} />
            {equivalencies && equivalencies.length > 0 && (
              <div>
                <Button
                  labelName={t("selectButtonLabel")}
                  isPrimary={true}
                  isDisabled={false}
                  onClick={handleSubmit}
                  minWidth={smScreen ? 122 : 213}
                />
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

export default SubstituteProduct;
