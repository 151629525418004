import React, { useState, useEffect, useContext } from "react";
import { ReactComponent as SearchButton } from "../assets/search-btn.svg";
import { ReactComponent as SearchButtonTablet } from "../assets/search-btn-tablet.svg";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./SearchBar.module.css";

const SearchBar = ({
  placeholder,
  onSearch,
  onEnter,
  focus,
  smFullWidth = false,
  parentFilterText = "",
}) => {
  // const searchInput = useRef(null);
  const { smallScreen, mediumScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);

  const containerStyle = smScreen
    ? {
        display: "flex",
        flexDirection: "row",
        marginLeft: smFullWidth ? "0px" : "20px",
        marginTop: smFullWidth ? "0px" : "13px",
        width: smFullWidth ? "100%" : "inherit",
      }
    : { display: "flex", flexDirection: "row" };

  const inputStyle = smScreen
    ? {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        backgroundColor: "#FAFCFE",
        outline: "none",
        flexDirection: "row",
        border: "1px solid",
        borderColor: "#D2D5E1",
        borderRadius: "10px",
        height: "37px",
        width: "100%",
        paddingLeft: "10px",
      }
    : mdScreen
    ? {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "1px solid",
        borderColor: "#9CD7DD",
        outline: "none",
        flexDirection: "row",
        maxHeight: "30px",
        flexGrow: 1,
        maxWidth: "366px",
        marginLeft: "10px",
      }
    : {
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "15px",
        lineHeight: "22.5px",
        color: "var(--black)",
        borderTop: "none",
        borderLeft: "none",
        borderRight: "none",
        borderBottom: "1px solid",
        borderColor: "#9CD7DD",
        outline: "none",
        flexDirection: "row",
        maxHeight: "36px",
        width: "490px",
        marginLeft: "24px",
      };
  const [value, setValue] = useState("");

  useEffect(() => {
    if (parentFilterText) {
      setValue(parentFilterText);
    }
  }, [parentFilterText]);

  // Set automatic focus to the search box upon initial page rendering
  // useEffect(() => {
  //   focus && searchInput.current.focus();
  // }, [focus]);

  const SearchBtnIcon = mdScreen ? <SearchButtonTablet /> : <SearchButton />;

  return (
    <div style={containerStyle}>
      {!smScreen && SearchBtnIcon}
      <input
        // ref={focus ? searchInput : null}
        className={styles.SearchBar__input}
        autoFocus={focus}
        style={inputStyle}
        type={"text"}
        placeholder={placeholder}
        value={value}
        onChange={(e) => {
          setValue(e.currentTarget.value);
          if (onSearch) {
            onSearch(e.currentTarget.value);
          }
        }}
        onKeyUp={(e) => {
          if (e.key.toUpperCase() === "ENTER" && onEnter) {
            onEnter(value);
          }
        }}
      />
    </div>
  );
};

export default SearchBar;
