const summaryBagReport_reportObj = {
  title: "",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: false,
  collections: [
    "dispensingOrderItem",
    "product",
    "facility",
    "packagedItem",
    "dispensingOrder",
  ],
  fields: [
    {
      collection: "dispensingOrder",
      dataType: "String",
      name: "dispensingOrderId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrderItem",
      dataType: "String",
      name: "patientInfo.name",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrderItem",
      dataType: "String",
      name: "patientInfo.id",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "facility",
      dataType: "String",
      name: "name",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrderItem",
      dataType: "String",
      name: "patientInfo.room",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "primaryName",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "strength",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrderItem",
      dataType: "String",
      name: "rxNumber",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "packageId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "dispensingOrderItem",
      dataType: "String",
      name: "physician",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "comments",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "packagedItem",
      dataType: "Date",
      name: "packagedOn",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "packagedItem",
      dataType: "Number",
      name: "quantity",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "facility",
      dataType: "String",
      name: "packagingMethod.type",
      query: [],
      sum: false,
      average: false,
    },
  ],
  functionFields: [],
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "Summary Bag Report",
  functionLookups: {},
};

export default summaryBagReport_reportObj;
