import ProductService from "../services/ProductService";
import ProductFieldController from "../controllers/ProductFieldController";

class ProductController {
  constructor(user) {
    this.user = user;
    this.productFieldController = new ProductFieldController();
  }

  getProductByBarcode = async (barcode, callback) => {
    ProductService.getProductByBarcode(this.user, barcode)
      .then((product) => {
        callback(null, product);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getProductByPackageId = async (packageId) => {
    return await ProductService.getProductByPackageId(this.user, packageId);
  };

  searchForProducts = async (searchText, callback) => {
    ProductService.search(this.user, searchText)
      .then((products) => {
        callback(null, products);
      })
      .catch((error) => {
        callback(error);
      });
  };

  searchForProductsPaginated = async (searchText, page, limit, callback) => {
    ProductService.searchPaginated(this.user, searchText, page, limit)
      .then((products) => {
        callback(null, products);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getPendingProducts = async (callback) => {
    ProductService.getPendingProducts(this.user)
      .then((data) => {
        callback(null, data);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getPendingProductsPaginated = async (page, limit, callback) => {
    ProductService.getPendingProductsPaginated(this.user, page, limit)
      .then((data) => {
        callback(null, data);
      })
      .catch((error) => {
        callback(error);
      });
  };

  getDefaultName = (product) => {
    return this.productFieldController.getDefaultName(product);
  };

  getPrimaryName = (val) => {
    return this.productFieldController.getPrimaryName(val);
  };

  getSecondaryName = (val) => {
    return this.productFieldController.getSecondaryName(val);
  };

  getTertiaryName = (val) => {
    return this.productFieldController.getTertiaryName(val);
  };

  getStrength = (val) => {
    return this.productFieldController.getStrength(val);
  };

  getEv54DrugId = (p) => {
    return this.productFieldController.getEV54DrugId(p);
  };

  getEv54DrugName = (p) => {
    return this.productFieldController.getEV54DrugName(p);
  };

  getEv54DrugNameError = (p) => {
    return this.productFieldController.getEV54DrugNameError(p);
  };

  getEv54DrugIdError = (p) => {
    return this.productFieldController.getEV54DrugIdError(p);
  };
}

export default ProductController;
