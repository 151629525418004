import React, { useState, useEffect, useContext, useRef } from "react";
import { ReactComponent as ToggleIcon } from "../../assets/accordion-rectangle.svg";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./Accordion.module.css";

const Accordion = ({
  title,
  children,
  openByDefault = false,
  rightMargin = 0,
  onGetAccordionState = null,
}) => {
  const [isActive, setIsActive] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const [scrollComponent, setScrollComponent] = useState(false);

  const scrollToRef = useRef();

  useEffect(() => {
    if (scrollComponent) {
      scrollToRef.current.scrollIntoView();
      setScrollComponent(false);
    }
  }, [scrollComponent]);

  useEffect(() => {
    if (openByDefault) {
      setIsActive(true);
    }
  }, [openByDefault]);

  return (
    <div className={styles.accordion__item} ref={scrollToRef}>
      <div
        onClick={(e) => {
          setScrollComponent(true);
          e.preventDefault();
          if (onGetAccordionState) {
            onGetAccordionState(!isActive);
          }
          setIsActive((prevState) => !prevState);
        }}
      >
        <div className={styles.accordion__titleGroup}>
          {/* This allows a different icon to be used for expanded or collapsed, if desired. */}
          {isActive ? (
            <ToggleIcon className={styles.accordion__toggleIcon} />
          ) : (
            <ToggleIcon className={styles.accordion__toggleIcon} />
          )}
          <h2
            className={styles.accordion__title}
            style={
              !smScreen ? { marginRight: rightMargin } : { marginRight: 0 }
            }
          >
            {title}
          </h2>
        </div>
      </div>
      {isActive ? (
        <div className={styles.accordion__content}>{children}</div>
      ) : (
        <div className={styles["accordion__content--hide"]}>{children}</div>
      )}
    </div>
  );
};

export default Accordion;
