import React, { useContext } from "react";
import { NavbarContext } from "../../../../contexts/NavbarContext";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import SFDropDownList from "../../../../components/SFDropDownList/SFDropDownList";
import { ReactComponent as BoldSVG } from "../../svgs/text-bold.svg";
import { ReactComponent as ItalicSVG } from "../../svgs/text-italic.svg";
import { ReactComponent as UnderlineSVG } from "../../svgs/text-underline.svg";
import { ReactComponent as LeftAlignSVG } from "../../svgs/text-left2.svg";
import { ReactComponent as CenterAlignSVG } from "../../svgs/text-center2.svg";
import { ReactComponent as RightAlignSVG } from "../../svgs/text-right2.svg";
import { ReactComponent as CheckboxChecked } from "../../svgs/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "../../svgs/checkbox-unchecked.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import getMappingJSONObj from "../getMappingJSONObj";
import getCanisterDataMappingDropdownList from "../getCanisterDataMappingDropdownList";
import styles from "./TextProperties.module.css";

const TextProperties = ({
  activeNode,
  nodes,
  onNodes,
  onShowDeleteNodeWarning,
  onInputFocused,
  labelType,
  onLabelModificationInProgress,
}) => {
  const { handleLabelModificationInProgressViaContext } =
    useContext(NavbarContext);

  const handleDataUpdate = (tempNodes) => {
    onNodes([...tempNodes]);
    onLabelModificationInProgress(true);
    handleLabelModificationInProgressViaContext(true);
  };

  const handleXPosition = (xPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].xPosmm = xPosition;
    handleDataUpdate(tempNodes);
  };

  const handleYPosition = (yPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].yPosmm = yPosition;
    handleDataUpdate(tempNodes);
  };

  const handleWidth = (width) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].widthmm = width;
    handleDataUpdate(tempNodes);
  };

  const handleHeight = (height) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].heightmm = height;
    handleDataUpdate(tempNodes);
  };

  const handlePropertyName = (propertyName) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].propertyName = propertyName;
    handleDataUpdate(tempNodes);
  };

  const handleDefaultValue = (defaultValue) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].defaultValue = defaultValue;
    handleDataUpdate(tempNodes);
  };

  // for Syncfusion color picker
  function onChange(args) {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].textColor = args.currentValue.hex;
    handleDataUpdate(tempNodes);
  }

  // for SyncFusion font dropdown
  const dropdownListFont = [
    { Item: "Poppins", Value: 1 },
    { Item: "Roboto", Value: 2 },
    { Item: "Open Sans", Value: 3 },
  ];

  // for SyncFusion font dropdown
  const handleSelectedFont = (selectedFont, selectedFontValue) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].font = selectedFont;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion font-size dropdown
  const dropdownListFontSize = [
    { Item: "8", Value: 1 },
    { Item: "10", Value: 2 },
    { Item: "12", Value: 3 },
    { Item: "14", Value: 4 },
    { Item: "16", Value: 5 },
    { Item: "18", Value: 6 },
    { Item: "20", Value: 7 },
  ];

  // for SyncFusion font dropdown
  const handleSelectedFontSize = (selectedFontSize, selectedFontSizeValue) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].fontSize = selectedFontSize;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion textLines dropdown
  const dropdownListTextLines = [
    { Item: 1, Value: 1 },
    { Item: 2, Value: 2 },
    { Item: 3, Value: 3 },
    { Item: 4, Value: 4 },
    { Item: 5, Value: 5 },
  ];

  // for SyncFusion font dropdown
  const handleSelectedTextLines = (
    selectedTextLines,
    selectedTextLinesValue
  ) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].textLines = selectedTextLines;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion Canister Data Mapping dropdown
  const handleCanisterDataMapping = (
    selectedFriendlyName,
    selectedFriendlyNamePath
  ) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].mapping = getMappingJSONObj(
      selectedFriendlyName,
      selectedFriendlyNamePath,
      activeNode
    );
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion textLines dropdown
  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleBold = () => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].bold = !tempNodes[activeNode.nodeId].bold;
    handleDataUpdate(tempNodes);
  };

  const handleItalic = () => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].italic = !tempNodes[activeNode.nodeId].italic;
    handleDataUpdate(tempNodes);
  };

  const handleUnderline = () => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].underline =
      !tempNodes[activeNode.nodeId].underline;
    handleDataUpdate(tempNodes);
  };

  const handleTextAlignment = (val) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].textAlignment = val;
    handleDataUpdate(tempNodes);
  };

  const handleWordWrap = () => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].wordWrap =
      !tempNodes[activeNode.nodeId].wordWrap;
    handleDataUpdate(tempNodes);
  };

  const handleRemoveNode = () => {
    onShowDeleteNodeWarning(true);
  };

  const handleBlur = () => {
    onInputFocused(false);
  };

  const handleMouseEnter = () => {
    // A time delay is used here to prevent competition with handleBlur when leavihg one input box and entering another
    setTimeout(() => {
      onInputFocused(true);
    }, 700);
  };

  return (
    <div>
      <div className={styles.TextProperties__container}>
        <div
          className={styles.TextProperties__trashIconContainer}
          onClick={handleRemoveNode}
        >
          <TrashIcon />
        </div>
        <div className={styles.TextProperties__positionContainer}>
          <p>
            Position <span>(mm)</span>
          </p>

          <div className={styles.TextProperties__boxesContainer}>
            <div className={styles.TextProperties__xBoxContainer}>
              <div className={styles.TextProperties__inputContainer}>
                <InputCustomType
                  userInput={activeNode?.xPosmm}
                  onHandleUserInput={handleXPosition}
                  onMouseEnter={handleMouseEnter}
                  blurHandler={handleBlur}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.xPosmm,
                  }}
                />
              </div>
              <p>x</p>
            </div>
            <div className={styles.TextProperties__yBoxContainer}>
              <div className={styles.TextProperties__inputContainer}>
                <InputCustomType
                  userInput={activeNode?.yPosmm}
                  onHandleUserInput={handleYPosition}
                  onMouseEnter={handleMouseEnter}
                  blurHandler={handleBlur}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.yPosmm,
                  }}
                />
              </div>
              <p>y</p>
            </div>
          </div>
        </div>
        <div className={styles.TextProperties__sizeContainer}>
          <p>
            Size <span>(mm)</span>
          </p>

          <div className={styles.TextProperties__boxesContainer}>
            <div className={styles.TextProperties__widthBoxContainer}>
              <div className={styles.TextProperties__inputContainer}>
                <InputCustomType
                  userInput={activeNode.withmm}
                  onHandleUserInput={handleWidth}
                  onMouseEnter={handleMouseEnter}
                  blurHandler={handleBlur}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.widthmm,
                  }}
                />
              </div>
              <p>width</p>
            </div>
            <div className={styles.TextProperties__heightBoxContainer}>
              <div className={styles.TextProperties__inputContainer}>
                <InputCustomType
                  userInput={activeNode.heightmm}
                  onHandleUserInput={handleHeight}
                  onMouseEnter={handleMouseEnter}
                  blurHandler={handleBlur}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.heightmm,
                  }}
                />
              </div>
              <p>height</p>
            </div>
          </div>
        </div>

        {labelType === "Canister" && (
          <div className={styles.TextProperties__canisterDataMapping}>
            <SFDropDownList
              id={"TextProperites_canisterDataMapping"}
              label={"Canister Data"}
              defaultValue={activeNode.mapping.friendlyName}
              placeHolder={activeNode.mapping.friendlyName}
              dropDownList={dropdownListCanisterDataMapping}
              onSelection={handleCanisterDataMapping}
            />
          </div>
        )}

        <div className={styles.TextProperties__textColorContainer}>
          <div className="wrap">
            <p>Text Color</p>
            <ColorPickerComponent
              id="color-picker"
              value={activeNode.textColor}
              // mode="Palette"
              // noColor={false}
              showButtons={true}
              modeSwitcher={true}
              change={onChange}
            />
          </div>
        </div>

        {/* <div className={styles.TextProperties__propertyNameContainer}>
          <p>Property Name</p>
          <div className={styles.TextProperties__inputContainer}>
            <InputCustomType
              userInput={activeNode.propertyName}
              onHandleUserInput={handlePropertyName}
              onMouseEnter={handleMouseEnter}
              blurHandler={handleBlur}
              enforceFocus={false}
              inputFields={{
                type: "text",
                value: activeNode.propertyName,
              }}
            />
          </div>
        </div> */}

        <div className={styles.TextProperties__defaultValueContainer}>
          <p>Default Value</p>
          <div className={styles.TextProperties__inputContainer}>
            <InputCustomType
              userInput={activeNode.defaultValue}
              onHandleUserInput={handleDefaultValue}
              onMouseEnter={handleMouseEnter}
              blurHandler={handleBlur}
              enforceFocus={false}
              inputFields={{
                type: "text",
                value: activeNode.defaultValue,
              }}
            />
          </div>
        </div>
        <div className={styles.TextProperties__fontContainer}>
          <SFDropDownList
            id={"TextProperites_font"}
            label={"Font"}
            defaultValue={activeNode.font}
            placeHolder={"Font"}
            dropDownList={dropdownListFont}
            onSelection={handleSelectedFont}
          />
        </div>

        <div className={styles.TextProperties__fontSizeContainer}>
          <SFDropDownList
            id={"TextProperites_fontSize"}
            label={"Font Size"}
            defaultValue={activeNode.fontSize}
            placeHolder={activeNode.fontSize}
            dropDownList={dropdownListFontSize}
            onSelection={handleSelectedFontSize}
          />
        </div>

        <div className={styles.TextProperties__textStylesContainer}>
          <p>Text</p>

          <div
            className={`${styles.TextProperties__boxesContainer} ${styles["TextProperties__boxesContainer--textStyles"]}`}
          >
            <div className={styles.TextProperties__styleBoxesContainer}>
              <div
                className={styles.TextProperties__boldContainer}
                onClick={handleBold}
              >
                <BoldSVG />
              </div>
              <div
                className={styles.TextProperties__italicContainer}
                onClick={handleItalic}
              >
                <ItalicSVG />
              </div>
              <div
                className={styles.TextProperties__underlineContainer}
                onClick={handleUnderline}
              >
                <UnderlineSVG />
              </div>
            </div>

            <div className={styles.TextProperties__alignmentBoxesContainer}>
              <div
                className={styles.TextProperties__leftAlignmentContainer}
                onClick={() => {
                  handleTextAlignment("left");
                }}
              >
                <LeftAlignSVG />
              </div>
              <div
                className={styles.TextProperties__centerAlignmentContainer}
                onClick={() => {
                  handleTextAlignment("center");
                }}
              >
                <CenterAlignSVG />
              </div>
              <div
                className={styles.TextProperties__rightAlignmentContainer}
                onClick={() => {
                  handleTextAlignment("right");
                }}
              >
                <RightAlignSVG />
              </div>
            </div>
          </div>

          <div className={styles.TextProperties__wordWrappingContainer}>
            <div className={styles.TextProperties__wordWrapContainer}>
              <div
                className={styles.TextProperties__wordWrapCheckBoxContainer}
                onClick={handleWordWrap}
              >
                {activeNode.wordWrap ? (
                  <CheckboxChecked />
                ) : (
                  <CheckboxUnchecked />
                )}
              </div>
              <p>Word Wrap</p>
            </div>
            <div className={styles.TextProperties__wordLinesContainer}>
              <SFDropDownList
                id={"TextProperties_textLines"}
                label={"Lines"}
                defaultValue={activeNode.textLines}
                placeHolder={"Lines"}
                dropDownList={dropdownListTextLines}
                onSelection={handleSelectedTextLines}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextProperties;
