import React, { useState, useEffect, useRef } from "react";
import Button from "./Button";
import { ReactComponent as CloseIcon } from "../assets/x.svg";
import Spacer from "./Spacer";

const Modal = ({
  title,
  message,
  buttonLabelName,
  onClose,
  width,
  height,
  top,
  left,
  padding = "35px",
  backgroundColor = "white",
  borderRadius = "20px",
  showButton = true,
  children,
}) => {
  const modalPopup = useRef(null);
  const [modalPopupHeight, setModalPopupHeight] = useState(0);
  const [browserWindowHeight, setBrowserWindowHeight] = useState(
    window.innerHeight
  );

  useEffect(() => {
    if (children) {
      setModalPopupHeight(modalPopup.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    if (children) {
      function handleResize() {
        setBrowserWindowHeight(window.innerHeight);
      }

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  left = left || (window.innerWidth - (width || 586)) / 2;
  top = top || (window.innerHeight - (height || 200)) / 2;

  const containerStyle = {
    position: "absolute",
    zIndex: 99,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: backgroundColor,
    borderRadius: borderRadius,
    left: left,
    top: top,
    width: width || 586,
    height: height || 200,
    padding: padding,
    boxShadow: "0px 5px 14px rgba(120, 146, 141, 0.05)",
    backdropFilter: "blur(46.2108px)",
  };

  const headerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignContent: "center",
    width: "100%",
    height: "50px",
  };

  const bodyStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
  };

  const footerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const titleStyle = {
    margin: 0,
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "22px",
    lineHeight: "27px",
    color: "#121A2D",
  };

  const textStyle = {
    margin: 0,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "13px",
    lineHeight: "22px",
    color: "#121A2D",
  };

  return (
    <div style={containerStyle}>
      {children && (
        <div
          ref={modalPopup}
          style={{
            position: "absolute",
            transform: `translateY(${
              0.5 * browserWindowHeight - 0.5 * modalPopupHeight
            }px)`,
          }}
        >
          {children}
        </div>
      )}
      <header style={headerStyle}>
        <CloseIcon
          fill={"#121A2D"}
          onClick={onClose}
          style={{ cursor: "pointer" }}
        />
      </header>
      <main style={bodyStyle}>
        <h1 style={titleStyle}>{title}</h1>
        <Spacer space={30} unit={"px"} />
        <p style={textStyle}>{message}</p>
      </main>
      {showButton && (
        <footer style={footerStyle}>
          <Button
            isPrimary={true}
            isDisabled={false}
            onClick={onClose}
            labelName={buttonLabelName}
            isInverse={false}
          />
        </footer>
      )}
    </div>
  );
};

export default Modal;
