import React, { useEffect, useState, useContext } from "react";
import { NavbarContext } from "../../../../contexts/NavbarContext";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import { ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { ReactComponent as BorderOffIcon } from "../../../../assets/x.svg";
import { ReactComponent as BorderOnIcon } from "../../../../assets/canvas.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import styles from "./RectangleProperties.module.css";

const RectangleProperties = ({
  activeNode,
  nodes,
  nodesRef,
  onNodes,
  onShowDeleteNodeWarning,
  onInputFocused,
  error,
  onError,
  onLabelModificationInProgress,
}) => {
  const [widthError, setWidthError] = useState(false);
  const [heightError, setHeightError] = useState(false);

  const { handleLabelModificationInProgressViaContext } =
    useContext(NavbarContext);

  const updateChildNodes = (mainRectangleNode) => {
    if (mainRectangleNode.nodeId === 0) {
      mainRectangleNode.childNodes.forEach((childNode) => {
        childNode.parent = mainRectangleNode;
      });
    }
  };

  useEffect(() => {
    if (widthError) {
      onError("Maximum width is 215.9");
    } else if (heightError) {
      onError("Maximum height is 279.4");
    } else {
      onError("");
    }
  }, [widthError, heightError]);

  const wholeNumbers = /^[0-9]+$/;
  const rx = new RegExp(wholeNumbers);
  let paddingValToProcess;

  const handleDataUpdate = (tempNodes) => {
    onNodes([...tempNodes]);
    onLabelModificationInProgress(true);
    handleLabelModificationInProgressViaContext(true);
  };

  // for Syncfusion color picker
  function onChange(args) {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].borderColor = args.currentValue.hex;
    handleDataUpdate(tempNodes);
  }

  const handleTopPadding = (paddingVal) => {
    let tempNodes = [...nodes];
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        top: paddingValToProcess,
      };
      if (activeNode.nodeId === 0) {
        updateChildNodes(tempNodes[activeNode.nodeId]);
      }
      handleDataUpdate(tempNodes);
    }
  };

  const handleRightPadding = (paddingVal) => {
    let tempNodes = [...nodes];
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        right: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleBottomPadding = (paddingVal) => {
    let tempNodes = [...nodes];
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        bottom: paddingValToProcess,
      };
      handleDataUpdate(tempNodes);
    }
  };

  const handleLeftPadding = (paddingVal) => {
    let tempNodes = [...nodes];
    if (rx.test(paddingVal) || !paddingVal) {
      paddingValToProcess = rx.test(paddingVal) ? parseInt(paddingVal) : "";
      tempNodes[activeNode.nodeId].padding = {
        ...activeNode.padding,
        left: paddingValToProcess,
      };
      if (activeNode.nodeId === 0) {
        updateChildNodes(tempNodes[activeNode.nodeId]);
      }
      handleDataUpdate(tempNodes);
    }
  };

  const handleXPosition = (xPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].xPosmm = xPosition;
    handleDataUpdate(tempNodes);
  };

  const handleYPosition = (yPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].yPosmm = yPosition;
    handleDataUpdate(tempNodes);
  };

  const handleWidth = (width) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].widthmm = width;
    if (activeNode.nodeId === 0) {
      updateChildNodes(tempNodes[activeNode.nodeId]);
    }
    handleDataUpdate(tempNodes);
    if (width > 215.9) {
      setWidthError(true);
    } else if (widthError) {
      setWidthError(false);
    }
  };

  const handleHeight = (height) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].heightmm = height;
    handleDataUpdate(tempNodes);
    if (height > 279.4) {
      setHeightError(true);
    } else if (heightError) {
      setHeightError(false);
    }
  };

  const handleBorder = (boolVal) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].borderOn = boolVal;
    handleDataUpdate(tempNodes);
  };

  const handleRemoveNode = () => {
    onShowDeleteNodeWarning(true);
  };

  const handleMouseEnter = () => {
    onInputFocused(true);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div className={styles.RectangleProperties__container}>
        <div
          className={styles.RectangleProperties__trashIconContainer}
          onClick={handleRemoveNode}
        >
          <TrashIcon />
        </div>

        <div
          className={styles.RectangleProperties__topAndRightPaddingContainer}
        >
          <p>
            Padding <span>(px)</span>
          </p>
          <div className={styles.RectangleProperties__boxesContainer}>
            <div className={styles.RectangleProperties__topPaddingBoxContainer}>
              <div
                className={styles.RectangleProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={nodesRef.current[activeNode.nodeId].padding.top}
                  onHandleUserInput={handleTopPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: nodesRef.current[activeNode.nodeId].padding.top,
                  }}
                />
              </div>
              <p>top</p>
            </div>
            <div
              className={styles.RectangleProperties__rightPaddingBoxContainer}
            >
              <div
                className={styles.RectangleProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={nodesRef.current[activeNode.nodeId].padding.right}
                  onHandleUserInput={handleRightPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: nodesRef.current[activeNode.nodeId].padding.right,
                  }}
                />
              </div>
              <p>right</p>
            </div>
          </div>
        </div>

        <div
          className={styles.RectangleProperties__bottomAndLeftPaddingContainer}
        >
          <p>
            Padding <span>(px)</span>
          </p>
          <div className={styles.RectangleProperties__boxesContainer}>
            <div
              className={styles.RectangleProperties__bottomPaddingBoxContainer}
            >
              <div
                className={styles.RectangleProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={nodesRef.current[activeNode.nodeId].padding.bottom}
                  onHandleUserInput={handleBottomPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: nodesRef.current[activeNode.nodeId].padding.bottom,
                  }}
                />
              </div>
              <p>bottom</p>
            </div>
            <div
              className={styles.RectangleProperties__leftPaddingBoxContainer}
            >
              <div
                className={styles.RectangleProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={nodesRef.current[activeNode.nodeId].padding.left}
                  onHandleUserInput={handleLeftPadding}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={100}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: nodesRef.current[activeNode.nodeId].padding.left,
                  }}
                />
              </div>
              <p>left</p>
            </div>
          </div>
        </div>

        {activeNode.parent && (
          <div className={styles.RectangleProperties__positionContainer}>
            <p>
              Position <span>(mm)</span>
            </p>
            <div className={styles.RectangleProperties__boxesContainer}>
              <div className={styles.RectangleProperties__xBoxContainer}>
                <div
                  className={styles.RectangleProperties__inputContainer}
                  onClick={handleStopPropagation}
                >
                  <InputCustomType
                    userInput={nodesRef.current[activeNode.nodeId].xPosmm}
                    onHandleUserInput={handleXPosition}
                    onMouseEnter={handleMouseEnter}
                    customType="positive number"
                    enforceFocus={false}
                    maxValue={10000}
                    maxNumOfDecimals={2}
                    inputFields={{
                      type: "text",
                      value: nodesRef.current[activeNode.nodeId].xPosmm,
                    }}
                  />
                </div>
                <p>x</p>
              </div>
              <div className={styles.RectangleProperties__yBoxContainer}>
                <div
                  className={styles.RectangleProperties__inputContainer}
                  onClick={handleStopPropagation}
                >
                  <InputCustomType
                    userInput={nodesRef.current[activeNode.nodeId].yPosmm}
                    onHandleUserInput={handleYPosition}
                    onMouseEnter={handleMouseEnter}
                    customType="positive number"
                    enforceFocus={false}
                    maxValue={10000}
                    maxNumOfDecimals={2}
                    inputFields={{
                      type: "text",
                      value: nodesRef.current[activeNode.nodeId].yPosmm,
                    }}
                  />
                </div>
                <p>y</p>
              </div>
            </div>
          </div>
        )}
        <div className={styles.RectangleProperties__sizeGroupWithError}>
          <div className={styles.RectangleProperties__sizeContainer}>
            <p>
              Size <span>(mm)</span>
            </p>
            <div className={styles.RectangleProperties__boxesContainer}>
              <div className={styles.RectangleProperties__widthBoxContainer}>
                <div
                  className={styles.RectangleProperties__inputContainer}
                  onClick={handleStopPropagation}
                >
                  <InputCustomType
                    userInput={nodesRef.current[activeNode.nodeId].widthmm}
                    onHandleUserInput={handleWidth}
                    onMouseEnter={handleMouseEnter}
                    customType="positive number"
                    enforceFocus={false}
                    maxValue={215.9}
                    maxNumOfDecimals={2}
                    inputFields={{
                      type: "text",
                      value: nodesRef.current[activeNode.nodeId].widthmm,
                    }}
                  />
                </div>
                <p>width</p>
              </div>
              <div className={styles.RectangleProperties__heightBoxContainer}>
                <div
                  className={styles.RectangleProperties__inputContainer}
                  onClick={handleStopPropagation}
                >
                  <InputCustomType
                    userInput={nodesRef.current[activeNode.nodeId].heightmm}
                    onHandleUserInput={handleHeight}
                    onMouseEnter={handleMouseEnter}
                    customType="positive number"
                    enforceFocus={false}
                    maxValue={246}
                    maxNumOfDecimals={2}
                    inputFields={{
                      type: "text",
                      value: nodesRef.current[activeNode.nodeId].heightmm,
                    }}
                  />
                </div>
                <p>height</p>
              </div>
            </div>
          </div>
          {error && (
            <div className={styles.RectangleProperties__sizeError}>{error}</div>
          )}
        </div>
        <div className={styles.RectangleProperties__borderContainer}>
          <p>Border</p>
          <div className={styles.RectangleProperties__borderBoxesContainer}>
            <div
              className={styles.RectangleProperties__borderOffBox}
              onClick={() => handleBorder(false)}
            >
              <BorderOffIcon />
            </div>
            <div
              className={styles.RectangleProperties__borderOnBox}
              onClick={() => handleBorder(true)}
            >
              <BorderOnIcon />
            </div>
          </div>
        </div>
        <div className={styles.RectangleProperties__borderColorContainer}>
          <div className="wrap">
            <p>Border Color</p>
            <ColorPickerComponent
              id={`${nodesRef.current[activeNode.nodeId].id}-color-picker`}
              value={nodesRef.current[activeNode.nodeId].borderColor}
              defaultValue={nodesRef.current[activeNode.nodeId].borderColor}
              // mode="Palette"
              // noColor={false}
              showButtons={true}
              modeSwitcher={true}
              change={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RectangleProperties;
