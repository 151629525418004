import React, { Fragment, useContext } from "react";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

import styles from "./ModalGen.module.css";

const Backdrop = (props) => {
  return <div className={styles.backdrop} />;
};

const ModalOverlay = (props) => {
  return (
    <div className={props.modalClassName}>
      <div>{props.children}</div>
    </div>
  );
};

const ModalGen = (props) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  return (
    <Fragment>
      {smScreen ? (
        <Fragment>
          <Backdrop />
          <ModalOverlay>{props.children}</ModalOverlay>
        </Fragment>
      ) : (
        <Fragment>
          <div className={styles.backdrop}>
            <ModalOverlay>{props.children}</ModalOverlay>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ModalGen;
