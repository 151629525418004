import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../services/AuthService";
import dashboardController from "../controllers/DashboardController";
import Popup from "../components/PopupMenu/Popup";
import styles from "./UserProfilePopup.module.css";

const UserProfilePopup = ({ user, showPopup, onHandlePopup, onLogOut }) => {
  const _controllerRef = useRef(dashboardController);
  const navigate = useNavigate();
  const { t } = useTranslation("navBar");

  const PROFILE_MENU_ITEM = 6;
  const LOGOUT_MENU_ITEM = 7;
  const CHANGE_PASSWORD_MENU_ITEM = 8;

  const handleMenuSelection = (selectedMenuItem) => {
    if (selectedMenuItem === PROFILE_MENU_ITEM) {
      navigate("/profile");
    }
    if (selectedMenuItem === CHANGE_PASSWORD_MENU_ITEM) {
      navigate("/change-password");
    }
    // setMenuItem(selectedMenuItem);
    if (selectedMenuItem === LOGOUT_MENU_ITEM) {
      // release the dashboard websocket for this user
      _controllerRef.current.releaseSocket();
      AuthService.logOut(user);
      onLogOut();
    }
  };

  const actionListItems = [
    {
      type: "logout",
      description: t("action0"),
    },
    {
      type: "editProfile",
      description: t("action1"),
    },
    {
      type: "changePassword",
      description: t("action2"),
    },
  ];

  const getActionMenu = () => {
    return actionListItems;
  };

  const handleAction = (action) => {
    switch (action.type) {
      case "logout":
        handleMenuSelection(LOGOUT_MENU_ITEM);
        break;
      case "editProfile":
        handleMenuSelection(PROFILE_MENU_ITEM);
        break;
      case "changePassword":
        handleMenuSelection(CHANGE_PASSWORD_MENU_ITEM);
        break;
      default:
        break;
    }
  };

  return showPopup ? (
    <div className={styles.userProfilePopup__popupContainer}>
      <Popup
        rightJustify={false}
        onAction={(menuItem) => {
          onHandlePopup(false);
          handleAction(menuItem);
        }}
        menuItems={getActionMenu()}
        onClose={() => onHandlePopup(false)}
      />
    </div>
  ) : (
    <div></div>
  );
};

export default UserProfilePopup;
