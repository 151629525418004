export const addZeros = (number, length) => {
  let num = "" + number;
  while (num.length < length) {
    num = "0" + num;
  }
  return num;
};

export const capitalize = (str) => {
  return str
    .trim()
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const containsObject = (obj, list) => {
  let i;
  for (i = 0; i < list.length; i++) {
    if (list[i] === obj) {
      return true;
    }
  }
  return false;
};

export const deepCopy = (inObject) => {
  let outObject, value, key;

  if (typeof inObject !== "object" || inObject === null) {
    return inObject; // Return the value if inObject is not an object
  }

  // Create an array or object to hold the values
  outObject = Array.isArray(inObject) ? [] : {};

  for (key in inObject) {
    value = inObject[key];

    // Recursively (deep) copy for nested objects, including arrays
    outObject[key] = deepCopy(value);
  }

  return outObject;
};

export const deepEqual = (object1, object2) => {
  function isObject(object) {
    return object != null && typeof object === "object";
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false;
    }
  }
  return true;
};

const canisterTypes = [
  "VBM_SMART_CANISTER_SMALL",
  "VBM_SMART_CANISTER_LARGE",
  "PROUD_SMART_CANISTER_SMALL",
  "PROUD_SMART_CANISTER_LARGE",
  "PROUD_CANISTER_XLARGE",
  "EV54_CANISTER",
];

export const isCanister = (stockLocation) => {
  return canisterTypes.includes(stockLocation.type.type);
};

export const isCanisterType = (type) => {
  // The type parameter must be an object with a type method.
  // This function returns true if the type method corresponds with one of the array types below; false otherwise.
  // Used for Add New Stock Location Form.

  if (type?.type) {
    return canisterTypes.includes(type.type);
  } else {
    return false;
  }
};

export const getOrderPriorityList = () => {
  return [
    { type: 5, description: "LOW" },
    { type: 2, description: "HIGH" },
    { type: 1, description: "STAT" },
    { type: 3, description: "WAITER" },
  ];
};

export const getHighestOrderPriority = (dispensingOrderItems) => {
  let temp = 0;
  for (let i = 0; i < dispensingOrderItems.length; i++) {
    const priStr = dispensingOrderItems[i].priority;
    let pri = 0;
    switch (priStr) {
      case "LOW":
        pri = 1;
        break;
      case "HIGH":
        pri = 3;
        break;
      case "WAITER":
        pri = 4;
        break;
      case "STAT":
        pri = 5;
        break;
      default:
        pri = 1;
        break;
    }
    if (pri > temp) {
      temp = pri;
    }
  }
  return temp;
};

export const getOrderPriority = (priStr) => {
  switch (priStr) {
    case "LOW":
      return 1;
    case "HIGH":
      return 3;
    case "WAITER":
      return 4;
    case "STAT":
      return 5;
    default:
      return 1;
  }
};

export const isObjEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const stringify = (obj) => {
  let cache = [];
  let str = JSON.stringify(obj, function (key, value) {
    if (typeof value === "object" && value !== null) {
      if (cache.indexOf(value) !== -1) {
        // Circular reference found, discard key
        return;
      }
      // Store value in our collection
      cache.push(value);
    }
    return value;
  });
  cache = null; // reset the cache
  return str;
};
