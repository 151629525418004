import React, { useEffect, useState } from "react";
import styles from "./PendingDispensingOrderCardList.module.css";
import PendingDispensingOrderCard from "./PendingDispensingOrderCard";

const PendingDispensingOrdercardList = ({
  headers,
  records,
  searchText,
  onClickHandler = null,
}) => {
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);

  useEffect(() => {
    let arr = [];
    for (let i = 0; i < headers.length; i++) {
      arr.push(1);
    }
  }, [headers]);

  useEffect(() => {
    if (searchText) {
      const arr = [...records];
      const arr2 = [];
      for (let r = 0; r < arr.length; r++) {
        const record = arr[r];
        for (let i = 0; i < record.length; i++) {
          if (
            record[i] &&
            record[i]
              .toString()
              .toUpperCase()
              .includes(searchText.toUpperCase())
          ) {
            arr2.push(record);
            break;
          }
        }
      }
      setFilteredRecords(arr2);
    } else {
      setFilteredRecords(records);
    }
  }, [searchText, records]);

  const handleRowClick = (record) => {
    let tempArraySelectedRecords = [...selectedOrders];

    let recordMatchCriteria = (element) => element === record[0];
    let matchedIndex = tempArraySelectedRecords.findIndex(recordMatchCriteria);

    if (matchedIndex === -1) {
      tempArraySelectedRecords.push(record[0]);
    } else {
      tempArraySelectedRecords.splice(matchedIndex, 1);
    }

    onClickHandler(tempArraySelectedRecords);
    setSelectedOrders(tempArraySelectedRecords);
  };

  const containsObject = (obj, list) => {
    let i;
    for (i = 0; i < list.length; i++) {
      if (list[i]._id === obj[0]._id) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className={styles.card__container}>
      {filteredRecords &&
        filteredRecords.map((row, index) => {
          let rowIsSelected;
          rowIsSelected = onClickHandler
            ? selectedOrders.includes(row[0])
            : null;
          return (
            <PendingDispensingOrderCard
              onClickHandler={onClickHandler ? () => handleRowClick(row) : null}
              key={"r_" + index}
              selectedExternalObject={containsObject(row, selectedOrders)}
              data={{
                facilityHeading: "Facility",
                priorityHeading: "Priority",
                patientHeading: "Patient",
                locationHeading: "Location",
                rxNumberHeading: "Rx",
                idHeading: "ID",
                productHeading: "Product",
                adminTimeHeading: "Admin Date",
                adminDateHeading: "Admin Time",
                quantityHeading: "Qty",
                errorHeading: "Error",
                actionsHeading: "",
                facility: row[2],
                priority: row[3],
                patient: row[4],
                location: row[5],
                rxNumber: "rx: " + row[6],
                id: row[7],
                product: row[8],
                adminDate: row[9],
                adminTime: row[10],
                quantity: row[11],
                error: row[12],
                actions: row[13],
              }}
            />
          );
        })}
    </div>
  );
};

export default PendingDispensingOrdercardList;
