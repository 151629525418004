import React from "react";
import { View, Image } from "@react-pdf/renderer";

const ImageComponent = ({ node, PDF_SIZE_FACTOR }) => {
  const position = node.nodeId;

  const widthpx = node.width;
  const heightpx = node.height;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;
  const base64EncodedString = node.base64EncodedString;
  const mimeType = node.mimeType;

  const width = (parseFloat(widthpx) * PDF_SIZE_FACTOR).toString() + "px";
  const height = (parseFloat(heightpx) * PDF_SIZE_FACTOR).toString() + "px";

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm
  const widthmm = widthpx * 0.2646; // convert px to mm
  const heightmm = heightpx * 0.2646; // convert px to mm

  if (Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) >
    Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) >
    Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  const imageContainerStyle = {
    position: "absolute",
    cursor: "pointer",
    left: x,
    top: y,
    width: width,
    height: height,
    border: "none",
  };

  const imageStyle = {
    width: width,
    height: height,
  };

  return (
    <View position={position} style={imageContainerStyle}>
      <Image
        style={imageStyle}
        src={`data:image/${mimeType};base64,${base64EncodedString}`}
        alt={"profile pic"}
      />
    </View>
  );
};

export default ImageComponent;
