import DashboardService from "../../../services/DashboardService";

class EV54DashboardController {
  /*
        This method will provide data as follows:
        user:           required
        site:           optional - if not provided, data will represent a total view of the account.
                        If provided, will be based on just the specified site
        stockLocation:  optional.  If provided, will be specific to that stockLocation (device)

        returns:        JSON structure with prevDayResults array and rollingAverage
    */
  getEV54ScriptsPerHourData = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getEV54ScriptsPerHourData(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate
    );
  };

  /*
        This method will provide data as follows:
        user:           required
        site:           optional - if not provided, data will represent a total view of the account.
                        If provided, will be based on just the specified site
        stockLocation:  optional.  If provided, will be specific to that stockLocation (device)

        return:         An array containing scriptType and count
    */
  getEV54ScriptTypes = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getEV54ScriptTypes(
      user,
      site,
      stockLocation,
      utcStartDate,
      utcStopDate
    );
  };

  /*
        This method will provide current inventory on the machine:
        user:           required
        site:           optional - if not provided, data will represent a total view of the account.
                        If provided, will be based on just the specified site
        stockLocation:  optional.  If provided, will be specific to that stockLocation (device)

        return:         An array of objects with the following fields:
                        device,
                        canister,
                        product,
                        expiration, (yyyy-mm-dd)
                        min,
                        max,
                        quantity

        note: for the summary widget, use the objects to group by under min, over max, etc
    */
  getEV54Inventory = async (
    user,
    site,
    stockLocation,
    utcStartDate,
    utcStopDate
  ) => {
    return await DashboardService.getEV54Inventory(user, site, stockLocation);
  };

  /*
        This method will provide canister utilization data for the previous 30 days as follows:
        user:           required
        site:           optional - if not provided, data will represent a total view of the account.
                        If provided, will be based on just the specified site
        stockLocation:  optional.  If provided, will be specific to that stockLocation (device)

        return:         An object with two elements:
                        scriptsPerCanister - array of {device, canister, count, product}
                        averageScriptsPerCanister (rolling average)
    */
  getEV54CanisterUtilization = async (user, site, stockLocation) => {
    return await DashboardService.getEV54CanisterUtilization(
      user,
      site,
      stockLocation
    );
  };

  // Returns the following JSON: [{errorCode:<>,description:<>,count:<>}]
  getEV54AlarmHistory = async (user, site, startDate, stopDate, details) => {
    return await DashboardService.getEV54AlarmHistory(
      user,
      site,
      startDate,
      stopDate,
      details
    );
  };

  getEV54AlarmResolution = async (user, errorCode) => {
    return await DashboardService.getEV54AlarmResolution(user, errorCode);
  };
}

const instance = new EV54DashboardController();
export default instance;
