import React from "react";
import styles from "./ProductBulkCard.module.css";

const ProductBulkCard = ({ data, onCardClick = false }) => {
  return (
    <div
      className={styles.productBulkCard__container}
      onClick={(e) => onCardClick(e)}
    >
      <div className={styles.productBulkCard__dataList}>
        <div className={styles.productBulkCard__rowGroup}>
          <div
            className={`${styles.productBulkCard__colGroup} ${styles["productBulkCard__colGroup--1"]}`}
          >
            <div className={`${styles["productBulkCard__heading"]}`}>
              {data.heading1}
            </div>
            <div className={`${styles["productBulkCard__value"]}`}>
              {data.item1}
            </div>
          </div>

          <div
            className={`${styles.productBulkCard__colGroup} ${styles["productBulkCard__colGroup--2"]}`}
          >
            <div className={`${styles["productBulkCard__heading"]}`}>
              {data.heading2}
            </div>
            <div className={`${styles["productBulkCard__value"]}`}>
              {data.item2}
            </div>
          </div>
        </div>

        <div className={styles.productBulkCard__rowGroup}>
          <div
            className={`${styles.productBulkCard__colGroup} ${styles["productBulkCard__colGroup--1"]} `}
          >
            <div className={`${styles["productBulkCard__heading"]}`}>
              {data.heading3}
            </div>
            <div className={`${styles["productBulkCard__value"]}`}>
              {data.item3}
            </div>
          </div>
        </div>

        <div className={styles.productBulkCard__rowGroup}>
          <div
            className={`${styles.productBulkCard__colGroup} ${styles["productBulkCard__colGroup--1"]}`}
          >
            <div className={`${styles["productBulkCard__heading"]}`}>
              {data.heading4}
            </div>
            <div className={`${styles["productBulkCard__value"]}`}>
              {data.item4}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBulkCard;
