import * as React from "react";
import { useContext } from "react";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { getYAxisScale } from "./graphFunctions";
import {
  ChartComponent,
  SeriesCollectionDirective,
  AxesDirective,
  AxisDirective,
  SeriesDirective,
  Inject,
  ColumnSeries,
  Legend,
  Category,
  Tooltip,
  DataLabel,
  LineSeries,
} from "@syncfusion/ej2-react-charts";
function CanisterUtilizationGraph({
  graphData,
  graphId,
  graphTitle,
  palette,
  viewWidth,
}) {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  // yp represents the primary Y axis
  // ys represents the secondary Y axis (yAxis1)
  // Initializations
  let ypMin = 0; // min data value
  let ysMin = 0; // min data value
  let ypMax = 0; // max data value
  let ysMax = 0; // max data value
  let minimumYp = 0; // min scale label value
  let minimumYs = 0; // min scale label value
  let maximumYp = 0; // max scale label value
  let maximumYs = 0; // max scale label value
  let incYp = 0; // yAxis scale interval
  let incYs = 0; // yAxis scale interval

  // variable as desired
  let numYpScaleValues = 6;
  let numYsScaleValues = 6;

  if (graphData && graphData.length) {
    graphData.forEach((graphObj) => {
      if (graphObj.y > ypMax) {
        ypMax = graphObj?.y;
      }
      if (graphObj.y1 > ypMax) {
        ypMax = graphObj?.y1;
      }
      if (graphObj.y1 > ysMax) {
        ysMax = graphObj?.y1;
      }
    });

    incYp = getYAxisScale(ypMin, ypMax, numYpScaleValues);
    incYs = getYAxisScale(ysMin, ysMax, numYsScaleValues);

    maximumYp = parseInt(minimumYp + (numYpScaleValues - 1) * incYp);
    maximumYs = parseInt(minimumYs + (numYsScaleValues - 1) * incYs);
  }

  const primaryxAxis = { valueType: "Category" };

  const primaryyAxis = {
    title: "# of Scripts",
    minimum: 0,
    maximum: maximumYp,
    interval: incYp,
    lineStyle: { width: 1 },
  };

  return (
    <div>
      <ChartComponent
        style={
          smScreen
            ? { width: `${viewWidth - 54}px`, height: "300px" }
            : { width: "450px", height: "300px" }
        }
        titleStyle={{
          size: "16px",
          fontFamily: "Poppins",
          fontWeight: "Normal",
        }}
        title={graphTitle}
        id={graphId}
        primaryXAxis={primaryxAxis}
        primaryYAxis={primaryyAxis}
        palettes={palette}
      >
        <Inject
          services={[
            ColumnSeries,
            LineSeries,
            Legend,
            Tooltip,
            DataLabel,
            Category,
          ]}
        />
        <AxesDirective>
          <AxisDirective
            rowIndex={0}
            name="yAxis1"
            opposedPosition={true}
            lineStyle={0}
            minimum={0}
            maximum={maximumYs}
            interval={incYs}
            labelFormat="c"
            majorGridLines={{ width: 0 }}
          ></AxisDirective>
        </AxesDirective>
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={graphData}
            xName="x"
            yName="y"
            name="# of scripts"
            type="Column"
          ></SeriesDirective>
          <SeriesDirective
            dataSource={graphData}
            xName="x"
            yName="y1"
            name="Average script by canisters"
            type="Line"
            fill={palette[1]}
            width={3}
            // yAxisName="yAxis1"
          ></SeriesDirective>
        </SeriesCollectionDirective>
      </ChartComponent>
    </div>
  );
}
export default CanisterUtilizationGraph;
