import React, { useEffect, useRef, useState, useContext } from "react";
import cheveronDownIcon from "../../assets/cheveron-down.svg";
import styles from "./DropDownInput.module.css";
import { useKeyPress } from "../InputHooks";
import { ScrollContext } from "../../contexts/ScrollContext";

const DropdownInput = ({
  labelName,
  listElements,
  isEnabled,
  value,
  placeholder,
  validationText,
  onSelection,
  display,
}) => {
  const ref = useRef(null);

  const { xScroll, yScroll } = useContext(ScrollContext);

  const [showList, setShowList] = useState(false);
  const [filter, setFilter] = useState(value || "");

  if (useKeyPress("Escape")) {
    if (showList) {
      setShowList(false);
    }
  }

  useEffect(() => {
    setFilter(value);
  }, [value]);

  const handleMouseUp = (e) => {
    if (ref.current) {
      const element = ref.current;
      const rect = element.getBoundingClientRect();
      if (
        e.pageX - xScroll < rect.left ||
        e.pageX - xScroll > rect.left + rect.width ||
        e.pageY - yScroll < rect.top ||
        e.pageY - yScroll > rect.top + rect.height
      ) {
        setShowList(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleMouseUp);

    return () => {
      document.removeEventListener("click", handleMouseUp);
    };
  });

  const handleChange = (text) => {
    setFilter(text);
    if (text.length > 0) {
      setShowList(true);
    }
  };

  const handleKeyDown = (e) => {
    console.log("keyDown");
    if (e.code.toUpperCase() === "TAB") {
      // tab key
      if (
        filter &&
        listElements.filter((o) => {
          if (!filter.length) {
            return true;
          } else {
            return display
              ? o[display].toUpperCase().startsWith(filter.toUpperCase())
              : o.description.toUpperCase().startsWith(filter.toUpperCase());
          }
        }).length === 1
      ) {
        const li = listElements.filter((o) => {
          if (!filter.length) {
            return true;
          } else {
            return display
              ? o[display].toUpperCase().startsWith(filter.toUpperCase())
              : o.description.toUpperCase().startsWith(filter.toUpperCase());
          }
        })[0];
        onSelection(li);

        // original
        // This was causing filter to incorrectly be set to undefined and causing the filter.toUpperCase() call below to cause an error.
        // setFilter(li[display]);

        // Changed by RWD on 9/7/2022.
        // Note that filter is automatically updated once a dropdown selection is made via the useEffect above.  Thus, setting the filter here appears to be redundant.
        setFilter(li.description);

        setShowList(false);
      } else if (
        display &&
        listElements.some(
          (li) => li[display].toUpperCase() === filter.toUpperCase()
        )
      ) {
        const li = listElements.find(
          (li) => li[display].toUpperCase() === filter.toUpperCase()
        );
        onSelection(li);
        setFilter(li[display]);
        setShowList(false);
      } else if (
        !display &&
        listElements.some(
          (li) => li.description.toUpperCase() === filter.toUpperCase()
        )
      ) {
        const li = listElements.find(
          (li) => li.description.toUpperCase() === filter.toUpperCase()
        );
        onSelection(li);
        setFilter(li.description);
        setShowList(false);
      } else {
        setFilter(value);
      }
      setShowList(false);
    }
  };

  const handleKeyUp = (event) => {
    const code = event.keyCode || event.which;
    if (code === 9) {
      setShowList(false);
    }
  };

  let updatedFilter = filter;

  if (filter) {
    if (filter.includes("EV-54")) {
      updatedFilter = updatedFilter.replace("EV-54", "TruScript");
    } else if (filter.includes("Proud")) {
      updatedFilter = updatedFilter.replace("Proud", "TruPak");
    } else if (filter.includes("Pouch")) {
      updatedFilter = updatedFilter.replace("Pouch", "TruPak");
    } else if (filter.includes("VBM")) {
      updatedFilter = updatedFilter.replace("VBM", "TruCard");
    }
  }

  return (
    <div
      ref={ref}
      className={styles.DropDownList__group}
      onKeyDown={(e) => handleKeyDown(e)}
      onKeyUp={handleKeyUp}
      onClick={(e) => e.stopPropagation()}
    >
      <label>{labelName}</label>
      <div className={styles.DropDownList__input_group}>
        <input
          type={"text"}
          placeholder={placeholder}
          disabled={isEnabled ? !isEnabled : false}
          value={updatedFilter}
          //readOnly={true}
          onClick={(e) => {
            e.preventDefault();
          }}
          onChange={(e) => handleChange(e.target.value)}
        />
        <button
          className={styles.selectButton}
          onClick={(e) => {
            e.preventDefault();
            setShowList((prevState) => {
              return !prevState;
            });
          }}
        >
          <img src={cheveronDownIcon} alt={"dropdown button"} />
        </button>
      </div>
      <div className={styles.DropDownList__control_border} />
      {showList && (
        <div className={styles.DropDownList__list}>
          {listElements.length > 0 &&
            listElements
              .filter((o) => {
                if (!filter || filter === value) {
                  return true;
                } else {
                  return display
                    ? o[display].toUpperCase().startsWith(filter.toUpperCase())
                    : o.description
                        .toUpperCase()
                        .startsWith(filter.toUpperCase());
                }
              })
              .map((listElement, index) => {
                let updatedDescription = listElement.description;

                if (updatedDescription.includes("EV-54")) {
                  updatedDescription = updatedDescription.replace(
                    "EV-54",
                    "TruScript"
                  );
                } else if (updatedDescription.includes("Proud")) {
                  updatedDescription = updatedDescription.replace(
                    "Proud",
                    "TruPak"
                  );
                } else if (updatedDescription.includes("Pouch")) {
                  updatedDescription = updatedDescription.replace(
                    "Pouch",
                    "TruPak"
                  );
                } else if (updatedDescription.includes("VBM")) {
                  updatedDescription = updatedDescription.replace(
                    "VBM",
                    "TruCard"
                  );
                }

                return (
                  <p
                    key={index}
                    onClick={() => {
                      onSelection(listElement);
                      setFilter(
                        display ? listElement[display] : listElement.description
                      );
                      setShowList(false);
                    }}
                  >
                    {display ? listElement[display] : updatedDescription}
                  </p>
                );
              })}
        </div>
      )}
      {validationText && (
        <label className={styles.DropDownList__validation}>
          {validationText}
        </label>
      )}
    </div>
  );
};

export default DropdownInput;
