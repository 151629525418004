import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.username) {
        errors.username = 'An email address or username is required'
    }

   if(!values.password) {
       errors.password = 'Password is required'
   }

    return errors;
}
