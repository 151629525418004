import React, { useContext } from "react";
import Popup from "reactjs-popup";
import cheveronDownIcon from "../../../assets/cheveron-down.svg";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import ButtonGen from "../../commonUI/ButtonGen";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./SelectDevice.module.css";

const SelectDevice = ({
  selectedSite,
  onHandleGenerateList,
  selectedDevice,
  onHandleSetSelectedDevice,
  onHandleCloseSelectDeviceModal,
  onHandleResetSelectedSite,
  onHandleResetSelectedDevice,
}) => {
  let popup = null;

  const setRef = (element) => {
    popup = element;
  };

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const labelName = "Select Dispensing Device";

  const placeholder = "Select Device";

  const handleGoBack = () => {
    onHandleResetSelectedSite();
    onHandleResetSelectedDevice();
  };

  return (
    <div className={styles.selectDevice__container}>
      {!smScreen && (
        <CloseModalIcon
          className={styles.selectDevice__closeModalIcon}
          onClick={onHandleCloseSelectDeviceModal}
        />
      )}

      {smScreen && (
        <section className={styles.selectDevice__headingGroup}>
          <div
            className={styles.selectDevice__backArrow}
            onClick={handleGoBack}
          >
            <BackArrowMobile className={styles.selectDevice__backArrow__icon} />
            <p className={styles.selectDevice__goBackTitle}>{"Back"}</p>
          </div>
          <p className={styles.selectDevice__title}>Select Dispensing Device</p>
        </section>
      )}

      <div className={(styles.controlGroup, styles.dropdown)}>
        {!smScreen && (
          <label className={styles.selectDevice__heading}>{labelName}</label>
        )}
        <div className={styles.controlButtonInputGroup}>
          <input
            className={styles.controlInput}
            type={"text"}
            placeholder={placeholder}
            disabled={false}
            value={selectedDevice.name || ""}
            readOnly={true}
            onClick={(e) => e.preventDefault()}
          />
          <Popup
            trigger={
              <button className={styles.controlButton}>
                <img src={cheveronDownIcon} alt={"dropdown button"} />
              </button>
            }
            on={"click"}
            ref={setRef}
            position="bottom right"
            closeOnDocumentClick={true}
            arrow={true}
          >
            <div className={styles.controlButtonPopup}>
              {selectedSite.devices.length > 0 &&
                selectedSite.devices.map((device, index) => {
                  return (
                    <p
                      className={styles.controlButtonPopupListItem}
                      key={index}
                      onClick={() => {
                        onHandleSetSelectedDevice(device);
                        popup.close();
                      }}
                    >
                      {selectedSite.devices[index].name}
                    </p>
                  );
                })}
            </div>
          </Popup>
        </div>
        <div className={styles.controlBorder} />
      </div>

      <div className={styles.selectDevice__buttonContainer}>
        <ButtonGen
          buttonClass={
            !selectedDevice
              ? [styles["button"], styles["button--disabled"]].join(" ")
              : styles.button
          }
          btnDisabled={selectedDevice ? false : true}
          onClickHandler={() => onHandleGenerateList()}
        >
          Generate Replenishment
        </ButtonGen>
      </div>
    </div>
  );
};

export default SelectDevice;
