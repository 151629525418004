import PharmacyService from "../../services/PharmacyService";

export const solveFunction = (
  reportObject,
  data,
  reportObjects,
  rowDataIndex,
  combinedReportFields,
  getPathValue,
  functionLookup
) => {
  let dataObj = {};

  data.forEach((columnValue, columnIndex) => {
    dataObj[combinedReportFields[columnIndex].name] = columnValue;
  });

  let operator = reportObject.operator;
  let field1Idx = reportObjects.findIndex(
    (o) => o.name === reportObject.operands[0].name
  );
  let op1ReportObject = reportObjects[field1Idx];
  let field2Idx = reportObjects.findIndex(
    (o) => o.name === reportObject.operands[1].name
  );
  let op2ReportObject = reportObjects[field2Idx];

  let field1;
  let field2;

  if (op1ReportObject.collection !== "function") {
    field1 = Number(getPathValue(op1ReportObject.name, dataObj));
  } else {
    field1 = Number(functionLookup[op1ReportObject.name][rowDataIndex]);
  }

  if (op2ReportObject.collection !== "function") {
    field2 = Number(getPathValue(op2ReportObject.name, dataObj));
  } else {
    field2 = Number(functionLookup[op2ReportObject.name][rowDataIndex]);
  }

  switch (operator.type) {
    case "ADDITION":
      return (field1 + field2).toFixed(2);
    case "SUBTRACTION":
      return (field1 - field2).toFixed(2);
    case "MULTIPLICATION":
      return (field1 * field2).toFixed(2);
    case "DIVISION":
      if (field2) {
        return (field1 / field2).toFixed(2);
      }
      return "NaN";
    default:
      return 0;
  }
};

export const getColumnAverages = (
  combinedReportFields,
  reportData,
  functionLookup,
  selectedRows,
  numSelectedRows,
  reportObj,
  handleColumnFilters
) => {
  let averages = [];

  for (let i = 0; i < combinedReportFields.length; i++) {
    if (combinedReportFields[i].average) {
      averages.push(0);
    } else {
      averages.push(-1);
    }
  }

  for (let dataRow = 0; dataRow < reportData.length; dataRow++) {
    for (
      let dataColumn = 0;
      dataColumn < combinedReportFields.length;
      dataColumn++
    ) {
      let dataFieldText;
      let reportField = combinedReportFields[dataColumn];
      if (dataColumn <= reportObj.fields?.length) {
        dataFieldText = reportData[dataRow][dataColumn];
      } else if (
        functionLookup[reportField.name] &&
        functionLookup[reportField.name].length > 0
      ) {
        if (functionLookup[reportField.name][dataRow]) {
          dataFieldText = functionLookup[reportField.name][dataRow];
        } else {
          dataFieldText = "---";
        }
      }
      if (reportField.average && selectedRows[dataRow]) {
        averages[dataColumn] += Number(dataFieldText) / numSelectedRows;
      }
    }
  }
  return averages;
};

export const getColumnSums = (
  combinedReportFields,
  reportData,
  functionLookup,
  selectedRows,
  reportObj
) => {
  let sums = [];

  for (let i = 0; i < combinedReportFields.length; i++) {
    if (combinedReportFields[i].sum) {
      sums.push(0);
    } else {
      sums.push(-1);
    }
  }

  for (let dataRow = 0; dataRow < reportData.length; dataRow++) {
    for (
      let dataColumn = 0;
      dataColumn < combinedReportFields.length;
      dataColumn++
    ) {
      let dataFieldText;
      // let dataFieldText = getDataFieldText(reportField, data);
      let reportField = combinedReportFields[dataColumn];
      if (dataColumn <= reportObj.fields?.length) {
        dataFieldText = reportData[dataRow][dataColumn];
      } else if (
        functionLookup[reportField.name] &&
        functionLookup[reportField.name].length > 0
      ) {
        if (functionLookup[reportField.name][dataRow]) {
          dataFieldText = functionLookup[reportField.name][dataRow];
        } else {
          dataFieldText = "---";
        }
      }
      if (reportField.sum && selectedRows[dataRow]) {
        sums[dataColumn] += Number(dataFieldText);
      }
    }
  }
  return sums;
};

const getPhoneNumbers = (array) => {
  let phoneData = "";
  array.forEach((phoneObj) => {
    if (!phoneData) {
      phoneData = `${phoneObj.type.type}: ${phoneObj.number}`;
    } else {
      phoneData += `, ${phoneObj.type.type}: ${phoneObj.number}`;
    }
  });
  return phoneData;
};
const getRoles = (array) => {
  let roleData = "";
  array.forEach((role) => {
    if (!roleData) {
      roleData = `${role.type}`;
    } else {
      roleData += `, ${role.type}`;
    }
  });
  return roleData;
};

const getSites = async (array, user) => {
  let data = "";
  const sites = await PharmacyService.list(user);
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    const site = sites.find((o) => o._id === item);
    if (!data) {
      data = `${site.name}`;
    } else {
      data += `, ${site.name}`;
    }
  }
  return data;
};

const getBarcodes = (array) => {
  let barcodeData = "";
  array.forEach((barcode) => {
    if (!barcodeData) {
      barcodeData = `${barcode}`;
    } else {
      barcodeData += `, ${barcode}`;
    }
  });
  return barcodeData;
};

export const getDataFields = async (
  combinedReportFields,
  reportObj,
  reportData,
  formatDate,
  functionLookup,
  handleColumnFilters
) => {
  let filters = [];

  for (let i = 0; i < combinedReportFields.length; i++) {
    filters.push([]);
  }

  for (let dataRow = 0; dataRow < reportData.length; dataRow++) {
    for (
      let dataColumn = 0;
      dataColumn < combinedReportFields.length;
      dataColumn++
    ) {
      let filter = filters[dataColumn];

      let reportField = combinedReportFields[dataColumn];
      try {
        let dataFieldText;

        if (dataColumn <= reportObj.fields?.length) {
          dataFieldText = reportData[dataRow][dataColumn];
        } else {
          dataFieldText = "";
        }

        let collectionType;

        if (combinedReportFields[dataColumn].collection === "function") {
          collectionType = "function";
        } else {
          collectionType = collectionType =
            combinedReportFields[dataColumn].dataType;
        }
        switch (collectionType) {
          case "String":
            if (
              dataFieldText &&
              dataFieldText.length > 0 &&
              isNaN(Number(dataFieldText))
            ) {
              let dataFieldArray = [...dataFieldText];
              dataFieldText = dataFieldArray.join("");
            }
            break;
          case "Boolean":
            dataFieldText = dataFieldText ? "Yes" : "No";
            break;
          case "Number":
            break;
          case "Date":
            dataFieldText = formatDate(dataFieldText);
            break;
          case "Array":
            if (reportField.name === "phoneNumbers") {
              dataFieldText = getPhoneNumbers(dataFieldText);
            } else if (reportField.name === "roles") {
              dataFieldText = getRoles(dataFieldText);
            } else if (reportField.name === "sites") {
              dataFieldText = await getSites(dataFieldText);
            } else if (reportField.name === "barcodeList") {
              dataFieldText = getBarcodes(dataFieldText);
            } else {
              dataFieldText = "";
            }
            break;
          case "function":
            if (functionLookup[reportField.name][dataRow]) {
              dataFieldText = functionLookup[reportField.name][dataRow];
            } else {
              dataFieldText = "---";
            }
            break;
          default:
          // console.log(
          //   `Reading data of type ${collectionType} has not been defined`
          // );
        }

        filter.push({
          selected: false,
          description: dataFieldText || "",
          index: dataColumn,
        });
      } catch (e) {
        console.log(e);
      }
    }
  }
  handleColumnFilters(filters);
};
