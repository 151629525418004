import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import Popup from "../../../components/PopupMenu/Popup";
import RoleCheckService from "../../../services/RoleCheckService";
import { ReactComponent as MoreActionsIcon } from "../../../assets/more-actions.svg";
import { ReactComponent as MoreActionsIconMobile } from "../../../assets/more-actions-mobile.svg";
import styles from "./PendingDispensingOrder.module.css";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
const PendingDispensingOrderItem = ({
  user,
  item,
  onActivateSubstituteProductModal,
  onActivateAddProductModal,
  onActivateAdminDateTimeModal,
  onActivateAdjustQuantityModal,
  onActivateChangePatientLocationModal,
  onActivatePriorityModal,
  onHandleSelectedItem,
}) => {
  const [showPopup, setShowPopup] = useState(false);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const { t } = useTranslation("pendingDispensingOrder");

  const handleOnClick = (e) => {
    e.stopPropagation();
    if (!showPopup) {
      setShowPopup(true);
    }
  };

  let actionListItems = [
    {
      type: "Change Admin Date/Time",
      description: t("menuItem2"),
    },
    {
      type: "Adjust Quantity",
      description: t("menuItem3"),
    },
    {
      type: "Change Patient Location",
      description: t("menuItem4"),
    },
    {
      type: "Change Priority",
      description: "Change Priority",
    },
  ];

  if (!RoleCheckService.changePatientLocationFromOrderDetails(user)) {
    const index = actionListItems.findIndex(
      (o) => o.type === "Change Patient Location"
    );
    actionListItems.splice(index, 1);
  }

  if (!RoleCheckService.adjustQtyFromOrderDetails(user)) {
    const index = actionListItems.findIndex(
      (o) => o.type === "Adjust Quantity"
    );
    actionListItems.splice(index, 1);
  }

  if (!RoleCheckService.modifyAdminDateTimeFromOrderDetails(user)) {
    const index = actionListItems.findIndex(
      (o) => o.type === "Change Admin Date/Time"
    );
    actionListItems.splice(index, 1);
  }

  if (
    RoleCheckService.addProductFromOrderDetails(user) &&
    (!item.product ||
      (item?.errorTypes?.length && item.errorTypes.includes("NO_PRODUCT")))
  ) {
    actionListItems.splice(1, 0, {
      type: "Add Product",
      description: t("menuItem0"),
    });
  }

  if (
    RoleCheckService.substituteProductFromOrderDetails(user) &&
    item.product &&
    item.product.packageId
  ) {
    actionListItems.splice(1, 0, {
      type: "Substitute Product",
      description: t("menuItem1"),
    });
  }

  if (!user || (user && !RoleCheckService.addProductFromOrderDetails(user))) {
    const index = actionListItems.findIndex((o) => o.type === "Add Product");
    actionListItems.splice(index, 1);
  }

  const getActionMenu = () => {
    let listItems = [...actionListItems];
    return listItems;
  };

  const handleAction = (action) => {
    switch (action.type) {
      case "Add Product":
        onActivateAddProductModal();
        onHandleSelectedItem(item);
        break;
      case "Substitute Product":
        onActivateSubstituteProductModal();
        onHandleSelectedItem(item);
        break;
      case "Change Admin Date/Time":
        onActivateAdminDateTimeModal();
        onHandleSelectedItem(item);
        break;
      case "Adjust Quantity":
        onActivateAdjustQuantityModal();
        onHandleSelectedItem(item);
        break;
      case "Change Patient Location":
        onActivateChangePatientLocationModal();
        onHandleSelectedItem(item);
        break;
      case "Change Priority":
        onActivatePriorityModal();
        onHandleSelectedItem(item);
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={
        smScreen && item.errorExists
          ? `${styles.PendingDispensingOrders__actionsContainer} ${styles["PendingDispensingOrders__actionsContainer--withError"]}`
          : styles.PendingDispensingOrders__actionsContainer
      }
      onClick={(e) => handleOnClick(e)}
    >
      {smScreen ? <MoreActionsIconMobile /> : <MoreActionsIcon />}
      {showPopup && (
        <div className={styles.PendingDispensingOrders__popupContainer}>
          <Popup
            menuItems={getActionMenu()}
            onAction={(action) => {
              setShowPopup(false);
              handleAction(action);
            }}
            onClose={() => setShowPopup(false)}
            rightJustify={true}
          />
        </div>
      )}
    </div>
  );
};

export default PendingDispensingOrderItem;
