import React from "react";
import styles from "./TabItem.module.css";

const TabItem = ({ tab, isSelected, onTabSelected, fontSize }) => {
  const handleKeyUp = (event) => {
    const code = event.keyCode || event.which;
    if (code === 13) {
      onTabSelected(tab.index);
    }
  };

  return (
    <div
      tabIndex="0"
      className={
        isSelected
          ? [styles["tabItem__container"], styles["tabItem__tabSelected"]].join(
              " "
            )
          : styles.tabItem__container
      }
      onClick={() => {
        document.activeElement.blur();
        onTabSelected(tab.index);
      }}
      onKeyUp={handleKeyUp}
    >
      {tab.badge > 0 && (
        <div className={styles.tabItem_badge}>
          <p>{tab.badge}</p>
        </div>
      )}
      <p
        className={
          isSelected
            ? [styles["tabItem__text"], styles["tabItem__text--selected"]].join(
                " "
              )
            : styles.tabItem__text
        }
      >
        {tab.text}
      </p>
    </div>
  );
};

export default TabItem;
