import React, { createContext, useState, useEffect } from "react";

export const NavbarContext = createContext();

export function NavbarProvider(props) {
  const [activeNavbarMenuItemViaContext, setNavbarActiveMenuItem] =
    useState(-1);

  const handleActiveNavbarMenuItemViaContext = (menuItem) => {
    setNavbarActiveMenuItem(menuItem);
  };

  const [labelModificationInProgressViaContext, setLabelModifcationInProgress] =
    useState(false);

  const handleLabelModificationInProgressViaContext = (boolVal) => {
    setLabelModifcationInProgress(boolVal);
  };

  return (
    <NavbarContext.Provider
      value={{
        activeNavbarMenuItemViaContext,
        handleActiveNavbarMenuItemViaContext,
        labelModificationInProgressViaContext,
        handleLabelModificationInProgressViaContext,
      }}
    >
      {props.children}
    </NavbarContext.Provider>
  );
}
