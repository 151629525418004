import EmailValidator from "email-validator";
/*
let user = {
            firstName: firstName,
            lastName: lastName,
            emailAddress: emailAddress,
            username: username,
            password: password,
            roles: selectedRoles,
            isEnterprise: isEnterprise,
            sites: selectedSites
        }s
 */
export default function validate(values, isNew) {
  let errors = {};

  if (values.accountSecurity.type === "USERNAME" && !values.username) {
    errors.username = "Username is required";
  } else if (
    values.accountSecurity.type === "EMAIL" &&
    !EmailValidator.validate(values.emailAddress)
  ) {
    errors.emailAddress = "Valid email address required";
  }

  if (values.accountSecurity.type === "USERNAME" && !values.password && isNew) {
    errors.password = "Password is required";
  }

  if (!values.firstName) {
    errors.firstName = "First name is required";
  }

  if (!values.lastName) {
    errors.lastName = "Last name is required";
  }

  if (!values.defaultSite) {
    errors.defaultSite = "Please select a default site";
  }

  if (values.roles.length === 0) {
    errors.roles = "At least one role assignment required";
  }

  if (!values.isEnterprise && values.sites.length === 0) {
    errors.sites = "At least one site assignment is required";
  }

  return errors;
}
