import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import TabItem from "./TabItem";
import styles from "./TabBar.module.css";

const TabBar = ({
  tabs,
  onSelectedTab,
  fontSize,
  dynamicActiveIndex,
  resetTabSelectionToZero,
  site,
  dynamicSelectedIndex = 0,
}) => {
  const location = useLocation();

  fontSize = fontSize || "22px";

  const [selectedTabIndex, setSelectedTabIndex] =
    useState(dynamicSelectedIndex);

  const handleTabSelection = (index) => {
    setSelectedTabIndex(index);
    onSelectedTab(index, tabs[index]);
  };

  const handleTabReset = () => {
    setSelectedTabIndex(0);
    onSelectedTab(0, tabs[0]);
  };

  useEffect(() => {
    // Added by RWD 12/23/2023
    // time delay ensures there are no conflicts with handleTabSelection

    if (site) {
      const tabIndex = tabs.findIndex((tab) => tab.site._id === site._id);
      if (tabIndex !== -1 && tabs && tabs.length) {
        setTimeout(() => {
          handleTabSelection(tabIndex);
        }, 100);
      }
    }
  }, [site, tabs]);

  useEffect(() => {
    if (resetTabSelectionToZero) {
      handleTabReset();
    }
  }, [resetTabSelectionToZero]);

  useEffect(() => {
    switch (location.pathname) {
      case "/settings/sites":
        setSelectedTabIndex(0);
        break;
      case "/settings/facilities":
        setSelectedTabIndex(1);
        break;
      case "/settings/users":
        setSelectedTabIndex(2);
        break;
      case "/settings/products":
        setSelectedTabIndex(3);
        break;

      default:
      // code block
    }
  }, [location.pathname]);

  useEffect(() => {
    if (dynamicActiveIndex) {
      setSelectedTabIndex(dynamicActiveIndex);
    }
  }, []);

  return (
    <div className={styles.tabBar__container}>
      {tabs.map((tab) => {
        return (
          <div className={styles.tabBar__itemContainer} key={tab.index}>
            <TabItem
              tab={tab}
              isSelected={tab.index === selectedTabIndex}
              onTabSelected={handleTabSelection}
              onResetTabToDefault={handleTabReset}
              fontSize={fontSize}
            />
          </div>
        );
      })}
    </div>
  );
};

export default TabBar;
