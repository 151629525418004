import React, { Fragment, useState, useEffect, useContext } from "react";
import TabBar from "../components/TabBar";
import PharmacySites from "./PharmacySites/PharmacySites";
import Users from "./Users/Users";
import Facilities from "./Facilities/Facilities";
import UserProfilePopup from "./UserProfilePopup";
import { useTranslation } from "react-i18next";
import Products from "./Product/Products";
//import AlarmDateTime from "../components/AlarmDateTime/AlarmDateTime";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_MENU_ITEM } from "./NavBar";
import { useMediaQuery } from "react-responsive";
import { ReactComponent as BackArrow } from "../assets/mobile-back-arrow.svg";
import { ReactComponent as ReceiveIcon } from "../assets/receive-icon.svg";
import { ReactComponent as PicFrameEmpty } from "../assets/pic-frame-empty.svg";
import ImageService from "../services/ImageService";
import { ScreenContext } from "../contexts/ScreenContext";
import styles from "./Settings.module.css";
import RoleCheckService from "../services/RoleCheckService";

const Settings = ({ user, onLogOut, alarm }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [subTitle, setSubTitle] = useState("");
  const [profilePic, setProfilePic] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [tabs, setTabs] = useState([]);

  useEffect(() => {
    if (location.pathname === "/settings") {
      handleSelectedTab(0);
    }
  }, [location]);

  useEffect(() => {
    if (!profilePic && user.profilePic) {
      ImageService.read(user, user.profilePic).then((imageDoc) => {
        setProfilePic(imageDoc);
      });
    }
  }, [user]);

  useEffect(() => {
    switch (location.pathname) {
      case "/settings/sites":
        setSubTitle("");
        break;
      case "/settings/sites/create":
        setSubTitle("Edit Site");
        break;
      case "/settings/facilities":
        setSubTitle("");
        break;
      case "/settings/facilities/create":
        setSubTitle("Edit Facility");
        break;
      case "/settings/facilities/deliverySchedule":
        setSubTitle("Delivery Schedule");
        break;
      case "/settings/users":
        setSubTitle("");
        break;
      case "/settings/users/create":
        setSubTitle("Add New User");
        break;
      case "/settings/products":
        setSubTitle("");
        break;
      case "/settings/products/create":
        setSubTitle("Update Product");
        break;
      case "/settings/sites/stock-locations":
        setSubTitle("Stock Locations");
        break;
      case "/settings/therapeuticEquivalencies":
        setSubTitle("");
        break;

      default:
      // code block
    }
  }, [location.pathname]);

  const { t, i18n } = useTranslation("settings");

  useEffect(() => {
    const canViewPharmacySettings = RoleCheckService.viewPharmacySettings(user);
    const canViewFacilitySettings = RoleCheckService.viewFacilitySettings(user);
    const canViewProductSettings = RoleCheckService.viewProductSettings(user);
    const canViewUserSettings = RoleCheckService.viewUserSettings(user);
    const tempTabs = [];
    if (canViewPharmacySettings) {
      tempTabs.push({
        index: 0,
        text: t("tab0"),
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      });
    }
    if (canViewFacilitySettings) {
      tempTabs.push({
        index: 1,
        text: t("tab1"),
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      });
    }
    if (canViewUserSettings) {
      tempTabs.push({
        index: 2,
        text: t("tab2"),
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      });
    }
    if (canViewProductSettings) {
      tempTabs.push({
        index: 3,
        text: t("tab3"),
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      });
    }
    if (canViewProductSettings) {
      tempTabs.push({
        index: 4,
        text: 'Therapeutic Equivalencies',
        icon: <ReceiveIcon style={{ fill: "inherit" }} />,
      });
    }
    setTabs(tempTabs);
  }, [user]);

  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [today, setToday] = useState(new Date());

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  useEffect(() => {
    if (alarm) {
      switch (alarm.callToActionHandler) {
        case "PRODUCT":
          setSelectedTab(tabs[3]);
          break;
        default:
      }
    }
  }, [alarm]);

  useEffect(() => {
    const interval = setInterval(() => {
      setToday(new Date());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleSelectedTab = (index) => {
    if (index === 0) {
      navigate("sites");
    } else if (index === 1) {
      navigate("facilities", { state: { profilePic: profilePic } });
    } else if (index === 2) {
      navigate("users", { state: { profilePic: profilePic } });
    } else if (index === 3) {
      navigate("products", { state: { alarm: alarm } });
    } else if (index === 4) {
      navigate("therapeuticEquivalencies");
    }
    setSelectedTab(tabs[index]);
  };

  const handlePopup = (boolVal) => {
    setShowPopup(boolVal);
  };

  return (
    <main className={styles.settings__main}>
      {(!smScreen || subTitle === "") && (
        <>
          <section className={styles.settings__headerRow}>
            <div className={styles.settings__tabBar}>
              {!smScreen && (
                <TabBar
                  tabs={tabs}
                  onSelectedTab={handleSelectedTab}
                  fontSize={"14px"}
                />
              )}
            </div>
            {/*{!smScreen && <AlarmDateTime />}*/}
          </section>

          {smScreen && (
            <Fragment>
              <TabBar
                tabs={tabs}
                onSelectedTab={handleSelectedTab}
                fontSize={"14px"}
              />
              <div
                className={styles.settings__picFrameHeaderContainer}
                onClick={() => handlePopup(true)}
              >
                {profilePic ? (
                  <img
                    className={styles.settings__picFrameHeader}
                    src={
                      profilePic.mimeType
                        ? `data:image/${profilePic.mimeType};base64,${profilePic.base64EncodedString}`
                        : profilePic.base64EncodedString
                    }
                    alt={"logo"}
                  />
                ) : (
                  <PicFrameEmpty className={styles.settings__picFrameHeader} />
                )}
                <UserProfilePopup
                  user={user}
                  showPopup={showPopup}
                  onHandlePopup={handlePopup}
                  onLogOut={onLogOut}
                />
              </div>
            </Fragment>
          )}
        </>
      )}
      <Outlet />
      {/*{selectedTab.index === 0 && <PharmacySites user={user} />}*/}
      {/*{selectedTab.index === 1 && <Facilities user={user} />}*/}
      {/*{selectedTab.index === 2 && <Users user={user} />}*/}
      {/*{selectedTab.index === 3 && <Products user={user} alarm={alarm} />}*/}
    </main>
  );
};

export default Settings;
