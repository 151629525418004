import ApiService from "./ApiService";
import axios from "axios";

class StockLocationService extends ApiService {
  static getDevices = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/devices`;
      let response = await axios.get(url, rc);
      return response.data.devices;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDeviceCoverages = async (
    user,
    site,
    stockLocationsIds,
    dispensingOrder
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/dispensing-orders/${dispensingOrder._id}/get-device-coverage?stockLocationIds=${stockLocationsIds}`;
      let response = await axios.get(url, rc);
      return response.data.devices;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static create = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations`;

      let response = await axios.post(
        url,
        { stockLocation: stockLocation },
        rc
      );
      return response.data.stockLocation;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static delete = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}`;

      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static zeroInventory = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/zero-inventory`;

      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static list = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations`;
      let response = await axios.get(url, rc);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static get = async (user, site, id) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${id}`;
      let response = await axios.get(url, rc);
      return response.data.stockLocation;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getDispensableLocations = async (user, site) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations?dispensable=1`;
      let response = await axios.get(url, rc);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static update = async (
    user,
    site,
    stockLocation,
    parentStockLocation = null
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}`;

      let response = await axios.put(
        url,
        {
          stockLocation: stockLocation,
          parentStockLocation: parentStockLocation,
        },
        rc
      );
      return response.data.stockLocation;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getByProduct = async (user, site, product) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations?productId=${product._id}`;
      let response = await axios.get(url, rc);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getByBarcode = async (user, site, barcode) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations?barcode=${barcode}`;
      let response = await axios.get(url, rc);
      return response.data.stockLocation;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getStockedItems = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/stocked-items`;
      let response = await axios.get(url, rc);
      return response.data.stockedItems.sort((a, b) => {
        if (a.qty < b.qty) {
          return -1;
        }
        if (b.qty < a.qty) {
          return 1;
        }
        return 0;
      });
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static audit = async (
    user,
    site,
    stockLocation,
    canister,
    product,
    stockedItems,
    expectedCount,
    actualCount
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/audit`;
      await axios.post(
        url,
        {
          stockedItems: stockedItems,
          expectedCount: expectedCount,
          actualCount: actualCount,
          product: product,
        },
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getControlledStockLocationsOnDevice = async (user, device) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${device.site._id}/stock-locations/${device._id}/controlled-drugs`;
      let response = await axios.get(url, rc);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getHazardousStockLocationsOnDevice = async (user, device) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${device.site._id}/stock-locations/${device._id}/hazardous-drugs`;
      let response = await axios.get(url, rc);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getStockNeeds = async (user, device) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${device.site._id}/stock-locations/${device._id}/get-stock-needs`;
      let response = await axios.get(url, rc);
      return response.data.stockNeeds;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static hasInventory = async (user, device, canisterNo) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${device.site._id}/stock-locations/${device._id}/has-inventory?devicePosition=${canisterNo}`;
      let response = await axios.get(url, rc);
      return response.data.hasInventory;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getCleaningSchedule = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/cleaning-schedule`;
      let response = await axios.get(url, rc);
      return response.data.cleaningSchedule;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static addCleaningTask = async (user, site, stockLocation, data) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/cleaning-schedule`;
      let response = await axios.post(url, { cleaningTask: data }, rc);
      return response.data.cleaningTask;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static deleteCleaningTask = async (user, task) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${task.account._id}/sites/${task.site._id}/stock-locations/${task.stockLocation._id}/cleaning-schedule/${task._id}`;
      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateCleaningTask = async (user, task) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${task.account._id}/sites/${task.site._id}/stock-locations/${task.stockLocation._id}/cleaning-schedule/${task._id}`;
      let response = await axios.put(url, { cleaningTask: task }, rc);
      return response.data.cleaningTask;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getCleaningTasksDue = async (user, siteIds) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/dashboard/cleaning-tasks-due?siteIds=${siteIds}`;
      let response = await axios.get(url, rc);
      return response.data.cleaningTasksDue;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static createTestOrders = async (user, site, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${site._id}/stock-locations/${stockLocation._id}/create-test-orders`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getStockLocationsConfigFile = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/stockLocations`;

      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getAlarms = async (user, stockLocation) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${stockLocation.site._id || stockLocation.site}/stock-locations/${stockLocation._id}/alarms`;

      let response = await axios.get(url, rc);
      return response.data.alarms;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default StockLocationService;
