import ApiService from "./ApiService";
import axios from 'axios';

class PharmacyService extends ApiService {
    static getById = async (user, siteId) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            const response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/sites/${siteId}`, rc);
            return response.data;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static createSite = async (user, site) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.post(`${ApiService.BASE_URL}/accounts/${user.account._id}/sites`, site, rc);
            return response.data; // site document
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static siteExists = async (user, name) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/sites/exists?name=${encodeURIComponent(name)}`, rc);
            return response.data.exists;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static list = async (user) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/sites`, rc);
            return response.data.sites;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static update = async (userLoggedIn, site) => {
        try {
            const rc = {
                headers: {'x-access-token': userLoggedIn.token}
            }

            let response = await axios.put(`${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/sites/${site._id}`, {site: site}, rc);
            return response.data.site;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default PharmacyService;
