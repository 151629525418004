import React, { Fragment, useState } from "react";

import NavBar from "../../partials/NavBar";

import styles from "./DropDown.module.css";

const DropDown = ({
  user = { user },
  onMenuSelection = { onMenuSelection },
  menuSelection = { menuSelection },
}) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggleMenu = () => {
    setToggleMenu((prevState) => {
      return !prevState;
    });
  };

  const Hamburger = (
    <div className={styles.navmenu} onClick={() => handleToggleMenu()}>
      <div
        className={[
          styles["navmenu__button"],
          styles["navmenu__button--home"],
          styles["navmenu__--menuOpen"],
        ].join(" ")}
      >
        <span
          className={
            !toggleMenu
              ? [styles["navmenu__icon"], styles["navmenu__icon--home"]].join(
                  " "
                )
              : [
                  styles["navmenu__icon"],
                  styles["navmenu__icon--menuOpen"],
                  styles["navmenu__icon--home"],
                ].join(" ")
          }
        >
          &nbsp;
        </span>
      </div>
    </div>
  );

  return (
    <Fragment>
      {toggleMenu && (
        <div
          className={styles.backdrop}
          onClick={() => handleToggleMenu()}
        ></div>
      )}
      {!toggleMenu && Hamburger}
      {toggleMenu ? (
        <div className={styles["pulldown"]}>
          <NavBar
            user={user}
            onMenuSelection={onMenuSelection}
            menuSelection={menuSelection}
            onCloseDropDownMenu={handleToggleMenu}
          />
        </div>
      ) : (
        <div
          className={[styles["pulldown"], styles["pulldown--hide"]].join(" ")}
        >
          <NavBar
            user={user}
            onMenuSelection={onMenuSelection}
            menuSelection={menuSelection}
          />
        </div>
      )}
    </Fragment>
  );
};

export default DropDown;
