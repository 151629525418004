import React, { useState, useEffect, useContext } from "react";
import ConfigService from "../../../services/ConfigService";
import { ReactComponent as TrashIcon } from "../../../assets/trash-icon.svg";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import styles from "./Report2Collections.module.css";
import ReportService from "../../../services/ReportService";
import Node from "../../../functions/Node";
import Report2Fields from "../Report2Fields/Report2Fields";
import { deepCopy } from "../../../functions/utilities";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";

const Report2Collections = ({
  handleReturnToHeader,
  handleResetAppState,
  reportObj,
  handleReportObj,
  user,
  collectionsObj,
  handleCollectionsObj,
  handleDeactivateCollectionsPage,
  onSaveData,
  handleNotFromHeaderPage,
  onIsNotNew,
  EditSaveButtonGroup,
  editMode,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const [collectionsObjLocal, setCollectionsObjLocal] = useState([]);
  const report = collectionsObj.report;
  const [reportConcepts, setReportConcepts] = useState([]);
  const [reportConceptsPopulated, setReportConceptsPopulated] = useState(false);
  const [lookupDictionary, setLookupDictionary] = useState({});
  const [nodeListArray, setNodeListArray] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [selectedCollections, setSelectedCollections] = useState([]);
  const [selectedCollectionsLocal, setSelectedCollectionsLocal] = useState([]);
  const [activateFieldsPage, setActivateFieldsPage] = useState(false);
  const [reportConceptsUpdatedForEdit, setReportConceptsUpdatedForEdit] =
    useState(false);
  const [groupedCollections, setGroupedCollections] = useState([]);
  const [groupedCollectionsLookup, setGroupedCollectionsLookup] = useState("");

  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  const getNodeList = async (schema, parent) => {
    const temp = [];
    const path = await ReportService.getSchemaPaths(user, schema);
    for (let key in path) {
      const node = new Node(path[key]);
      node.parent = parent;
      if (parent) {
        parent.addChild(node);
      }
      temp.push(node);
    }
    if (parent) {
      parent.children.sort((a, b) => {
        if (a.obj.path < b.obj.path) return -1;
        if (b.obj.path < a.obj.path) return 1;
        return 0;
      });
    }

    return temp;
  };

  useEffect(() => {
    let groupedCollectionsLookupTemp = {};
    reportConcepts.forEach((reportConcept) => {
      let parentSchema = reportConcept.schema;
      let groupedCollectionsTemp = [
        parentSchema,
        ...reportConcept.associations,
      ];
      reportConcepts.forEach((conceptObj) => {
        if (
          conceptObj.associations.includes(parentSchema) &&
          !groupedCollectionsTemp.includes(conceptObj.schema)
        ) {
          groupedCollectionsTemp.push(conceptObj.schema);
        }
      });

      groupedCollectionsLookupTemp[parentSchema] = groupedCollectionsTemp;
    });
    setGroupedCollectionsLookup(groupedCollectionsLookupTemp);
  }, [reportConcepts]);

  useEffect(() => {
    if (
      collectionsObjLocal.collections?.length === 1 &&
      collectionsObjLocal.collections[0] === "account"
    ) {
      setReportConcepts((prevState) => {
        return prevState.map((reportConcept) => ({
          ...reportConcept,
          isDraggable: reportConcept.schema !== "account" ? true : false,
          isSelected: reportConcept.schema !== "account" ? false : true,
        }));
      });
    }
  }, [collectionsObjLocal.collections]);

  useEffect(() => {
    if (
      collectionsObj &&
      collectionsObj.collections.length &&
      collectionsObj.fields.length
    ) {
      let tempCollectionsObj = { ...collectionsObj };
      setCollectionsObjLocal(tempCollectionsObj);

      let tempSelectedCollections = [...selectedCollections];
      setSelectedCollectionsLocal(tempSelectedCollections);
    } else if (
      collectionsObjLocal &&
      collectionsObjLocal?.collections?.length &&
      reportObj.collections.length
    ) {
      handleCollectionsObj(collectionsObjLocal);
      setSelectedCollections(selectedCollectionsLocal);
    }
  }, [collectionsObj, collectionsObj.collections]);

  useEffect(() => {
    try {
      if (
        !reportConceptsUpdatedForEdit &&
        nodeListArray.length > 0 &&
        reportObj
      ) {
        let refIndexes = [];
        let tempReportConcepts = [...reportConcepts];
        let tempUpdatedSelectedCollectionArray = [];
        let collectionsSeen = [];

        reportObj.collections.forEach((item) => {
          let collection = item;
          if (!collectionsSeen.includes(collection)) {
            collectionsSeen.push(collection);
            reportConcepts.forEach((reportConcept, reportConceptIndex) => {
              if (reportConcept.schema === collection) {
                refIndexes.push(reportConceptIndex);

                const updatedReportConcept = {
                  isDraggable: false,
                  isSelected: true,
                  refIndex: reportConceptIndex,
                  schema: reportConcept.schema,
                  title: reportConcept.title,
                  associations: reportConcept.associations,
                };
                tempReportConcepts[reportConceptIndex] = updatedReportConcept;

                let tempUpdatedSelectedCollection =
                  handleOnDropSchema(updatedReportConcept);
                if (tempUpdatedSelectedCollection) {
                  tempUpdatedSelectedCollectionArray.push(
                    tempUpdatedSelectedCollection[0]
                  );
                }
              }
            });
          }
        });

        if (refIndexes.length > 0) {
          setReportConcepts(tempReportConcepts);
          setReportConceptsUpdatedForEdit(true);
          setSelectedCollections(tempUpdatedSelectedCollectionArray);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [nodeListArray, reportObj.collections.length]);

  useEffect(() => {
    if (reportConcepts.length > 0 && Object.keys(lookupDictionary).length > 0) {
      const getNodeListArray = async (_) => {
        let tempNodeListArray = [];

        for (let index = 0; index < reportConcepts.length; index++) {
          const schema = reportConcepts[index].schema;
          const nodeListNew = await getNodeList(schema);
          tempNodeListArray.push(nodeListNew);
        }
        setNodeListArray(tempNodeListArray);
      };
      getNodeListArray();
    }
  }, [lookupDictionary, reportConcepts]);

  useEffect(() => {
    let tempDictionary = {};
    if (reportConcepts.length > 0) {
      reportConcepts.forEach((reportConcept) => {
        tempDictionary[reportConcept.schema] = reportConcept.title;
      });
    }
    setLookupDictionary(tempDictionary);
  }, [reportConcepts]);

  useEffect(() => {
    if (reportConcepts.length && !reportConceptsPopulated) {
      setReportConceptsPopulated(true);
    }
  }, [reportConcepts]);

  useEffect(() => {
    if (reportConceptsPopulated && selectedCollections.length) {
      if (
        groupedCollections.length === 0 &&
        nodeListArray.length > 0 &&
        selectedCollections.length > 0 &&
        selectedCollections[0].schema !== "account"
      ) {
        const parentSchema = selectedCollections[0].schema;

        setGroupedCollections(groupedCollectionsLookup[parentSchema]);

        let tempReportConcepts = [...reportConcepts];

        tempReportConcepts.forEach((concept, index) => {
          concept.refIndex = index;
          if (
            !groupedCollectionsLookup[parentSchema].includes(concept.schema)
          ) {
            concept.isSelected = false;
            concept.isDraggable = false;
          }
        });
        setReportConcepts(tempReportConcepts);
      } else if (
        selectedCollections.length > 0 &&
        selectedCollections[0].schema === "account"
      ) {
        let tempReportConcepts = [...reportConcepts];
        tempReportConcepts.forEach((concept, index) => {
          concept.refIndex = index;
          if (
            selectedCollections[1]?.schema &&
            !groupedCollectionsLookup[selectedCollections[1].schema].includes(
              concept.schema
            )
          ) {
            concept.isSelected = false;
            concept.isDraggable = false;
          }
        });
        setReportConcepts(tempReportConcepts);
      }
    }
  }, [selectedCollections, reportConceptsPopulated]);

  useEffect(() => {
    let tempArraySchemas = [];
    let tempArrayFields = [];
    selectedCollections.forEach((collection, collectionIndex) => {
      let useExistingField =
        collectionsObj?.fields[collectionIndex]?.expanded &&
        collectionsObj?.fields &&
        collectionsObj?.fields.length &&
        collectionsObj.fields[collectionIndex].expanded;
      const index = collection.refIndex;
      if (!tempArraySchemas.includes(collection.schema)) {
        tempArraySchemas.push(reportConcepts[index]?.schema);
        tempArrayFields.push({
          expanded: useExistingField ? true : false,
          collection: collection.schema,
          name: collection.title,
          nodeList: nodeListArray[index],
        });
      }
    });
    collectionsObj.collections = tempArraySchemas;
    collectionsObj.fields = tempArrayFields;
  }, [selectedCollections]);

  const goBackHandler = () => {
    handleDeactivateCollectionsPage();
    handleReturnToHeader(true);
  };

  const wrapReportConcept = (concept, index) => {
    let conceptStyle;
    if (!concept.isSelected && concept.isDraggable) {
      conceptStyle = `${styles.Report2Collections__reportConcept}`;
    } else if (concept.isSelected) {
      conceptStyle = `${styles.Report2Collections__reportConcept} ${styles["Report2Collections__reportConcept--active"]}`;
    } else {
      conceptStyle = `${styles.Report2Collections__reportConcept} ${styles["Report2Collections__reportConcept--inActive"]}`;
    }
    return (
      <div
        key={index}
        className={conceptStyle}
        draggable={concept.isDraggable ? true : false}
        onDrag={(e) => handleOnDrag(e, concept)}
      >
        {concept.title}
      </div>
    );
  };

  const handleOnDrag = (e, reportConcept) => {
    setSelectedCollection(reportConcept);
  };

  /************************************************************ */
  useEffect(() => {
    ConfigService.reportConcepts(user).then((reportConcepts) => {
      const sortedArray = reportConcepts.sort((a, b) => {
        if (a.title < b.title) {
          return -1;
        }
        if (b.title < a.title) {
          return 1;
        }
        return 0;
      });

      sortedArray.forEach((concept) => {
        concept.isSelected = false;
        concept.isDraggable = true;
      });
      setReportConcepts(sortedArray);
    });
  }, [user, report]);
  /************************************************************ */

  const handleOnDropSchema = (editSelectedSelection = null) => {
    let found = false;

    let selectedCollectionLocal;

    if (editSelectedSelection) {
      selectedCollectionLocal = editSelectedSelection;
    } else {
      selectedCollectionLocal = selectedCollection;
    }

    if (selectedCollectionLocal) {
      let tempSelectedCollections = [...selectedCollections];
      for (let i = 0; i < tempSelectedCollections.length; i++) {
        if (
          tempSelectedCollections[i].schema === selectedCollectionLocal.schema
        ) {
          found = true;
          break;
        }
      }
      if (!found) {
        selectedCollectionLocal.isSelected = true;
        selectedCollectionLocal.isDraggable = false;

        tempSelectedCollections.push(selectedCollectionLocal);

        if (editSelectedSelection) {
          return tempSelectedCollections;
        } else {
          setSelectedCollections(tempSelectedCollections);
        }
      }
    }
  };

  const handleRemoveSelectedCollection = (index, collection) => {
    // Case where the collection clicked for deletion is the first in selectedCollections (index === 0) and whose schema does not equal "account" ("Accounts" collection).
    // The parameter 'index' is the index of the 'clicked' collection in selectedCollections.
    // This block of code resets selectedCollection, selectedCollections, groupedCollections, reportConcepts, collectionsObj, and reportObj back to their intitial states.
    if (
      selectedCollections.length &&
      selectedCollections[0].schema !== "account" &&
      index === 0
    ) {
      setSelectedCollection("");
      setSelectedCollections([]);
      setGroupedCollections([]);
      let tempReportConcepts = [...reportConcepts];
      tempReportConcepts.forEach((item) => {
        item.isDraggable = true;
        item.isSelected = false;
      });
      setReportConcepts(tempReportConcepts);
      let tempCollectionsObj = { ...collectionsObj };
      let tempReportObj = { ...reportObj };

      tempCollectionsObj.collections = [];
      tempCollectionsObj.fields = [];
      tempReportObj.collections = [];
      tempReportObj.fields = [];

      handleCollectionsObj(tempCollectionsObj);
      handleReportObj(tempReportObj);

      // Case where "Accounts" the first collection (schema is "account") in selectedCollections (index === 0) and the collection clicked for deletion is the second collection in selectedCollections (index === 1).
      // This block of code removes all collections except "Accounts" from selectedCollections and from and also updates reportConcepts so that all of the the deleted collections are reset to draggable and not selected.
    } else if (
      index === 1 &&
      selectedCollections.length &&
      selectedCollections[0].schema === "account"
    ) {
      let tempSelectedCollections = [...selectedCollections];
      let tempReportConcepts = deepCopy(reportConcepts);

      tempSelectedCollections = [selectedCollections[0]];

      let tempReportObj = deepCopy(reportObj);

      let collectionItemToDeleteIndex = -1;

      reportObj.collections.forEach((reportObjCollection, collectionIndex) => {
        if (reportObjCollection === collection.schema) {
          collectionItemToDeleteIndex = collectionIndex;
        }
      });

      let tempReportObjFields = [];
      reportObj.fields.forEach((field) => {
        if (field.collection === "account") {
          tempReportObjFields.push(field);
        }
      });

      // tempReportObj.collections.splice(collectionItemToDeleteIndex, 1);
      reportObj.collections.forEach((obj, index) => {
        if (index > 0) {
          tempReportObj.collections = ["account"];
          tempReportObj.fields = tempReportObjFields;
        }
      });

      handleReportObj(tempReportObj);
      setReportConcepts(tempReportConcepts);
      setSelectedCollectionsLocal([...tempSelectedCollections]);
      setSelectedCollections([...tempSelectedCollections]);

      // Case where "Accounts" is the first collection in selectedCollections (index === 0) and "Accounts" is clicked for deletion, or "Accounts" is not the first collection in selectedCollections and the index of the collection clicked for deletion is greater than 0.
      // The parameter 'index' is the index of the selected collection in selectedCollections.
      // This block of code removes the collection selected for deletion from selectedCollections and reportObj.collections.  It also updates reportConcepts so that the deleted collection is reset to draggable and not selected.
    } else if (index >= 0) {
      collection.isSelected = false;
      collection.isDraggable = true;
      let tempSelectedCollections = [...selectedCollections];
      let tempReportConcepts = deepCopy(reportConcepts);
      let removedCollection;

      reportConcepts.forEach((reportConcept, collectionIndex) => {
        if (reportConcept.schema === collection.schema) {
          tempReportConcepts[collectionIndex].isSelected = false;
          tempReportConcepts[collectionIndex].isDraggable = true;
        }
      });

      tempSelectedCollections.forEach((selectedCollection, index) => {
        if (selectedCollection?.schema === collection?.schema) {
          removedCollection = tempSelectedCollections.splice(index, 1);
        }
      });

      let tempReportObj = deepCopy(reportObj);

      let collectionItemToDeleteIndex = -1;

      reportObj.collections.forEach((reportObjCollection, collectionIndex) => {
        if (reportObjCollection === collection.schema) {
          collectionItemToDeleteIndex = collectionIndex;
        }
      });

      tempReportObj.collections.splice(collectionItemToDeleteIndex, 1);

      let filteredTempReportFields = tempReportObj.fields.filter((field) => {
        return field?.collection !== removedCollection[0].schema;
      });

      tempReportObj.fields = filteredTempReportFields;

      setReportConcepts(tempReportConcepts);
      handleReportObj(tempReportObj);
      setSelectedCollections(tempSelectedCollections);
    }
  };

  const handleActivateFieldsPage = () => {
    setActivateFieldsPage(true);
  };

  const handleDeactivateFieldsPage = () => {
    setActivateFieldsPage(false);
  };

  const functionReferenced = (collectionSchema) => {
    let functionRefFound = false;
    for (let i = 0; i < reportObj.functionFields.length; i++) {
      if (
        collectionSchema ===
          reportObj.functionFields[i].operands[0].collection ||
        collectionSchema === reportObj.functionFields[i].operands[1].collection
      ) {
        functionRefFound = true;
        break;
      }
    }
    return functionRefFound;
  };

  const handleSaveReport = () => {
    handleResetAppState();
    onSaveData();
    handleNotFromHeaderPage();
    onIsNotNew();
  };

  if (editMode && isPreview && !activateFieldsPage) {
    return ReportViewerForPreview;
  }

  if (!activateFieldsPage) {
    return (
      <div className={styles.Report2Collections__pageContainer}>
        <div className={styles.Report2Collections__collectionsContainer}>
          <div className={styles.Report2Collections__headerContainer}>
            <h1 className={styles.Report2Collections__header}>
              Select Collections
            </h1>
            {editMode && (
              <div
                className={styles.Report2Collections__previewButtonContainer}
              >
                {previewButton}
              </div>
            )}
          </div>
          <div className={styles.Report2Collections__reportCollectionsGroup}>
            <div className={styles.Report2Collections__dropBoxContainers}>
              <div className={styles.Report2Collections__dropBoxContainer}>
                <p className={styles.Report2Collections__selectStatement}>
                  {mdScreen
                    ? "Select collection(s)"
                    : "Select a collection or a group of collections"}
                </p>
                <div className={styles.Report2Collections__schemasListing}>
                  {reportConcepts.map((reportConcept, index) => {
                    return wrapReportConcept(reportConcept, index);
                  })}
                </div>
              </div>
              <div className={styles.Report2Collections__dropBoxContainer}>
                <p className={styles.Report2Collections__selectStatement}>
                  {mdScreen
                    ? "Drag and drop from the collections"
                    : "Drag and drop from the collections on the left"}
                </p>
                <div
                  className={styles.Report2Collections__schemasDropBox}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={() => handleOnDropSchema()}
                  onDragEnter={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDragStart={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {selectedCollections.map((collection, index) => {
                    return (
                      <div
                        key={index}
                        className={styles.Report2Collections__reportConcept}
                      >
                        {collection.title}
                        <div
                          onClick={() =>
                            handleRemoveSelectedCollection(index, collection)
                          }
                        >
                          {!functionReferenced(collection.schema) && (
                            <TrashIcon
                              fill={"#089BAB"}
                              style={{
                                width: "14px",
                                height: "14px",
                                cursor: "pointer",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Spacer space={40} unit={"px"} />
          <div className={styles.Report2Collections__buttonGroup}>
            <Button
              onClick={goBackHandler}
              labelName="Prev"
              isPrimary={false}
              isDisabled={false}
              minWidth={null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              onClick={handleActivateFieldsPage}
              labelName="Next"
              isPrimary={true}
              isDisabled={selectedCollections.length === 0 ? true : false}
              minWidth={mdScreen ? null : 213}
            />
            {EditSaveButtonGroup}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Report2Fields
        reportObj={reportObj}
        handleReportObj={handleReportObj}
        user={user}
        collectionsObj={collectionsObj}
        handleCollectionsObj={handleCollectionsObj}
        handleDeactivateFieldsPage={handleDeactivateFieldsPage}
        onSaveReport={handleSaveReport}
        editMode={editMode}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }
};

export default Report2Collections;
