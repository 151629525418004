import React, { useState, useEffect, useContext, Fragment } from "react";
import Button from "../../../components/Button";
import SearchBar from "../../../components/SearchBar";
import Spacer from "../../../components/Spacer";
import { ReactComponent as TrashIcon } from "../../../assets/trash-icon.svg";
import AddIcon from "../../../assets/add-icon-small.svg";
import RemoveIcon from "../../../assets/remove-icon-sm.svg";
import { ReactComponent as DragIcon } from "../../../assets/menu-icon.svg";
import Report2SelectGroupField from "..//Report2SelectGroupField/Report2SelectGroupField";
import { getPreferredFieldName } from "../lookups/getPreferredFieldName";
import { useMediaQuery } from "react-responsive";
import { ScreenContext } from "../../../contexts/ScreenContext";
import styles from "./Report2Fields.module.css";

const Report2Fields = ({
  reportObj,
  handleReportObj,
  user,
  collectionsObj,
  handleCollectionsObj,
  handleDeactivateFieldsPage,
  onSaveReport,
  editMode,
  previewButton,
  ReportViewerForPreview,
  isPreview,
  collectionFieldDictionary,
}) => {
  const [selectedField, setSelectedField] = useState("");
  const [selectedNode, setSelectedNode] = useState("");
  const [nodesObj, setNodesObj] = useState({
    nodes: [],
    nodeListIndexes: [],
  });
  const [activateSelectGroupPage, setActivateSelectGroupPage] = useState(false);
  const [fromIndexForReposition, setFromIndexForReposition] = useState(null);
  const [toIndexForReposition, setToIndexForReposition] = useState(null);
  const [preventDrop, setPreventDrop] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [booleanDateIntegerOrStringFound, setBooleanDateIntegerOrStringFound] =
    useState(false);
  const { mediumScreen } = useContext(ScreenContext);
  const mdScreen = useMediaQuery(mediumScreen);

  useEffect(() => {
    const searchArray = ["Number", "String", "Date", "Boolean"];
    let found = false;
    for (let i = 0; i < reportObj.fields.length; i++) {
      if (searchArray.includes(reportObj.fields[i].dataType)) {
        found = true;
      }
    }
    setBooleanDateIntegerOrStringFound(found);
  }, [reportObj]);

  useEffect(() => {
    if (reportObj.fields.length > 0) {
      let localNodes = [];
      let localNodeListIndexes = [];
      let collectionsObjIndex;

      reportObj.fields.forEach((field, fieldsIndex) => {
        collectionsObjIndex = collectionsObj.collections.indexOf(
          field.collection
        );

        if (collectionsObjIndex !== -1) {
          collectionsObj.fields[collectionsObjIndex].nodeList.forEach(
            (node, nodeIndex) => {
              if (node && node?.obj?.path === field?.name) {
                let tempCollectionsObj = { ...collectionsObj };

                if (
                  tempCollectionsObj?.fields[fieldsIndex]?.nodeList[
                    nodeIndex
                  ] &&
                  !node._isSelected
                ) {
                  node._isSelected = true;
                }
                setTimeout(() => {
                  // A small delay is necessary for the first time a field is selected or it will not be deleted from the collection list.
                  handleCollectionsObj(tempCollectionsObj);
                  localNodes.push(node);
                  localNodeListIndexes.push(fieldsIndex);
                }, "200");
              }
            }
          );
        }
      });
      setNodesObj({
        nodes: localNodes,
        nodeListIndexes: localNodeListIndexes,
      });
    }
  }, [reportObj]);

  useEffect(() => {
    let tempCollections = [...reportObj.collections];
    let tempReportObj = { ...reportObj };
    let updateReportObj = false;
    if (updateReportObj) {
      tempReportObj.collections = tempCollections;
      handleReportObj(tempReportObj);
    }
  }, [reportObj.fields]);

  const goBackHandler = () => {
    handleDeactivateFieldsPage();
  };

  const expandCollection = (index) => {
    let tempObj = { ...collectionsObj };

    tempObj.fields[index].expanded = true;
    handleCollectionsObj(tempObj);
  };

  const collapseCollection = (index) => {
    let tempObj = { ...collectionsObj };
    tempObj.fields[index].expanded = false;
    handleCollectionsObj(tempObj);
  };

  const wrapCollection = (collection, field, index) => {
    const collectionStyle = `${styles.Report2Fields__reportConcept}`;
    return (
      <div className={styles.Report2Fields__collectionContainer} key={index}>
        <div className={collectionStyle}>
          <div>{field.name}</div>
          <img
            className={styles.Report2Fields__image}
            src={field.expanded ? RemoveIcon : AddIcon}
            onClick={
              field.expanded
                ? () => {
                    collapseCollection(index);
                  }
                : () => {
                    expandCollection(index);
                  }
            }
          />
        </div>

        <div className={styles.Report2Fields__fields}>
          {field.expanded &&
            field?.nodeList &&
            field?.nodeList?.length &&
            field.nodeList.map((node, index) => {
              if (
                !node._isSelected &&
                node.obj.path !== "_id" &&
                node.obj.path !== "__v" &&
                !node.obj?.options?.ref &&
                (node.obj.instance === "Boolean" ||
                  node.obj.instance === "Date" ||
                  node.obj.instance === "Number" ||
                  node.obj.instance === "String" ||
                  (node.obj.instance === "Array" &&
                    (node.obj.path === "sites" ||
                      node.obj.path === "phoneNumbers" ||
                      node.obj.path === "roles" ||
                      node.obj.path === "barcodeList")))
              ) {
                const nodeListIndex = index;
                if (node.obj.path.toUpperCase().includes(filterText)) {
                  return (
                    <div
                      key={index}
                      className={styles.Report2Fields__fieldGroup}
                      draggable={true}
                      onDrag={(e) =>
                        handleOnDrag(nodeListIndex, collection, node)
                      }
                    >
                      <DragIcon />
                      <p className={styles.Report2Fields__field}>
                        {getPreferredFieldName(
                          field.collection,
                          node.obj.path,
                          collectionFieldDictionary,
                          false,
                          false
                        )}
                      </p>
                    </div>
                  );
                }
              }
            })}
        </div>
      </div>
    );
  };

  const handleOnDrag = (nodeListIndex, collection, node) => {
    const selectedField = {
      collection: collection,
      dataType: node.obj.instance,
      name: node.obj.path,
    };
    setSelectedField(selectedField);
    setSelectedNode(node);
  };

  const handleOnDropField = () => {
    if (!preventDrop) {
      // reportObj.collections.length > reportObj.collections.length ? collectionsObj.collections[reportObj.collections.length] : collection,

      let tempObj = { ...reportObj };

      let tempCollections = [...tempObj.collections];

      let tempFields = [...tempObj.fields];
      tempFields.push({
        collection: selectedField.collection,
        dataType: selectedField.dataType,
        name: selectedField.name,
        query: [],
        sum: false,
        average: false,
      });

      let tempReportObjCollections = [
        ...collectionsObj.collections.slice(
          0,
          reportObj.collections.length + 1
        ),
      ];

      let updatedReportObj = {
        ...reportObj,
        collections:
          collectionsObj.collections.length > reportObj.collections.length
            ? [...tempReportObjCollections]
            : tempCollections,
        fields: tempFields,
      };

      handleReportObj(updatedReportObj);

      selectedNode._isSelected = true;
    } else {
      setPreventDrop(false);
    }
  };

  const handleRemoveSelectedField = (reportObjField, reportObjFieldIndex) => {
    const collectionSchema = reportObjField.collection;

    let collectionsIndex = -1;

    collectionsObj.collections.forEach((objCollection, index) => {
      if (objCollection === collectionSchema) {
        collectionsIndex = index;
      }
    });

    collectionsObj.fields[collectionsIndex].nodeList.forEach(
      (collectionNode) => {
        if (collectionNode === nodesObj.nodes[reportObjFieldIndex]) {
          collectionNode._isSelected = false;
        }
      }
    );
    const tempObj = { ...collectionsObj };
    handleCollectionsObj(tempObj);

    let tempReportObj = { ...reportObj };

    let firstIndex = -1;
    let lastIndex = -1;
    tempReportObj.fields.forEach((field, index) => {
      if (field.collection === reportObjField.collection && firstIndex === -1) {
        firstIndex = index;
      } else if (field.collection === reportObjField.collection) {
        lastIndex = index;
      }
    });

    if (firstIndex !== -1 && lastIndex === -1) {
      let collectionItemToDeleteIndex = reportObj.collections.indexOf(
        tempReportObj.fields[reportObjFieldIndex].collection
      );
      tempReportObj.collections.splice(collectionItemToDeleteIndex, 1);
    }

    tempReportObj.fields.splice(reportObjFieldIndex, 1);

    let tempLocalReportObj = { ...nodesObj };
    tempLocalReportObj.nodes.splice(reportObjFieldIndex, 1);
    tempLocalReportObj.nodeListIndexes.splice(reportObjFieldIndex, 1);

    handleReportObj(tempReportObj);
    setSelectedField("");
    setSelectedNode("");
  };

  const handleActivateSelectGroupPage = () => {
    setActivateSelectGroupPage(true);
  };

  const handleDeactivateSelectGroupPage = () => {
    setActivateSelectGroupPage(false);
  };

  const handleRepositionedSelectedField = () => {
    const fromIndex = fromIndexForReposition;
    const toIndex = toIndexForReposition;

    let tempReportObj = { ...reportObj };
    let tempNodesObj = { ...nodesObj };
    let element;

    element = tempReportObj.fields.splice(fromIndex, 1)[0];
    tempReportObj.fields.splice(toIndex, 0, element);

    element = tempNodesObj.nodes.splice(fromIndex, 1)[0];
    tempNodesObj.nodes.splice(toIndex, 0, element);

    element = tempNodesObj.nodeListIndexes.splice(fromIndex, 1)[0];
    tempNodesObj.nodeListIndexes.splice(toIndex, 0, element);

    handleReportObj(tempReportObj);
    setNodesObj(tempNodesObj);
  };

  const handleSearch = (searchText) => {
    setFilterText(searchText.toUpperCase());
  };

  const functionReferenced = (reportObjFieldName) => {
    let functionRefFound = false;
    for (let i = 0; i < reportObj?.functionFields?.length; i++) {
      if (
        reportObjFieldName === reportObj.functionFields[i].operands[0].name ||
        reportObjFieldName === reportObj.functionFields[i].operands[1].name
      ) {
        functionRefFound = true;
        break;
      }
    }
    return functionRefFound;
  };

  if (editMode && isPreview && !activateSelectGroupPage) {
    return ReportViewerForPreview;
  }

  if (!activateSelectGroupPage) {
    return (
      <div className={styles.Report2Fields__pageContainer}>
        <div className={styles.Report2Fields__collectionsContainer}>
          <div className={styles.Report2Fields__headerContainer}>
            <h1 className={styles.Report2Fields__header}>Select Fields</h1>
            {editMode && (
              <div className={styles.Report2Fields__previewButtonContainer}>
                {previewButton}
              </div>
            )}
          </div>

          <div className={styles.Report2Fields__searchBarContainer}>
            <SearchBar
              placeholder="Search by field name"
              onSearch={handleSearch}
              focus={true}
            />
          </div>
          <div className={styles.Report2Fields__reportCollectionsGroup}>
            <div className={styles.Report2Fields__dropBoxContainers}>
              <div className={styles.Report2Fields__dropBoxContainer}>
                <p className={styles.Report2Fields__selectStatement}>
                  {mdScreen
                    ? "Expand a collection to see fields"
                    : "Expand a collection to see available fields"}
                </p>
                <div className={styles.Report2Fields__schemasListing}>
                  {collectionsObj.collections.map(
                    (collection, collectionIndex) => {
                      return wrapCollection(
                        collection,
                        collectionsObj.fields[collectionIndex],
                        collectionIndex
                      );
                    }
                  )}
                </div>
              </div>

              <div className={styles.Report2Fields__dropBoxContainer}>
                <p className={styles.Report2Fields__selectStatement}>
                  {mdScreen
                    ? " Drag and drop desired fields at left"
                    : " Drag and drop desired collection fields on the left"}
                </p>
                <div
                  className={styles.Report2Fields__schemasDropBox}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onDrop={(e) => handleOnDropField()}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                >
                  {reportObj.fields.map(
                    (reportObjField, reportObjFieldIndex) => {
                      if (
                        reportObj?.fields[reportObjFieldIndex]?.collection
                          ?.length > 0
                      ) {
                        return (
                          <div
                            key={reportObjFieldIndex}
                            draggable={"true"}
                            onDrag={(e) => {
                              setFromIndexForReposition(reportObjFieldIndex);
                              setPreventDrop(true);
                            }}
                            onDragEnter={(e) => {
                              setToIndexForReposition(reportObjFieldIndex);
                            }}
                            onDrop={() => handleRepositionedSelectedField()}
                            onDragLeave={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }}
                            className={styles.Report2Fields__droppedFieldGroup}
                          >
                            <div className={styles.Report2Fields__droppedField}>
                              {getPreferredFieldName(
                                reportObjField.collection,
                                reportObjField.name,
                                collectionFieldDictionary,
                                true,
                                false
                              )}
                              {
                                <div
                                  className={
                                    styles.Report2Fields__trashIconContainer
                                  }
                                  onClick={() =>
                                    handleRemoveSelectedField(
                                      reportObjField,
                                      reportObjFieldIndex
                                    )
                                  }
                                >
                                  {!functionReferenced(reportObjField.name) && (
                                    <TrashIcon
                                      fill={"#089BAB"}
                                      style={{
                                        width: "14px",
                                        height: "14px",
                                        cursor: "pointer",
                                      }}
                                    />
                                  )}
                                </div>
                              }
                            </div>
                          </div>
                        );
                      }
                    }
                  )}
                </div>
              </div>
            </div>
          </div>
          <Spacer space={40} unit={"px"} />
          <div className={styles.Report2Fields__buttonGroup}>
            <Button
              onClick={goBackHandler}
              labelName="Prev"
              isPrimary={false}
              isDisabled={false}
              minWidth={null}
            />
            <Spacer space={20} unit={"px"} />
            {booleanDateIntegerOrStringFound && (
              <Button
                onClick={handleActivateSelectGroupPage}
                labelName="Next"
                isPrimary={true}
                isDisabled={reportObj.collections.length === 0 ? true : false}
                minWidth={mdScreen ? null : 213}
              />
            )}

            {(editMode || !booleanDateIntegerOrStringFound) && (
              <Fragment>
                <Spacer space={20} unit={"px"} />
                <Button
                  onClick={onSaveReport}
                  labelName="Save Report & Close"
                  isPrimary={true}
                  isDisabled={
                    reportObj.title &&
                    reportObj.collections.length > 0 &&
                    reportObj.fields.length > 0
                      ? false
                      : true
                  }
                  minWidth={mdScreen ? null : 213}
                />
              </Fragment>
            )}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <Report2SelectGroupField
        reportObj={reportObj}
        handleReportObj={handleReportObj}
        user={user}
        handleCollectionsObj={handleCollectionsObj}
        handleDeactivateSelectGroupPage={handleDeactivateSelectGroupPage}
        booleanDateIntegerOrStringFound={booleanDateIntegerOrStringFound}
        onSaveReport={onSaveReport}
        editMode={editMode}
        previewButton={previewButton}
        ReportViewerForPreview={ReportViewerForPreview}
        isPreview={isPreview}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  }
};

export default Report2Fields;
