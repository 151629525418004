// for SyncFusion dropdown
const getCanisterDataMappingDropdownList = () => {
  return [
    { Item: "Default Value", Value: "" },
    { Item: "Barcode", Value: "stockLocation.barcode" },
    {
      Item: "Control Level",
      Value: "productAssociations[0].product.controlLevel.type",
    },
    {
      Item: "Description",
      Value: "productAssociations[0].product.comments",
    },
    {
      Item: "Dosage Form",
      Value: "productAssociations[0].product.dosageForm.type",
    },

    { Item: "Legend", Value: "productAssociations[0].product.legend" },
    {
      Item: "Manufacturer Abbreviation",
      Value: "productAssociations[0].product.abbrName",
    },
    {
      Item: "Manufacturer Name",
      Value: "productAssociations[0].product.manufacturer.fullName",
    },
    { Item: "Motor Base", Value: "stockLocation.devicePosition" },
    { Item: "Name", Value: "stockLocation.name" },
    {
      Item: "Package Id",
      Value: "productAssociations[0].product.packageId",
    },
    {
      Item: "Primary Name",
      Value: "productAssociations[0].product.primaryName",
    },
    {
      Item: "Product Id",
      Value: "productAssociations[0].product.productId",
    },
    {
      Item: "Secondary Name",
      Value: "productAssociations[0].product.secondaryName",
    },
    {
      Item: "Strength",
      Value: "productAssociations[0].product.strength",
    },
    {
      Item: "TallMan Name",
      Value: "productAssociations[0].product.tallManName",
    },
    {
      Item: "Tertiary Name",
      Value: "productAssociations[0].product.tertiaryName",
    },
  ];
};

export default getCanisterDataMappingDropdownList;
