import React, { useContext } from "react";

import styles from "./MenuItem.module.css";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { Popconfirm } from "antd";

const MenuItem = ({
  icon,
  menuName,
  onMenuSelect,
  isActive,
  forSmallScreen = false,
  padLeft,
  lastChild,
  needsConfirmation = false,
  confirmationText = "",
}) => {
  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: mdScreen ? "50px" : "89px",
    height: mdScreen ? "45px" : "65px",
    minHeight: mdScreen ? "45px" : "65px",
    overflow: "none",
    borderRadius: "8px",
    cursor: "pointer",
    backgroundColor: isActive ? "rgba(255, 255, 255, 0.1)" : "transparent",
    outline: "none",
  };

  const containerSmallScreenStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "60px",
    width: "160px",
    padding: "0 10px 0 0",
    paddingLeft: padLeft ? padLeft : "0",
    overflow: "none",
    borderBottom: "1px solid",
    borderColor: "rgba(236, 239, 248, 0.3)",
    cursor: "pointer",
    backgroundColor: isActive ? "rgba(255, 255, 255, 0.1)" : "transparent",
    outline: "none",
  };

  const menuItemTextStyle = {
    margin: 0,
    color: "#ffffff",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    textAlign: "center",
    letterSpacing: "0.3px",
    zIndex: 2,
    opacity: 1.0,
  };

  const menuItemTextSmallScreenStyle = {
    margin: 0,
    paddingLeft: "10px",
    color: "#ffffff",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letter: ".3px",
    textAlign: "center",
    letterSpacing: "0.3px",
    zIndex: 2,
    opacity: 1.0,
  };

  const iconSmallScreenStyle = {
    width: "20px",
    // height: "inherit",
    paddingTop: "5px",
  };

  const handleMenuSelect = () => {
    document.activeElement.blur();
    onMenuSelect();
  };

  const handleKeyUp = (event, container) => {
    // If a menu is focused, open it if the Enter key is pressed
    const code = event.keyCode || event.which;
    if (code === 13) {
      handleMenuSelect();
    }
  };

  return needsConfirmation && confirmationText ? (
    <Popconfirm
      placement="right"
      title={confirmationText}
      onConfirm={handleMenuSelect}
    >
      <div
        tabIndex="0"
        style={forSmallScreen ? containerSmallScreenStyle : containerStyle}
        className={styles.MenuItem__container}
        onKeyUp={handleKeyUp}
      >
        <div style={smallScreen ? iconSmallScreenStyle : null}>{icon}</div>
        {smScreen && <p style={menuItemTextSmallScreenStyle}>{menuName}</p>}
        {lgScreen && <p style={menuItemTextStyle}>{menuName}</p>}
      </div>
    </Popconfirm>
  ) : (
    <div
      tabIndex="0"
      style={forSmallScreen ? containerSmallScreenStyle : containerStyle}
      className={styles.MenuItem__container}
      onClick={handleMenuSelect}
      onKeyUp={handleKeyUp}
    >
      <div style={smallScreen ? iconSmallScreenStyle : null}>{icon}</div>
      {smScreen && <p style={menuItemTextSmallScreenStyle}>{menuName}</p>}
      {lgScreen && <p style={menuItemTextStyle}>{menuName}</p>}
    </div>
  );
};

export default MenuItem;
