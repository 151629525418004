import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import style from "./DatePickerComponent.module.css";

const DatePickerComponent = ({
  startDate,
  onChange,
  label,
  alternateFormat = false,
    showMonthYearPicker = false
}) => {
  // console.log(date)
  // const [startDate, setStartDate] = useState(new Date());

  return (
    <div
      className={
        !alternateFormat
          ? style.DatePickerComponent__wrapper
          : `${style.DatePickerComponent__wrapper} ${style["DatePickerComponent__wrapper--alternateFormat"]}`
      }
    >
      {!alternateFormat && <label>{label}</label>}
      <DatePicker preventOpenOnFocus={true} selected={startDate} onChange={(date) => onChange(date)} showMonthYearPicker={showMonthYearPicker}/>
    </div>
  );
};

export default DatePickerComponent;
