import { io } from 'socket.io-client';
import StockLocationService from "../services/StockLocationService";
import DashboardService from "../services/DashboardService";

// This is a singleton class.  Every logged in user will have one websocket connection
// used for receiving server-generated alerts
class DashboardController {

    constructor(){
        this._alarms = [];
        // callbacks
        this.inventoryAlertCallback = null;
        this.newAlarmCallback = null;
    }

    initializeSocket(user){
        console.log('CONSTRUCTING DASH CONTROLLER')
        this.user = user;
        this.setupAlarmSocket();
        this.setupInventorySocket();
    }

    setupAlarmSocket(){
        console.log('CREATING ALARM DASH SOCKETS')
        // setting up alarm socket
        this.alarmSocket = io('/alarms');
        if(this.alarmSocket){
            this.alarmSocket.on("connect", () => {
                console.log(`Dashboard socket ${this.alarmSocket.id} is connected`);
            })
            this.alarmSocket.on("disconnect", () => {
                console.log(`Alarm socket has disconnected`);
            })
            this.alarmSocket.on(`${this.user.account._id}-alarms`, (data)=>this.handleAlarms(data));
        }

    }

    setupInventorySocket(){
        console.log('CREATING INV DASH SOCKETS')
        this.invSocket = io('/inventory');
        if(this.invSocket){
            this.invSocket.on("connect", () => {
                console.log(`inventory socket ${this.invSocket.id} is connected`);
            })
            this.invSocket.on("disconnect", () => {
                console.log(`inventory socket has disconnected`);
            })
            this.invSocket.on(`${this.user.account._id}-inventory-alerts`, (data)=>this.handleInventoryAlerts(this, data));

        }
    }

    releaseSocket() {
        console.log('RELEASING INV and ALARM DASH SOCKETS')
        if(this.invSocket){
            this.invSocket.disconnect();
            this.invSocket = null;
        }
        if(this.alarmSocket){
            this.alarmSocket.disconnect();
            this.alarmSocket = null;
        }
    };

    handleInventoryAlerts(sender, data){
        console.log('received inventory alert', data);
        if(sender.inventoryAlertCallback){
            sender.inventoryAlertCallback(data);
        }
    }

    registerInventoryAlertCallback(callback){
        this.inventoryAlertCallback = callback;
    }

    registerNewAlarmCallback(callback){
        this.newAlarmCallback = callback;
        this.newAlarmCallback(this._alarms.length, this._alarms);
    }

    handleAlarms = (alarmArray) => {
        this.removeAlarms(alarmArray);
        this.addAlarms(alarmArray);

        if(this.newAlarmCallback){
            this.newAlarmCallback(this._alarms.length, this._alarms);
        }
    }

    removeAlarms = (alarmArray) => {
        this._alarms = this._alarms.filter(o=>!alarmArray.some(s=>s._id === o._id));
    }

    addAlarms = (alarmArray) => {
        let newAlarms = alarmArray.filter(o=>!this._alarms.some(s=>s._id === o._id));
        this._alarms = this._alarms.concat(newAlarms);
    }

    getAlarms = () => {
        let data = {
            accountId: this.user.account._id
        }
        this.alarmSocket.emit("get-alarms", data);
    }

    acknowledgeAlarm = (alarm) => {
        let data = {
            userId: this.user._id,
            acknowledgedAt: new Date(),
            alarmId: alarm._id
        }
        this.alarmSocket.emit("acknowledge-alarm", data);
        this._alarms.splice(this._alarms.findIndex(a=>a._id === alarm._id), 1);
        if(this.newAlarmCallback){
            this.newAlarmCallback(this._alarms.length, this._alarms);
        }
    }

    registerCallToAction = (callback) => {
        this._callToActionCallback = callback;
    }

    callToAction = (alarm) => {
        if(this._callToActionCallback){
            this._callToActionCallback(alarm);
            //this.acknowledgeAlarm(alarm);
        }
    }

    getPillSortData = async (user, startDate, stopDate) => {
        return await DashboardService.getPillSortData(user, startDate, stopDate);
    }
}

const instance = new DashboardController();
export default instance;