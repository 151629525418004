class ProductFieldController {
  /*
    This method can be used to get a quick name irrelavent of pharmacy setting or product type
     */
  getDefaultName = (product) => {
    if (product) {
      // if(product.defaultName){
      //     return product.defaultName
      // } else
      // if(this._isDrug(product)){
      //     return this._getDrugName(product);
      // } else {
      //     return this._getName(product);
      // }

      return this._getDrugName(product);
    }
    return "";
  };

  getPackageId = (product) => {
    return product.packageId;
  };

  getPrimaryName = (name) => {
    return name.toUpperCase().substring(0, 50);
  };

  getSecondaryName = (name) => {
    return this._getProperCase(name).substring(0, 50);
  };

  getTertiaryName = (name) => {
    return this._getProperCase(name).substring(0, 50);
  };

  getManufacturerAbbreviation = (fullName) => {
    if (fullName) {
      return fullName.split(" ")[0];
    }
    return "";
  };

  getStrength = (val) => {
    return val.replaceAll(" ", "").toLowerCase();
  };

  _getProperCase = (str) => {
    return str.replace(/\b\w+/g, function (s) {
      return s.charAt(0).toUpperCase() + s.substr(1).toLowerCase();
    });
  };

  convertTo11DigitNDC = (tenDigitNDC) => {
    let parts = tenDigitNDC.split("-");

    if (parts.length < 3) {
      return "";
    }

    if (parts[0].length === 4) {
      return "0" + parts[0] + parts[1] + parts[2];
    } else if (parts[0].length === 5 && parts[1].length === 3) {
      return parts[0] + "0" + parts[1] + parts[2];
    } else {
      return parts[0] + parts[1] + "0" + parts[2];
    }
  };

  convertTo9DigitNDC = (elevenDigitId) => {
    return elevenDigitId.substring(0, 9);
  };

  getProductId = (product) => {
    return product.productId;
  };

  _isDrug = (product) => {
    return product.productCategory?.name?.toUpperCase() === "DRUG";
  };

  _getDrugName = (product) => {
    let name = product.primaryName || "";
    if (product.strength) {
      name = `${name} ${product.strength || ""}`;
    }
    if (product.dosageForm?.type) {
      name = `${name} ${product.dosageForm.type}`;
    } else if (product.dosageForm?.description) {
      name = `${name} ${product.dosageForm.description}`;
    }
    return name;
  };

  _getName = (product) => {
    return product.primaryName || "";
  };

  getEV54DrugId = (p) => {
    if (p) {
      return p.ev54DrugId || p.productId || "";
    }
    return "";
  };

  getEV54DrugName = (p) => {
    if (p) {
      return p.ev54DrugName || this.getDefaultName(p);
    }
    return "";
  };

  getEV54DrugIdError = (p) => {
    if (p && p.ev54DrugId && p.ev54DrugId.length !== 9) {
      return "EV-54 Id must be 9-characters long";
    } else {
      return "";
    }
  };

  getEV54DrugNameError = (p) => {
    if (p && p.ev54DrugName && p.ev54DrugName.length > 36) {
      return "EV-54 drug name must be no more than 36-characters long";
    } else {
      return "";
    }
  };

  barcodeExists = (p, bc) => {
    return (
      bc === p.packageId || bc === p.productId || p.barcodeList.includes(bc)
    );
  };
}

export default ProductFieldController;
