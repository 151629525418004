import ApiService from "./ApiService";
import axios from 'axios';

class AccountService extends ApiService {
    static create = async (account) => {
        try{
            let response = await axios.post(`${ApiService.BASE_URL}/accounts`, account);
            return response.data; // account document
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static exists = async (companyName) => {
        try{
            let response = await axios.get(`${ApiService.BASE_URL}/accounts/exists?companyName=${encodeURIComponent(companyName)}`);
            return response.data.exists;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static accountList = async (user) => {
        try{
            let response = await axios.get(`${ApiService.BASE_URL}/accounts`);
            return response.data.accounts;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default AccountService;
