const replenishment_reportObj = {
  title: "",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: false,
  collections: [],
  fields: [],
  functionFields: [],
  functionLookups: {},
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "",
};

export default replenishment_reportObj;
