import React, { Fragment } from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  Font,
} from "@react-pdf/renderer";
import backIcon from "../../../../assets/back_icon.svg";
import styled from "styled-components";

// Register fonts
Font.register({
  family: "Poppins",
  fonts: [
    {
      src: "/fonts/Poppins-Regular.ttf",
      fontWeight: "normal",
    },
    {
      src: "/fonts/Poppins-Medium.ttf",
      fontWeight: "medium",
    },
    {
      src: "/fonts/Poppins-Light.ttf",
      fontWeight: "300",
    },
  ],
});

//region CSS
const MyView = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
  width: 100%;
  height: 100%;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const GeneratePDF = ({
  selectedViewData,
  onShowPDFViewer,
  onSelectedBatchIndex,
  selectionRange,
  Barcodes,
  mainHeadings,
  subHeadings,
  reportTitle,
  symbology,
}) => {
  const getDateString = (month, day, fullYear) => {
    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${month}/${day}/${fullYear}`;
  };

  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    const fullYearStart = selectionRange.startDate.getFullYear().toString();
    const monthStart = (selectionRange.startDate.getMonth() + 1).toString();
    const dayStart = selectionRange.startDate.getDate().toString();

    const fullYearEnd = selectionRange.endDate.getFullYear().toString();
    const monthEnd = (selectionRange.endDate.getMonth() + 1).toString();
    const dayEnd = selectionRange.endDate.getDate().toString();

    startDate = getDateString(monthStart, dayStart, fullYearStart);
    endDate = getDateString(monthEnd, dayEnd, fullYearEnd);
  }

  let names = [];
  let nameChangeIndexes = [];
  let Pages = [];

  let MainHeadings = [];
  const mainHeadingWidths = ["auto", "260px", "140px", "240px", "160px"];

  const subHeadingWidths = [
    "260px",
    "100px",
    "116px",
    "90px",
    "180px",
    "80px",
    "104px",
  ];

  const dataWidths = [
    "260px",
    "100px",
    "116px",
    "90px",
    "180px",
    "80px",
    "104px",
    "500px", // product.comments
  ];

  let SubHeadings = subHeadings.map((subHeading, index) => {
    return (
      <Text
        key={subHeading}
        fill="black"
        style={{
          display: "flex",
          alignItems: "center",
          width: subHeadingWidths[index],
          fontSize: "10px",
          fontFamily: "Poppins",
          fontWeight: "medium",
          lineHeight: "1",
          color: "#121A2D",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {subHeading}
      </Text>
    );
  });

  const MainHeadingText = (heading, index, row) => {
    if (index === 0) {
      return null;
    }

    return (
      <Text
        key={heading}
        fill="black"
        style={{
          width: mainHeadingWidths[index],
          fontSize: "10px",
          fontFamily: "Poppins",
          fontWeight: "normal",
          lineHeight: "1.6",
          color: "#121A2D",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        <Text style={{ fontWeight: "medium" }}>{`${heading}: `}</Text>
        {`${row[index - 1]}`}
      </Text>
    );
  };

  const MainHeadingBatchAndDate = (heading, index, row) => {
    if (index !== 0) {
      return null;
    }

    return (
      <View
        key={index}
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          marginBottom: "10px",
        }}
      >
        <Text
          key={heading}
          fill="black"
          style={{
            width: mainHeadingWidths[index],
            fontSize: "10px",
            fontFamily: "Poppins",
            fontWeight: "normal",
            lineHeight: "1.6",
            color: "#121A2D",
            margin: 0,
            marginRight: index === 0 ? "16px" : 0,
            padding: 0,
            boxSizing: "border-box",
          }}
        >
          <Text style={{ fontWeight: "medium" }}>{`${heading}: `}</Text>
          {selectedViewData.batch}
        </Text>
        <Text
          key={"dateRange"}
          fill="black"
          style={{
            width: mainHeadingWidths[index],
            fontSize: "10px",
            fontFamily: "Poppins",
            fontWeight: "normal",
            lineHeight: "1.6",
            color: "#121A2D",
            margin: 0,
            padding: 0,
            marginLeft: "10px",
            boxSizing: "border-box",
          }}
        >
          <Text style={{ fontWeight: "medium" }}>Packaged on:</Text>
          {/* {` ${startDate} - ${endDate}`} */}
          {` ${selectedViewData.packagedOnFormatted}`}
        </Text>
      </View>
    );
  };

  let DataRows = [];

  const TableDataText = (columnDatum, columnIndex, rowIndex) => {
    return (
      <Text
        key={`${columnIndex.toString()}-${rowIndex.toString}`}
        fill="black"
        style={{
          width: dataWidths[columnIndex],
          fontSize: "10px",
          fontFamily: "Poppins",
          fontWeight: "normal",
          lineHeight: "1.6",
          color: "#121A2D",
          margin: 0,
          padding: 0,
          boxSizing: "border-box",
          textAlign: columnIndex === 5 || columnIndex === 6 ? "right" : "left",
          paddingRight: columnIndex === 5 || columnIndex === 6 ? "22px" : 0,
        }}
      >
        {columnDatum}
      </Text>
    );
  };

  const SubTableDataText = (columnDatum) => {
    // used for products.comments displayed underneath main row
    return (
      <Text
        fill="black"
        style={{
          fontSize: "9px",
          fontFamily: "Poppins",
          fontWeight: "normal",
          lineHeight: "1.6",
          color: "#121A2D",
          margin: "-15px 0 0 10px",
          padding: 0,
          boxSizing: "border-box",
        }}
      >
        {columnDatum}
      </Text>
    );
  };

  selectedViewData.data.forEach((row, rowIndex) => {
    DataRows.push([
      TableDataText(`${row[4]}, ${row[5]}`, 0, rowIndex),
      TableDataText(row[6], 1, rowIndex),
      TableDataText(row[7], 2, rowIndex),
      Barcodes[rowIndex],
      TableDataText(row[8], 4, rowIndex),
      TableDataText(row[11], 5, rowIndex),
      TableDataText("_____", 6, rowIndex),
    ]);

    if (!names.includes(row[0])) {
      MainHeadings.push(
        <View
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginBottom: "10px",
          }}
        >
          {mainHeadings.map((heading, mainHeadingIndex) => {
            return MainHeadingBatchAndDate(heading, mainHeadingIndex, row);
          })}
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginBottom: "10px",
            }}
          >
            {mainHeadings.map((heading, mainHeadingIndex) => {
              return MainHeadingText(heading, mainHeadingIndex, row);
            })}
          </View>
        </View>
      );

      names.push(row[0]);
      nameChangeIndexes.push(rowIndex);
    }
  });
  let count = -1;
  names.forEach((name, nameIndex) => {
    Pages.push(
      <Page
        key={name}
        size="LETTER"
        orientation="landscape"
        style={{
          boxSizing: "border-box",
          padding: "20px",
        }}
      >
        {MainHeadings[(name, nameIndex)]}
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            paddingTop: "6px",
            paddingBottom: "8px",
            borderTop: "1px solid #089bab",
            borderBottom: "1px solid #089bab",
          }}
        >
          {SubHeadings}
        </View>
        {DataRows.slice(
          nameChangeIndexes[nameIndex],
          nameChangeIndexes[nameIndex + 1]
        ).map((row, rowIndex) => {
          count++;
          return (
            <Fragment key={rowIndex}>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  backgroundColor: rowIndex % 2 ? "#EBEDF8" : "#ffffff",
                  overflow: "hidden",
                  height: symbology === "Code39" ? "54px" : "40px",
                }}
              >
                {row}
              </View>
              <View>{SubTableDataText(selectedViewData.data[count][9])}</View>
            </Fragment>
          );
        })}
        <Text
          style={{
            margin: 0,
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 10,
            color: "#121A2D",
            position: "absolute",
            bottom: 10,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    );
  });

  const handleBackButton = () => {
    onShowPDFViewer(false);
    // onResetSortedReportData();
    // onResetViewData();
    onSelectedBatchIndex(-1);
    // onResetSelectionDateRange();
  };

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={handleBackButton} />
          <ReportTitle>PDF Viewer</ReportTitle>
        </TitleGroup>
      </Header>
      <PDFViewer style={{ height: "100%" }}>
        <Document title={reportTitle} style={{ height: "100%" }}>
          {Pages}
        </Document>
      </PDFViewer>
    </MyView>
  );
};

export default GeneratePDF;
