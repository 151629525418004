import React from 'react';

const Spacer = ({space, unit}) => {

    return (
        <section style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minWidth: space + unit,
            maxWidth: space+ unit,
            minHeight: space+ unit,
            maxHeight: space+ unit
        }}/>
    )
}

export default Spacer;
