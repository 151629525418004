import React, { useEffect, useRef, useContext, useCallback } from "react";
import styles from "./Popup.module.css";
import { useKeyPress } from "../InputHooks";
import { ScrollContext } from "../../contexts/ScrollContext";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

const Popup = ({
  menuItems,
  onAction,
  onClose,
  rightJustify,
  onCloseDropDownMenu = null,
}) => {
  const ref = useRef(null);

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const { xScroll, yScroll } = useContext(ScrollContext);

  if (useKeyPress("Escape")) {
    onClose();
  }

  const handleBlur = useCallback((e) => {
    // Closes the pop-up menu whenever none of its child elements have focus
    const currentTarget = e.currentTarget;

    // Give browser time to focus the next element
    requestAnimationFrame(() => {
      // Check if the new focused element is a child of the original container
      if (!currentTarget.contains(document.activeElement)) {
        onClose();
      }
    });
  }, []);

  const handleMouseUp = (e) => {
    e.preventDefault();
    if (ref.current) {
      const element = ref.current;
      const rect = element.getBoundingClientRect();

      if (
        e.pageX - xScroll < rect.left ||
        e.pageX - xScroll > rect.left + rect.width ||
        e.pageY - yScroll < rect.top ||
        e.pageY - yScroll > rect.top + rect.height
      ) {
        onClose();
      }
      if (onCloseDropDownMenu) {
        onCloseDropDownMenu();
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mouseup", handleMouseUp);
    };
  });

  return (
    <div
      ref={ref}
      className={styles.Popup__container}
      onBlur={handleBlur}
      style={rightJustify ? { right: smScreen ? "10px" : 0 } : { left: 0 }}
    >
      {menuItems.map((menuItem, idx) => {
        return (
          <button
            key={idx}
            className={styles.Popup__MenuItem}
            onClick={(e) => {
              e.preventDefault();
              onAction(menuItem);
            }}
          >
            <p>{menuItem.description}</p>
          </button>
        );
      })}
    </div>
  );
};

export default Popup;
