import React from "react";
import { View } from "@react-pdf/renderer";

const RepeaterComponent = ({
  keyValue,
  node,
  children,
  ghost,
  ghostIndex,
  arrayOfRowsForPrint,
}) => {
  const MAX_NUMBER_OF_ROWS_HANDLED = 20;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;

  const width = widthmm.toString() + "mm";
  const height = heightmm.toString() + "mm";

  let marginTop = 0;
  let marginLeft = 0;

  const rowGapmm = Number(node.gapmm);

  if (ghost && node.orientation === "Vertical") {
    marginTop = marginTop =
      ((ghostIndex + 1) * (Number(heightmm) + rowGapmm)).toString() + "mm";
  } else if (ghost && node.orientation === "Horizontal") {
    marginTop = "1px";
    marginLeft = ((ghostIndex + 1) * (Number(widthmm) + 3)).toString() + "mm";
  }

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

  if (Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) >
    Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) >
    Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  let show = true;

  // execute only if label is of the repeater type
  if (arrayOfRowsForPrint && arrayOfRowsForPrint.length) {
    if (node.nodeId === 1 && keyValue === 1 && arrayOfRowsForPrint[0] !== 1) {
      show = false;
    }

    for (let i = 0; i < MAX_NUMBER_OF_ROWS_HANDLED; i++) {
      if (node.nodeId === 1 && keyValue === "ghost-" + i.toString()) {
        if (!arrayOfRowsForPrint.includes(i + 2)) {
          show = false;
          break;
        }
      }
    }
  }

  const styleRepeater = {
    position: "absolute",
    cursor: "pointer",
    left: x,
    top: y,
    display: show ? "flex" : "none",
    flexDirection: "column",
    justifyContent: "center",
    width: width,
    height: height,
    borderRadius: "10px",
    marginTop: marginTop,
    marginLeft: marginLeft,
  };

  return <View style={styleRepeater}>{children}</View>;
};

export default RepeaterComponent;
