import React, { Fragment } from "react";
import styles from "./ToggleSwitch.module.css";

const ToggleSwitch = ({
  onChangeHandler,
  id = "toggle",
  checkState = false,
}) => {
  return (
    <Fragment>
      <div className={styles.toggleWrapper}>
        <input
          onChange={onChangeHandler}
          type="checkbox"
          checked={checkState}
          name={id}
          className={styles.mobileToggle}
          id={id}
        ></input>
        <label htmlFor={id}></label>
      </div>
    </Fragment>
  );
};

export default ToggleSwitch;
