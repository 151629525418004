import ApiService from "../../../services/ApiService";
import axios from "axios";

class FillListService extends ApiService {
  static getCanisterReplenishmentData = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };

      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/reports/fillList`, rc
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  };
}

export default FillListService;
