import React, { Fragment, useState, useEffect } from "react";

const Pagination = ({
  pageValues,
  onPageClick,
  maxPagesToDisplay,
  paginationStyle,
  activePaginationStyle,
  prevNextStyle,
  prevNextDisabledStyle,
}) => {
  const numberOfPages = pageValues[pageValues.length - 1];

  const [startPage, setStartPage] = useState(0);
  const [endPage, setEndPage] = useState(0);
  const [activePageValue, setActivePageValue] = useState(pageValues[0]);

  let activeStyle = undefined;
  let disabledPrevNextStyle = undefined;

  if (paginationStyle && activePaginationStyle) {
    activeStyle = { ...paginationStyle, ...activePaginationStyle };
  }

  if (prevNextStyle && prevNextDisabledStyle) {
    disabledPrevNextStyle = { ...prevNextStyle, ...prevNextDisabledStyle };
  }

  useEffect(() => {
    let tempEndPage = startPage + maxPagesToDisplay - 1;
    if (tempEndPage <= numberOfPages) {
      setEndPage(tempEndPage);
    } else {
      setEndPage(pageValues.length - 1);
    }
  }, [startPage, maxPagesToDisplay]);

  const handlePrevious = () => {
    if (startPage + 1 - maxPagesToDisplay >= 0) {
      onPageClick(pageValues[startPage - maxPagesToDisplay]);
      setStartPage((prevState) => prevState - maxPagesToDisplay);
      setActivePageValue(pageValues[startPage - maxPagesToDisplay]);
    }
  };

  const handleNext = () => {
    if (pageValues.length - 1 > endPage) {
      setStartPage(endPage + 1);
      onPageClick(pageValues[endPage + 1]);
      setActivePageValue(pageValues[endPage + 1]);
    }
  };

  const handleClick = (pageValue) => {
    onPageClick(pageValue);
    setActivePageValue(pageValue);
  };

  return (
    <ul>
      <li
        key="previous"
        onClick={handlePrevious}
        style={
          startPage + 1 >= maxPagesToDisplay
            ? prevNextStyle
            : disabledPrevNextStyle
        }
      >
        {"<<"}
      </li>
      {pageValues.slice(startPage, endPage + 1).map((pageValue) => {
        return (
          <li
            key={pageValue}
            style={
              activePageValue === pageValue ? activeStyle : paginationStyle
            }
            onClick={() => handleClick(pageValue)}
          >
            {pageValue}
          </li>
        );
      })}
      <li
        key="next"
        onClick={handleNext}
        style={
          pageValues.length - 1 > endPage
            ? prevNextStyle
            : disabledPrevNextStyle
        }
      >
        {">>"}
      </li>
    </ul>
  );
};

export default Pagination;
