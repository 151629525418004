import React, { useContext } from "react";
import { NavbarContext } from "../../../../contexts/NavbarContext";
import Dropzone from "react-dropzone";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import Button from "../../../../components/Button";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import styles from "./ImageProperties.module.css";

const ImageProperties = ({
  activeNode,
  nodes,
  onNodes,
  onShowDeleteNodeWarning,
  onInputFocused,
  onLabelModificationInProgress,
}) => {
  const { handleLabelModificationInProgressViaContext } =
    useContext(NavbarContext);

  const handleDataUpdate = (tempNodes) => {
    onNodes([...tempNodes]);
    onLabelModificationInProgress(true);
    handleLabelModificationInProgressViaContext(true);
  };

  const handleXPosition = (xPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].xPosmm = xPosition;
    handleDataUpdate(tempNodes);
  };

  const handleYPosition = (yPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].yPosmm = yPosition;
    handleDataUpdate(tempNodes);
  };

  const handleWidth = (width) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].width = width;
    handleDataUpdate(tempNodes);
  };

  const handleHeight = (height) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].height = height;
    handleDataUpdate(tempNodes);
  };

  const onDrop = async (files) => {
    let file = files[0];
    const reader = new FileReader();
    let tempNodes = [...nodes];
    reader.onloadend = (e) => {
      // asynchronous
      let stringArray = reader.result.split("base64,");
      tempNodes[activeNode.nodeId].base64EncodedString = stringArray[1];
      handleDataUpdate(tempNodes);
    };
    reader.readAsDataURL(file);
    tempNodes[activeNode.nodeId].mimeType = file.type;
  };

  const handleRemoveNode = () => {
    onShowDeleteNodeWarning(true);
  };

  const handleMouseEnter = () => {
    onInputFocused(true);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div className={styles.ImageProperties__container}>
        <div
          className={styles.ImageProperties__trashIconContainer}
          onClick={handleRemoveNode}
        >
          <TrashIcon />
        </div>
        <div className={styles.ImageProperties__positionContainer}>
          <p>
            Position <span>(mm)</span>
          </p>

          <div className={styles.ImageProperties__boxesContainer}>
            <div className={styles.ImageProperties__xBoxContainer}>
              <div
                className={styles.ImageProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.xPosmm}
                  onHandleUserInput={handleXPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.xPosmm,
                  }}
                />
              </div>
              <p>x</p>
            </div>
            <div className={styles.ImageProperties__yBoxContainer}>
              <div
                className={styles.ImageProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.yPosmm}
                  onHandleUserInput={handleYPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.yPosmm,
                  }}
                />
              </div>
              <p>y</p>
            </div>
          </div>
        </div>
        <div className={styles.ImageProperties__sizeContainer}>
          <p>
            Size <span>(px)</span>
          </p>
          <div className={styles.ImageProperties__boxesContainer}>
            <div className={styles.ImageProperties__widthBoxContainer}>
              <div
                className={styles.ImageProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.width}
                  onHandleUserInput={handleWidth}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.width,
                  }}
                />
              </div>
              <p>width</p>
            </div>
            <div className={styles.ImageProperties__heightBoxContainer}>
              <div
                className={styles.ImageProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.height}
                  onHandleUserInput={handleHeight}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.height,
                  }}
                />
              </div>
              <p>height</p>
            </div>
          </div>
        </div>

        <div className={styles.editProfile__profilePicButton}>
          <Dropzone
            accept={"image/jpeg, image/png, image/svg+xml"}
            onDrop={(acceptedFiles) => onDrop(acceptedFiles)}
          >
            {({ getRootProps, getInputProps }) => (
              <div style={{ marginTop: "20px" }} {...getRootProps()}>
                <input {...getInputProps()} />
                <Button
                  labelName="Change Image"
                  isPrimary={true}
                  isInverse={true}
                  minWidth={null}
                  width="100%"
                />
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    </div>
  );
};

export default ImageProperties;
