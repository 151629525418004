import React, { createContext, useState, useEffect } from "react";

export const ScrollContext = createContext();

export function ScrollProvider(props) {
  const [xScroll, setXScroll] = useState(0);
  const [yScroll, setYScroll] = useState(0);

  const handleScroll = () => {
    const y = window.pageYOffset;
    setYScroll(y);

    const x = window.pageXOffset;
    setXScroll(x);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <ScrollContext.Provider value={{ xScroll, yScroll }}>
      {props.children}
    </ScrollContext.Provider>
  );
}
