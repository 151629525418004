import React from "react";

const ButtonGen = (props) => {
  let btnDisabled = false;

  if (props.btnDisabled) {
    btnDisabled = true;
  }
  return (
    <button
      className={props.buttonClass}
      type={props.type || "button"}
      onClick={props.onClickHandler}
      disabled={btnDisabled}
    >
      {props.children}
    </button>
  );
};

export default ButtonGen;
