import ApiService from "./ApiService";
import axios from 'axios';

class ImageService extends ApiService {

    static create = async (userLoggedIn, file, onUploadProgress) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/images`;

            const formData = new FormData();
            formData.append('file', file);

            const rc = {
                headers:{'x-access-token': userLoggedIn.token, 'content-type': 'multipart/form-data'},
                onUploadProgress: (e) => {
                    onUploadProgress(e);
                }
            }

            let response = await axios.post(url, formData, rc);
            return response.data.image; // user document


        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static createFromFdbImage = async (user, image) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/images/fromFdb`;

            const rc = {
                headers:{'x-access-token': user.token},
            }

            let response = await axios.post(url, image, rc);
            return response.data.image; // user document


        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static pullAllFdbImages = async (user) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/fdb/get-all-product-images`;

            const rc = {
                headers:{'x-access-token': user.token},
            }

            await axios.get(url, rc);

        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }



    static delete = async (userLoggedIn, image) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/images/${image._id}`;

            const rc = {
                headers:{'x-access-token': userLoggedIn.token},
            }

            return await axios.delete(url, rc);

        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static read = async (userLoggedIn, imageId) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id ? userLoggedIn.account._id: userLoggedIn.account}/images/${imageId}`;

            const rc = {
                headers:{'x-access-token': userLoggedIn.token},
            }

            let response = await axios.get(url, rc);
            return response.data.image;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static getAllProductWithImages = async (userLoggedIn, product) => {
        try{
            let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id || userLoggedIn.account}/products/${product._id}/images`;

            const rc = {
                headers:{'x-access-token': userLoggedIn.token},
            }

            let response = await axios.get(url, rc);
            return response.data.product;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default ImageService;
