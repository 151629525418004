import React from 'react';

const SwitchInput = ({isOn, labelName, onToggle, labelLeft}) => {

    const containerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'

    }

    const labelStyle = {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '21px',
        color: '#000000',
        margin: 0
    }

    const handleToggle = () => {
        onToggle(!isOn);
    }

    if(labelLeft){
        return (
            <section style={containerStyle}>
                <p style={labelStyle}>{labelName}</p>
                <label className="switch">
                    <input type="checkbox" onChange={handleToggle} checked={isOn} value={isOn}/>
                    <i/>
                </label>
            </section>
        )
    } else {
        return (
            <section style={containerStyle}>
                <label className="switch">
                    <input type="checkbox" onChange={handleToggle} checked={isOn} value={isOn}/>
                    <i/>
                </label>
                <p style={labelStyle}>{labelName}</p>
            </section>
        )
    }

}

export default SwitchInput;
