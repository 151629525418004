import React from "react";
import style from "./FunctionModal.module.css";
import { ReactComponent as Close } from "../../../assets/x.svg";
import { useEffect, useState } from "react";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import TextInput from "../../../components/TextInput";
import Button from "../../../components/Button";
import Spacer from "../../../components/Spacer";
import { getPreferredFieldName } from "../lookups/getPreferredFieldName";

const FunctionModal = ({ reportObj, operands, onDone, onClose }) => {
  const operators = [
    { type: "ADDITION", description: "+" },
    { type: "SUBTRACTION", description: "-" },
    { type: "MULTIPLICATION", description: "x" },
    { type: "DIVISI0N", description: "/" },
  ];

  const [operator, setOperator] = useState(operators[0]);
  const [name, setName] = useState("");
  const [functionNames, setFunctionNames] = useState([]);
  const [error, setError] = useState("");

  useEffect(() => {
    let tempFunctionNames = [];
    if (reportObj?.functionFields) {
      reportObj.functionFields.forEach((field) => {
        if (field?.name) {
          tempFunctionNames.push(field.name);
        }
      });

      setFunctionNames(tempFunctionNames);
    }
  }, [reportObj]);

  useEffect(() => {
    // checks if a function name starts with a letter and contains only letters and numbers
    const pattern = /^[a-zA-z]+[A-Za-z0-9]*$/;
    if (name && functionNames.includes(name)) {
      setError("The function name must be unique");
    } else if (name && !pattern.test(name)) {
      setError(
        "A function name must start with a letter and contain only letters and numbers"
      );
    } else {
      setError("");
    }
  }, [name]);

  const handleApply = () => {
    onDone(operator, name);
    setName("");
  };

  return (
    <div className={style["FunctionModal__overlay"]}>
      <div className={style["FunctionModal__modal"]}>
        <Close
          fill={"#121A2D"}
          className={style["FunctionModal__close"]}
          onClick={() => {
            onClose();
          }}
        />
        <div className={style["FunctionModal__header"]}>
          <h1>Function Builder</h1>
        </div>
        <br />
        <div className={style["FunctionModal__body"]}>
          <div className={style["FunctionModal__textInputContainer"]}>
            <TextInput
              validationText={""}
              labelName={"Name"}
              placeholder={"Enter a name for this function"}
              value={name}
              type={"text"}
              onChange={(val) => setName(val)}
              focus={true}
              isEnabled={true}
            />
            {error && <p className={style["FunctionModal__error"]}>{error}</p>}
          </div>
          <div className={style["FunctionModal__row"]}>
            <TextInput
              validationText={""}
              labelName={"Field 1"}
              placeholder={""}
              value={
                operands?.length === 2 &&
                // `${operands[0].collection}.${operands[0].name}`
                getPreferredFieldName(
                  operands[0].collection,
                  operands[0].name,
                  true,
                  false
                )
              }
              type={"text"}
              onChange={() => {}}
              focus={false}
              isEnabled={false}
            />
            <Spacer unit={"px"} space={20} />
            <DropdownInput
              value={operator.description}
              placeholder={"Select an operator"}
              labelName={"Operator"}
              listElements={operators}
              validationText={""}
              onSelection={(operator) => {
                setOperator(operator);
              }}
            />
            <Spacer unit={"px"} space={20} />
            <TextInput
              validationText={""}
              labelName={"Field 2"}
              placeholder={""}
              value={
                operands?.length === 2 &&
                // `${operands[1].collection}.${operands[1].name}`
                getPreferredFieldName(
                  operands[1].collection,
                  operands[1].name,
                  true,
                  false
                )
              }
              type={"text"}
              onChange={() => {}}
              focus={false}
              isEnabled={false}
            />
          </div>
        </div>
        <div className={style["FunctionModal__footer"]}>
          <Button
            onClick={() => {
              handleApply();
            }}
            labelName="Apply"
            isPrimary={true}
            isDisabled={name && !error ? false : true}
            minWidth={213}
          />
        </div>
      </div>
    </div>
  );
};

export default FunctionModal;
