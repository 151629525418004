import React from "react";
import styles from "./ProductionReportCard.module.css";

const ProductionReportCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.ProductionReportCard__reportList}>
      <li className={styles.ProductionReportCard__reportListItem}>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.facilityName}</h1>
          <p>{dataObj.facilityName}</p>
        </div>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.packagedOn}</h1>
          <p>{dataObj.packagedOn}</p>
        </div>
      </li>

      <li className={styles.ProductionReportCard__reportListItem}>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.stockLocationName}</h1>
          <p>{dataObj.stockLocationName}</p>
        </div>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.packageId}</h1>
          <p>{dataObj.packageId}</p>
        </div>
      </li>

      <li className={styles.ProductionReportCard__reportListItem}>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.productName}</h1>
          <p>{dataObj.productName}</p>
        </div>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.manufacturingAbbrName}</h1>
          <p>{dataObj.manufacturingAbbrName}</p>
        </div>
      </li>

      <li className={styles.ProductionReportCard__reportListItem}>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.patientLastName}</h1>
          <p>{dataObj.patientLastName}</p>
        </div>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.patientFirstName}</h1>
          <p>{dataObj.patientFirstName}</p>
        </div>
      </li>

      <li className={styles.ProductionReportCard__reportListItem}>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.productStrength}</h1>
          <p>{dataObj.productStrength}</p>
        </div>
        <div className={styles.ProductionReportCard__dataContainer}>
          <h1>{headingsObj.rxNumber}</h1>
          <p>{dataObj.rxNumber}</p>
        </div>
      </li>

      <li className={styles.ProductionReportCard__reportListItem}>
        <div
          className={`${styles.ProductionReportCard__dataContainer} ${styles["ProductionReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.qtyNeeded}</h1>
          <p>{dataObj.qtyNeeded}</p>
        </div>
        <div
          className={`${styles.ProductionReportCard__dataContainer} ${styles["ProductionReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.qoh}</h1>
          <p>{dataObj.qoh}</p>
        </div>
        <div
          className={`${styles.ProductionReportCard__dataContainer} ${styles["ProductionReportCard__dataContainer--triple"]}`}
        >
          <h1>{headingsObj.orderPriority}</h1>
          <p>{dataObj.orderPriority}</p>
        </div>
      </li>
    </ul>
  );
};

export default ProductionReportCard;
