/* 
    Checks if an element's content has vertical overflow.

    Takes an optional callback function, which fires after each overflow check.

    Checks for overflow whenever the referenced element size is changed.
*/

import { useState, useLayoutEffect } from "react";

export const useIsVerticalOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    if (current) {
      if ("ResizeObserver" in window) {
        new ResizeObserver(trigger).observe(current);
      }

      trigger();
    }
  }, [callback, ref]);

  return isOverflow;
};
