import ApiService from "./ApiService";
import axios from "axios";

class AddessService extends ApiService {
  static getCountries = async () => {
    try {
      let response = await axios.get(
        `${ApiService.BASE_URL}/countries`
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default AddessService;
