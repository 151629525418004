import React, { useEffect } from "react";

const Repeater = ({
  node,
  nodeClicked,
  nodeDragged,
  onClick,
  onClickCapture,
  onDrop,
  onDragOver,
  onDragStart,
  onDrag,
  children,
  ghost,
  ghostIndex,
  selectedObjectBorderColor,
  onInputFocused,
  copy,
}) => {
  useEffect(() => {
    onInputFocused(false);
  }, []);

  const position = node.nodeId;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;

  const width = widthmm.toString() + "mm";
  const height = heightmm.toString() + "mm";

  let marginTop = 0;
  let marginLeft = 0;

  const rowGapmm = Number(node.gapmm);

  if (ghost && node.orientation === "Vertical") {
    marginTop = marginTop =
      ((ghostIndex + 1) * (Number(heightmm) + rowGapmm)).toString() + "mm";
  } else if (ghost && node.orientation === "Horizontal") {
    marginTop = "1px";
    marginLeft = ((ghostIndex + 1) * (Number(widthmm) + 3)).toString() + "mm";
  }

  let border;

  if (!copy && nodeClicked && node && nodeClicked?.nodeId === node?.nodeId) {
    border = `2px dashed ${selectedObjectBorderColor}`;
  } else if (!copy) {
    border = "2px dashed #121a2d";
  } else {
    border = "1px dashed #121a2d";
  }

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

  if (Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) >
    Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) >
    Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  const styleRepeater = {
    position: "absolute",
    cursor: "pointer",
    left: x,
    top: y,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: width,
    height: height,
    border: border,
    borderRadius: "10px",
    marginTop: marginTop,
    marginLeft: marginLeft,
    boxSizing: "border-box",
  };

  let onDropFunction;

  if (!copy) {
    onDropFunction = onDrop;
  } else {
    onDropFunction = (e) => {
      e.stopPropagation();
    };
  }

  return (
    <div
      position={position}
      style={styleRepeater}
      draggable={!ghost ? true : false}
      onDragOver={onDragOver}
      onDrop={onDropFunction}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onClick={onClick}
      onClickCapture={onClickCapture}
    >
      {children}
    </div>
  );
};

export default Repeater;
