import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useContext,
} from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateRangePicker } from "react-date-range";
import SearchBar from "../../../components/SearchBar";
import DropdownInput from "../../../components/DropDownInput/DropdownInput";
import Button from "../../../components/Button";
import ButtonGen from "../../commonUI/ButtonGen";
import Table from "../../../components/Table/Table";
import Spacer from "../../../components/Spacer";
import PendingDispensingOrderCardList from "./PendingDispensingOrderCardList";
import PendingDispensingOrderItem from "./PendingDispensingOrderItem";
import { ReactComponent as BackButton } from "../../../assets/back_icon.svg";
import { ReactComponent as BackButtonMobile } from "../../../assets/back-icon-mobile.svg";
import ToggleSwitch from "../../../components/ToggleSwitch/ToggleSwitch";
import PendingDispensingOrderController from "./PendingDispensingOrderController";
import DispensingOrderService from "../../../services/DispensingOrderService";
import SubstituteProduct from "./SubstituteProduct";
import AdminDateTime from "./AdminDateTime";
import AdjustQuantity from "./AdjustQuantity";
import ChangePatientLocation from "./ChangePatientLocation";
import styles from "./PendingDispensingOrder.module.css";
import ErrorBanner from "../../../components/ErrorBanner";
import ProductFieldController from "../../../controllers/ProductFieldController";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import CreateProduct from "../../Product/CreateProduct2";
import alertStyle from "../../../Alert.module.css";
import ChangePriority from "./ChangePriority";
import WaitIndicator from "../../../components/WaitIndicator";
import { startOfMonth } from "date-fns";

const PendingDispensingOrder = ({
  user,
  site,
  dispensingOrder,
  selectedPackagingMethods,
}) => {
  const productFieldController = new ProductFieldController();

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const navigate = useNavigate();
  const _controllerRef = useRef(null);
  const [error, setError] = useState("");

  const [updateTable, setUpdateTable] = useState(false);
  const updateTableRef = useRef(null);
  updateTableRef.current = updateTable;

  const [localDispensingOrder, setLocalDispensingOrder] =
    useState(dispensingOrder);

  const [localDispensingOrderItems, setLocalDispensingOrderItems] = useState(
    []
  );
  const localDispensingOrderItemsRef = useRef(null);
  localDispensingOrderItemsRef.current = localDispensingOrderItems;

  const [searchText, setSearchText] = useState("");
  const [filterType, setFilterType] = useState("ALL");
  const [dispensingData, setDispensingData] = useState([]);
  const [itemError, setItemError] = useState(false);

  const [displaySubstituteProductModal, setDisplaySubstituteProductModal] =
    useState(false);
  const displaySubstituteProductModalRef = useRef(null);
  displaySubstituteProductModalRef.current = displaySubstituteProductModal;

  const [displayAddProductModal, setDisplayAddProductModal] = useState(false);
  const displayAddProductModalRef = useRef(null);
  displayAddProductModalRef.current = displayAddProductModal;

  const [displayAdminDateTimeModal, setDisplayAdminDateTimeModal] =
    useState(false);
  const displayAdminDateTimeModalRef = useRef(null);
  displayAdminDateTimeModalRef.current = displayAdminDateTimeModal;

  const [displayAdjustQuantityModal, setDisplayAdjustQuantityModal] =
    useState(false);
  const displayAdjustQuantityModalRef = useRef(null);
  displayAdjustQuantityModalRef.current = displayAdjustQuantityModal;

  const [
    displayChangePatientLocationModal,
    setDisplayChangePatientLocationModal,
  ] = useState(null);
  const displayChangePatientLocationModalRef = useRef(null);
  displayChangePatientLocationModalRef.current =
    displayChangePatientLocationModal;

  const [selectedItem, setSelectedItem] = useState("");
  const [modifiedItem, setModifiedItem] = useState("");
  const [displayChangePriorityModal, setDisplayChangePriorityModal] =
    useState(false);

  const [selectedDispensingOrderItems, setSelectedDispensingOrderItems] =
    useState([]);

  const [formatedCreatedAtLocalTime, setFormatedCreatedAtLocalTime] =
    useState("");

  const [fetching, setFetching] = useState(false);
  const [waitIndicatorMessage, setWaitIndicatorMessage] = useState(
    "Fetching order details..."
  );
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [selectionRange, setSelectionRange] = useState(null);
  const [startDateString, setStartDateString] = useState("");
  const [stopDateString, setStopDateString] = useState("");

  _controllerRef.current = new PendingDispensingOrderController(user);

  /* For Testing */
  // useEffect(() => {
  //   console.log("localDispensingOrderItems: ", localDispensingOrderItems);
  //   console.log("dispensingOrder: ", dispensingOrder);
  //   console.log("selectedDispensingOrderItems: ", selectedDispensingOrderItems);
  //   console.log("");
  // }, [
  //   dispensingOrder,
  //   localDispensingOrderItems,
  //   selectedDispensingOrderItems,
  // ]);

  // TIMER FOR REFRESH
  useEffect(() => {
    let timerId = setInterval(() => {
      updateDispensingOrderItems();
    }, 30000);
    return () => {
      clearInterval(timerId);
    };
  }, []);

  const updateDispensingOrderItems = () => {
    if (
      !(
        displaySubstituteProductModalRef.current ||
        displayAddProductModalRef.current ||
        displayAdminDateTimeModalRef.current ||
        displayAdjustQuantityModalRef.current ||
        displayChangePatientLocationModalRef.current
      )
    ) {
      setFilterType("ALL");
      if (localDispensingOrderItemsRef.current.length === 0) {
        setFetching(true);
      }

      let refreshTable = false;

      _controllerRef.current
        .getDetails(dispensingOrder, site)
        .then((details) => {
          if (details.dispensingOrder && details.dispensingOrder.createdAt) {
            const date = new Date(details.dispensingOrder.createdAt);
            setFormatedCreatedAtLocalTime(formatDate(date));
          }

          if (
            updateTableRef.current ||
            localDispensingOrderItemsRef.current.length !==
              details.dispensingOrderItems.length
          ) {
            refreshTable = true;
          } else if (
            localDispensingOrderItemsRef.current.length ===
            details.dispensingOrderItems.length
          ) {
            localDispensingOrderItemsRef.current.forEach((item, index) => {
              if (
                item?.errorTypes?.length &&
                item.errorTypes.includes("NO_PRODUCT")
              ) {
                if (
                  details.dispensingOrderItems[index]?.errorTypes?.length &&
                  !details.dispensingOrderItems[index].errorTypes.includes(
                    "NO_PRODUCT"
                  )
                ) {
                  refreshTable = true;
                }
              }
            });
          }

          if (refreshTable) {
            setLocalDispensingOrderItems(details.dispensingOrderItems);
            setUpdateTable(false);
          }
          setFetching(false);
        })
        .catch((error) => {
          setError(error);
        });
    }
  };

  useEffect(() => {
    if (filterType === "Patient - Special") {
      if (dispensingData?.length) {
        // converts local time to UTC
        let startDateUTC = new Date(dispensingData[0][9] + "Z");
        dispensingData.forEach((arrayItem) => {
          const nextDate = new Date(arrayItem[9]);
          if (new Date(nextDate.getTime() < startDateUTC.getTime())) {
            startDateUTC = nextDate;
          }
        });

        // The number of minutes returned by getTimezoneOffset() is positive if the local time zone is behind UTC, and negative if the local time zone is ahead of UTC
        const offset = startDateUTC.getTimezoneOffset();

        // converts UTC time to local
        const startDateLocal = new Date(
          startDateUTC.getTime() + offset * 60000
        );

        let stopDateLocal = new Date(startDateUTC.getTime() + offset * 60000);
        stopDateLocal.setDate(startDateLocal.getDate() + 6);
        stopDateLocal.setHours(23);
        stopDateLocal.setMinutes(59);
        stopDateLocal.setSeconds(59);

        setSelectionRange({
          startDate: startDateLocal,
          endDate: stopDateLocal,
          key: "selection",
        });
      }
    }
  }, [filterType]);

  useEffect(() => {
    if (selectionRange?.startDate && selectionRange?.endDate) {
      const startDateObj = new Date(selectionRange.startDate);
      const stopDateObj = new Date(selectionRange.endDate);

      setStartDateString(
        `${
          startDateObj.getMonth() + 1
        }/${startDateObj.getDate()}/${startDateObj.getFullYear()}`
      );
      setStopDateString(
        `${
          stopDateObj.getMonth() + 1
        }/${stopDateObj.getDate()}/${stopDateObj.getFullYear()}`
      );
    }
  }, [selectionRange]);

  useEffect(() => {
    updateDispensingOrderItems();
  }, []);

  useEffect(() => {
    // setSelectedItem("");
    setSelectedDispensingOrderItems([]);
  }, [smScreen]);

  const padTo2Digits = (num) => {
    return num.toString().padStart(2, "0");
  };

  const handleDateRangeSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const formatDate = (date) => {
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      `${date.getFullYear().toString().substr(-2)} ${padTo2Digits(
        date.getHours()
      )}:${padTo2Digits(date.getMinutes())}`,
    ].join("/");
  };

  useEffect(() => {
    if (dispensingOrder.errorExists) {
      setItemError(true);
    }

    setDispensingData(
      localDispensingOrderItems

        .filter((o) =>
          filterType === "ALL" || filterType === "Patient - Special"
            ? true
            : o.errorExists
        )

        .filter((o) => {
          if (
            o?.adminDate &&
            filterType === "Patient - Special" &&
            selectionRange?.startDate &&
            selectionRange.endDate
          ) {
            const adminDateArray = o.adminDate.split("-");
            const adminDateYear = adminDateArray[0];
            const adminDateMonth = adminDateArray[1];
            const adminDateDay = adminDateArray[2];

            const adminDateObject = new Date(
              `${adminDateYear}, ${adminDateMonth}, ${adminDateDay}`
            );

            if (
              adminDateObject >= selectionRange.startDate &&
              adminDateObject <= selectionRange.endDate
            ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
        })

        .filter((o) =>
          searchText
            ? (o.patientInfo &&
                o.patientInfo.id &&
                o.patientInfo.id.includes(searchText)) ||
              (o.patientInfo &&
                o.patientInfo.name &&
                o.patientInfo.name.toUpperCase().includes(searchText)) ||
              (o.product &&
                o.product.packageId.toUpperCase().includes(searchText)) ||
              (o.product &&
                o.product.primaryName &&
                o.product.primaryName.toUpperCase().includes(searchText)) ||
              (o.product &&
                o.product.secondaryName &&
                o.product.secondaryName.toUpperCase().includes(searchText))
            : true
        )

        .map((item) => {
          if (!itemError && item.errorExists) {
            setItemError(true);
          }
          let patientNameTemplateString = item.patientInfo?.id
            ? `${item.patientInfo.name} (${item.patientInfo.id}),`
            : `${item.patientInfo.name}`;
          return [
            "_selectedObject" ? item : "",
            <div
              className={
                !item.errorExists
                  ? styles.PendingDispensingOrders_itemStatusIndicator
                  : [
                      styles["PendingDispensingOrders_itemStatusIndicator"],
                      styles[
                        "PendingDispensingOrders_itemStatusIndicator--withError"
                      ],
                    ].join(" ")
              }
            ></div>,
            dispensingOrder &&
            dispensingOrder.facility &&
            dispensingOrder.facility.name
              ? dispensingOrder.facility.name
              : "",
            item.priority ? item.priority : "",
            patientNameTemplateString,
            item.patientInfo && item.patientInfo.room
              ? item.patientInfo.room
              : "",
            item.rxNumber ? item.rxNumber : "",
            item.product && item.product.packageId
              ? item.product.packageId
              : "",
            item.product
              ? productFieldController.getDefaultName(item.product)
              : "",
            item.adminDate ? item.adminDate : "",
            item.adminTime ? item.adminTime.substring(0, 5) : "",
            item.qtyNeeded ? item.qtyNeeded : "",
            item.errorDescription ? item.errorDescription : "",
            <PendingDispensingOrderItem
              user={user}
              item={item}
              onActivateSubstituteProductModal={() =>
                handleSubstituteProductModal(true)
              }
              onActivateAddProductModal={() => handleAddProductModal(true)}
              onActivateAdminDateTimeModal={() =>
                handleAdminDateTimeModal(true)
              }
              onActivateAdjustQuantityModal={() =>
                handleAdjustQuantityModal(true)
              }
              onActivateChangePatientLocationModal={() =>
                handleChangePatientLocationModal(true)
              }
              onActivatePriorityModal={() => handleChangePriorityModal(item)}
              onHandleSelectedItem={handleSelectedItem}
            ></PendingDispensingOrderItem>,
          ];
        })
    );
  }, [
    localDispensingOrderItems,
    searchText,
    filterType,
    displayChangePriorityModal,
    startDateString,
    stopDateString,
  ]);

  const { t } = useTranslation("pendingDispensingOrder");

  const handleSearch = (searchText) => {
    setSearchText(searchText.toUpperCase());
  };

  const filterListObjects = [
    { type: t("filterListAll"), description: t("filterListAll") },
    {
      type: t("filterListSpecialCustomer"),
      description: t("filterListSpecialCustomer"),
    },
    { type: t("filterListError"), description: t("filterListError") },
  ];

  const onClose = () => {
    navigate("/dispensing-orders", {
      state: {
        selectedSite: site,
        selectedPackagingMethods: selectedPackagingMethods,
      },
    });
  };

  const onBack = () => {
    navigate("/dispensing-orders", {
      state: {
        selectedSite: site,
        selectedPackagingMethods: selectedPackagingMethods,
      },
    });
  };

  const handleUpdateTable = (bool) => {
    setUpdateTable(bool);
  };

  // const handleReject = async () => {
  //   if (
  //     window.confirm(
  //       `Are you sure you want to reject ${dispensingOrder.dispensingOrderId}? This cannot be undone.`
  //     )
  //   ) {
  //     await _controllerRef.current.rejectOrder(dispensingOrder);
  //     onClose();
  //   }
  // };

  // Revised by RWD on 02/07/2024
  const handleReject = async () => {
    if (
      window.confirm(
        `Are you sure you want to reject ${dispensingOrder.dispensingOrderId}? This cannot be undone.`
      )
    ) {
      await DispensingOrderService.resetOrder(
        user,
        site,
        dispensingOrder,
        dispensingOrder.state
      );
      onClose();
    }
  };

  const handleApprove = () => {
    _controllerRef.current.approveOrder(dispensingOrder);
    onClose();
  };

  const handleSelectedItem = (item) => {
    setSelectedItem(item);
  };

  const handleModifiedItem = (item) => {
    setModifiedItem(item);
  };

  const handleSubstituteProductModal = (bool) => {
    setDisplaySubstituteProductModal(bool);
  };

  const handleAddProductModal = (bool) => {
    setDisplayAddProductModal(bool);
  };

  const handleAdminDateTimeModal = (bool) => {
    setDisplayAdminDateTimeModal(bool);
  };

  const handleAdjustQuantityModal = (bool) => {
    setDisplayAdjustQuantityModal(bool);
  };

  const handleChangePatientLocationModal = (bool) => {
    setDisplayChangePatientLocationModal(bool);
  };

  const handleChangePriorityModal = (item) => {
    setDisplayChangePriorityModal(item);
  };

  const handleUpdateItem = (originalItem, modifiedItem) => {
    const matchCriteria = (element) => element === originalItem;

    const index = localDispensingOrderItems.findIndex(matchCriteria);
    if (index !== -1) {
      let tempArray = [...localDispensingOrderItems];
      tempArray[index] = modifiedItem;
      setLocalDispensingOrderItems(tempArray);
    }

    setSelectedDispensingOrderItems([]);
  };

  const handleSplitOrders = (selectedDispensingOrderItemsList) => {
    setSelectedDispensingOrderItems(selectedDispensingOrderItemsList);
  };

  const handleProcessSplitOrder = async () => {
    if (localDispensingOrderItems.length >= 1) {
      setWaitIndicatorMessage("Splitting dispensing orders...");
      setFetching(true);
      await _controllerRef.current.splitOrder(
        dispensingOrder,
        localDispensingOrderItems,
        selectedDispensingOrderItems
      );

      if (filterType === "Patient - Special") {
        // Get patient for selected items group
        // Delete any pending dispensing order items for this patient outside of the selected date range.
        const patientInfo = selectedDispensingOrderItems[0]?.patientInfo;
        const patientLastName = patientInfo?.lastName;
        const patientFirstName = patientInfo?.firstName;
        const facilityId = selectedDispensingOrderItems[0]?.facility?._id;

        const dispensingOrderItemsToRemove = localDispensingOrderItems
          .map((o) => o)
          .filter((o) => {
            const adminDateArray = o.adminDate.split("-");
            const adminDateYear = adminDateArray[0];
            const adminDateMonth = adminDateArray[1];
            const adminDateDay = adminDateArray[2];

            const adminDateObject = new Date(
              `${adminDateYear}, ${adminDateMonth}, ${adminDateDay}`
            );

            return (
              o?.patientInfo?.lastName === patientLastName &&
              o?.patientInfo?.firstName === patientFirstName &&
              o?.facility?._id === facilityId &&
              !(
                adminDateObject >= selectionRange.startDate &&
                adminDateObject <= selectionRange.endDate
              )
            );
          });

        // console.log(
        //   "dispensingOrderItemsToRemove: ",
        //   dispensingOrderItemsToRemove
        // );

        async function removeDispensingOrderItems() {
          for (let i = 0; i < dispensingOrderItemsToRemove.length; i++) {
            await _controllerRef.current
              .deleteDispensingOrderItem(dispensingOrderItemsToRemove[i])
              .then((results) => {
                console.log("results: ", results);
              })
              .catch((error) => {
                console.log(error);
              });
          }
          console.log("closed");
        }

        await removeDispensingOrderItems();
      }

      onClose();
    } else {
      setError(
        "Orders can only be split when there is more than one dispensing transaction."
      );
    }
  };

  const handleToggleSwitch = () => {
    if (filterType === "ALL") {
      setFilterType("ERROR");
    } else {
      setFilterType("ALL");
    }
  };

  let HeaderComponent;

  {
    HeaderComponent = smScreen ? (
      <Fragment>
        <div className={styles.PendingDispensingOrder__header}>
          <div className={styles.PendingDispensingOrder__headerTitleGroup}>
            <div className={styles.PendingDispensingOrder__backButtonMobile}>
              <BackButtonMobile onClick={onBack} />
            </div>
            <h1>Order Details</h1>
          </div>
          <div className={styles.PendingDispensingOrder__errorToggleGroup}>
            <span>Errors only</span>
            <ToggleSwitch
              id={1}
              onChangeHandler={handleToggleSwitch}
              checkState={filterType === "ERROR" ? true : false}
            />
          </div>
        </div>

        <div className={styles.PendingDispensingOrder__searchBarContainer}>
          <SearchBar
            smFullWidth={true}
            placeholder={t("searchBarText")}
            onSearch={handleSearch}
            focus={true}
          />
        </div>
      </Fragment>
    ) : (
      <div className={styles.PendingDispensingOrder__header}>
        <BackButton
          className={styles.PendingDispensingOrder__button}
          onClick={onClose}
        />
        <h1>
          {site.name} <span>{t("pendingDispensingOrderTitle")}</span>
        </h1>
      </div>
    );
  }

  if (fetching) {
    return <WaitIndicator message={waitIndicatorMessage} />;
  } else if (displayChangePriorityModal) {
    return (
      <ChangePriority
        user={user}
        dispensingOrderItem={displayChangePriorityModal}
        onCloseModal={() => {
          handleChangePriorityModal(null);
        }}
        pendDisOrderControllerRef={_controllerRef.current}
        handleUpdateTable={handleUpdateTable}
      />
    );
  } else if (displaySubstituteProductModal) {
    return (
      <SubstituteProduct
        pendDisOrderControllerRef={_controllerRef.current}
        error={error}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        modifiedItem={modifiedItem}
        handleModifiedItem={handleModifiedItem}
        onCloseSubstituteProductModal={() =>
          handleSubstituteProductModal(false)
        }
        onUpdateItem={handleUpdateItem}
      />
    );
  } else if (displayAddProductModal) {
    return (
      <CreateProduct
        user={user}
        product={null}
        dispensingOrderItem={selectedItem}
        onCloseAddProductModal={() => handleAddProductModal(false)}
        isNew={true}
      />
    );
  } else if (displayAdminDateTimeModal) {
    return (
      <AdminDateTime
        pendDisOrderControllerRef={_controllerRef.current}
        error={error}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        modifiedItem={modifiedItem}
        handleModifiedItem={handleModifiedItem}
        onCloseAdminDateTimeModal={() => handleAdminDateTimeModal(false)}
        onUpdateItem={handleUpdateItem}
      />
    );
  } else if (displayAdjustQuantityModal) {
    return (
      <AdjustQuantity
        pendDisOrderControllerRef={_controllerRef.current}
        error={error}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        modifiedItem={modifiedItem}
        handleModifiedItem={handleModifiedItem}
        onCloseAdjustQuantityModal={() => handleAdjustQuantityModal(false)}
        onUpdateItem={handleUpdateItem}
      />
    );
  } else if (displayChangePatientLocationModal) {
    return (
      <ChangePatientLocation
        pendDisOrderControllerRef={_controllerRef.current}
        error={error}
        updateTable={updateTable}
        handleUpdateTable={handleUpdateTable}
        user={user}
        selectedItem={selectedItem}
        modifiedItem={modifiedItem}
        handleModifiedItem={handleModifiedItem}
        onCloseChangePatientLocationModal={() =>
          handleChangePatientLocationModal(false)
        }
        onUpdateItem={handleUpdateItem}
      />
    );
  } else {
    return (
      <div className={styles.PendingDispensingOrder__container}>
        {showDateTimeRangePicker && (
          <div className={styles.PendingDispensingOrder__overlay}>
            <div className={styles.PendingDispensingOrder__modal}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleDateRangeSelect}
                rangeColors={["#089BAB"]}
              />
              <br />
              <Button
                labelName={"Continue"}
                isPrimary={true}
                onClick={() => setShowDateTimeRangePicker(false)}
              />
            </div>
          </div>
        )}
        {HeaderComponent}

        <section
          className={
            smScreen
              ? styles.PendingDispensingOrder__noDisplay
              : styles.PendingDispensingOrders__searchAndFilterRow
          }
        >
          <div className={styles.PendingDispensingOrder__searchBarContainer}>
            <SearchBar
              placeholder={t("searchBarText")}
              onSearch={handleSearch}
              focus={true}
            />
          </div>

          {filterType === "Patient - Special" && (
            <div className={styles.PendingDispensingOrder__dateRangeGroup}>
              <p className={styles.PendingDispensingOrder__dateRangeLabel}>
                Start and End Date
              </p>
              <p
                className={styles.PendingDispensingOrder__dateRange}
                onClick={() => setShowDateTimeRangePicker(true)}
              >
                {startDateString || ""}-{stopDateString || ""}
              </p>
            </div>
          )}

          <div style={{ width: "300px" }}>
            <DropdownInput
              labelName={t("typeLabel")}
              value={filterType ? filterType : ""}
              validationText={""}
              placeholder={""}
              isEnabled={true}
              listElements={filterListObjects}
              onSelection={(value) => setFilterType(value.type)}
            />
          </div>
        </section>

        {error && (
          <div>
            <br />
            <ErrorBanner
              message={error}
              onClose={() => {
                setError("");
              }}
            />
            <br />
          </div>
        )}
        <div
          className={
            smScreen
              ? styles.PendingDispensingOrder__noDisplay
              : styles.PendingDispensingOrders__orderInfo
          }
        >
          <div
            className={
              !localDispensingOrder.errorExists
                ? styles.PendingDispensingOrders__groupStatusIndicator
                : [
                    styles["PendingDispensingOrders__groupStatusIndicator"],
                    styles[
                      "PendingDispensingOrders__groupStatusIndicator--withError"
                    ],
                  ].join(" ")
            }
          ></div>

          <div>
            <p
              className={[
                styles["PendingDispensingOrders__orderInfoItem"],
                styles[
                  "PendingDispensingOrders__orderInfoItem--dispensingOrderId"
                ],
              ].join(" ")}
            >
              Dispensing Order ID:
              <span>
                {dispensingOrder && dispensingOrder.dispensingOrderId
                  ? ` ${dispensingOrder.dispensingOrderId}`
                  : ""}
              </span>
            </p>

            {localDispensingOrder && localDispensingOrder.errorExists && (
              <p className={styles.PendingDispensingOrders__errorMsg}>
                {localDispensingOrder.errorDescription}
              </p>
            )}

            <p
              className={[
                styles["PendingDispensingOrders__orderInfoItem"],
                styles["PendingDispensingOrders__orderInfoItem--createdAt"],
              ].join(" ")}
            >
              Received:
              <span>{formatedCreatedAtLocalTime}</span>
            </p>
          </div>
        </div>
        {!smScreen && (
          <div className={styles.PendingDispensingOrders__tableContainer}>
            <Table
              onClickHandler={handleSplitOrders}
              searchText={searchText}
              headers={[
                " ",
                "Facility",
                "Priority",
                "Patient",
                "Location",
                "Rx Number",
                "ID",
                "Product",
                "Admin Date",
                "Admin Time",
                "Qty",
                "Error",
                " ",
              ]}
              records={dispensingData}
            />
          </div>
        )}

        {smScreen && (
          <div className={styles.PendingDispensingOrder__orderDetailsGroup}>
            <h2 className={styles.PendingDispensingOrder__heading}>
              {site.name}
            </h2>
            <div
              className={
                selectedDispensingOrderItems &&
                selectedDispensingOrderItems.length > 1
                  ? `${styles.PendingDispensingOrder__splitGroup}  ${styles["PendingDispensingOrder__splitGroup--showSplitBtn"]}`
                  : styles.PendingDispensingOrder__splitGroup
              }
            >
              <div className={styles.PendingDispensingOrders__orderInfo}>
                <p
                  className={
                    !dispensingOrder.errorExists
                      ? styles.PendingDispensingOrders__groupStatusIndicator
                      : [
                          styles[
                            "PendingDispensingOrders__groupStatusIndicator"
                          ],
                          styles[
                            "PendingDispensingOrders__groupStatusIndicator--withError"
                          ],
                        ].join(" ")
                  }
                ></p>

                <p
                  className={
                    styles[
                      "PendingDispensingOrders__orderInfoItem--dispensingOrderId"
                    ]
                  }
                >
                  {dispensingOrder &&
                  dispensingOrder.dispensingOrderId &&
                  dispensingOrder.createdAt
                    ? `${dispensingOrder.dispensingOrderId} (${formatedCreatedAtLocalTime})`
                    : dispensingOrder.dispensingOrderId
                    ? `${dispensingOrder.dispensingOrderId}`
                    : null}
                </p>
              </div>
              {selectedDispensingOrderItems &&
                selectedDispensingOrderItems.length > 1 && (
                  <button
                    className={styles.PendingDispensingOrder__splitBtn}
                    onClick={async () => await handleProcessSplitOrder()}
                  >
                    Split
                  </button>
                )}
            </div>

            {dispensingOrder && dispensingOrder.errorExists && (
              <p className={styles.PendingDispensingOrders__errorMsg}>
                {dispensingOrder.errorDescription}
              </p>
            )}

            <PendingDispensingOrderCardList
              onClickHandler={handleSplitOrders}
              searchText={searchText}
              headers={[
                " ",
                "Facility",
                "Priority",
                "Patient",
                "Location",
                "Rx Number",
                "ID",
                "Product",
                "Admin Date",
                "Admin Time",
                "Qty",
                "Error",
                " ",
              ]}
              records={dispensingData}
              errorDescription={
                dispensingOrder && dispensingOrder.errorExists
                  ? dispensingOrder.errorDescription
                  : ""
              }
            />
          </div>
        )}

        <section
          className={
            smScreen
              ? styles.PendingDispensingOrders__buttons
              : selectedDispensingOrderItems &&
                selectedDispensingOrderItems.length === 0
              ? `${styles.PendingDispensingOrders__buttons} ${styles["PendingDispensingOrders__buttons--flexEnd"]}`
              : `${styles.PendingDispensingOrders__buttons} ${styles["PendingDispensingOrders__buttons--flexSpaceBetween"]}`
          }
        >
          {!smScreen &&
            selectedDispensingOrderItems &&
            selectedDispensingOrderItems.length > 1 && (
              <Button
                labelName={t("splitOrderButtonLabel")}
                minWidth={"213px"}
                isPrimary={true}
                isDisabled={false}
                onClick={() => handleProcessSplitOrder()}
              />
            )}

          <div
            className={styles.PendingDispensingOrders__ApproveRejectBtnGroup}
          >
            <Button
              labelName={t("rejectButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => handleReject()}
              minWidth={smScreen ? 122 : null}
            />

            <Spacer space={20} unit={"px"} />

            {!itemError ? (
              <Button
                labelName={t("approveButtonLabel")}
                isPrimary={true}
                isDisabled={false}
                onClick={() => handleApprove()}
                minWidth={smScreen ? 122 : 213}
              />
            ) : (
              <ButtonGen
                buttonClass={styles.PendingDispensingOrders__disabledButton}
              >
                {t("approveButtonLabel")}
              </ButtonGen>
            )}
          </div>
        </section>
      </div>
    );
  }
};

export default PendingDispensingOrder;
