import React, {Fragment, useEffect, useState} from "react";
import styles from './Support.module.css'
import SFDropDownList from "../../components/SFDropDownList/SFDropDownList";
import { ColumnDirective, ColumnsDirective, GridComponent } from '@syncfusion/ej2-react-grids';
import AccountService from "../../services/AccountService";
import ErrorBanner from "../../components/ErrorBanner";

const Support = ({user}) => {

    const [accountList, setAccountList] = useState([]);
    const [error, setError] = useState('');

    useEffect(() => {
        AccountService.accountList(user).then(accounts=>{
            accounts.sort((a,b)=>{
                if(a.companyName < b.companyName){return -1;}
                if(b.companyName < a.companyName){return 1;}
                return 0;
            })
            let temp = [];
            for(let i = 0; i < accounts.length; i++){
                temp.push(accounts[i].companyName);
            }
            setAccountList(temp);
        }).catch(error=>{
            setError(error);
        })
    }, [user]);

    return (
        <Fragment>
            <div className={styles.col}>
                <h1 className={styles.pageTitle}>Support Dashboard</h1>
                {error && <ErrorBanner message={error} onClose={()=>{setError('')}}/>}
                <br/><br/>
                <SFDropDownList dropDownList={accountList} defaultValue={accountList.length > 0 ? accountList[0] : ''} id={'accountList'} label={'Account'} onSelection={(account)=>{
                    console.log(account)
                }} placeHolder={'Select an account'}/>
                {/*<GridComponent dataSource={}>*/}
                {/*    <ColumnsDirective>*/}
                {/*        <ColumnDirective field='OrderID' width='100' textAlign="Right"/>*/}
                {/*        <ColumnDirective field='CustomerID' width='100'/>*/}
                {/*        <ColumnDirective field='EmployeeID' width='100' textAlign="Right"/>*/}
                {/*        <ColumnDirective field='Freight' width='100' format="C2" textAlign="Right"/>*/}
                {/*        <ColumnDirective field='ShipCountry' width='100'/>*/}
                {/*    </ColumnsDirective>*/}
                {/*</GridComponent>*/}
            </div>
        </Fragment>
    )
}

export default Support;