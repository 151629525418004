import styled from "styled-components";
import { ReactComponent as MoreActions } from "../assets/more-actions.svg";
import Spacer from "./Spacer";
import { ReactComponent as ChartImage } from "../assets/chart.svg";
import React, { useState, useContext } from "react";
import ContextMenu from "./ContextMenu";
import Popup from "./PopupMenu/Popup";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";

//region CSS
const TemplateContainer = styled.button`
  @media (max-width: 640px) {
    width: 76px;
  }
  @media (min-width: 641px) {
    width: 169px;
  }

  @media (max-width: 640px) {
    height: 80px;
  }
  @media (min-width: 641px) {
    height: 100px;
  }

  background: #ffffff;
  border: 1px dashed #006874;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  cursor: pointer;
  &:focus,
  &:active {
    outline: none;
    border: 2px dashed #006874;
  }
`;
const ReportContainer = styled.div`
  @media (max-width: 640px) {
    width: 76px;
  }
  @media (min-width: 641px) {
    width: 169px;
  }
  @media (max-width: 640px) {
    height: 80px;
  }
  @media (min-width: 641px) {
    height: 100px;
  }
  background: #ffffff;
  border: 1px solid #006874;
  box-sizing: border-box;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  &:focus,
  &:active {
    outline: none;
    border: 2px solid #006874;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: 640px) {
    width: 80px;
  }
  @media (min-width: 641px) {
    width: 169px;
  }
  @media (max-width: 640px) {
    height: 116px;
  }
  @media (min-width: 641px) {
    // height: 183px;
  }

  @media (max-width: 640px) {
    margin-right: 12px;
  }
  @media (min-width: 641px) {
    // margin-right: 40px;
  }
`;
const Label = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: #454b60;
`;
const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: relative;
`;
const ImageContainer = styled.div`
  @media (max-width: 640px) {
    width: 60px;
  }
  @media (min-width: 641px) {
    width: 70px;
  }
  @media (max-width: 640px) {
    height: inherit;
  }
  @media (min-width: 641px) {
    height: 70px;
  }
  @media (max-width: 640px) {
    margin-top: 4px;
  }
  @media (min-width: 641px) {
    margin-top: 15px;
  }
  object-fit: contain;
  overflow: hidden;
  cursor: pointer;
`;
//endregion

const ReportTile = ({
  isTemplate,
  imageName,
  imageFillColor,
  title,
  onCreateTemplate,
  actions,
  onAction,
  onRunReport,
}) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  //const [contextMenu, setContextMenu] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleKeyUp = (event, container) => {
    // If report is focused, open it if the Enter key is pressed
    const code = event.keyCode || event.which;
    if (code === 13) {
      if (container === "Template") {
        onCreateTemplate();
      } else if (container === "Report") {
        onRunReport();
      }
    }
  };

  if (isTemplate) {
    return (
      <Container>
        <TemplateContainer
          onClick={onCreateTemplate}
          onKeyUp={(event) => handleKeyUp(event, "Template")}
        >
          <Header>
            <MoreActions fill={"#A7BBCF"} />
          </Header>
          <ImageContainer>
            {imageName === "barChart" && <ChartImage fill={imageFillColor} />}
          </ImageContainer>
        </TemplateContainer>
        <Spacer space={13} unit={"px"} />
        <Label>{title}</Label>
      </Container>
    );
  } else {
    return (
      <>
        <Container>
          <ReportContainer onKeyUp={(event) => handleKeyUp(event, "Report")}>
            <Header>
              {actions.length > 0 && (
                <MoreActions
                  style={{ zIndex: 50 }}
                  fill={"#089BAB"}
                  onClick={(e) => {
                    // e.preventDefault();
                    // if(actions.length > 0){
                    //     const rect = e.target.getBoundingClientRect();
                    //     let left = rect.x + rect.width;
                    //     let top = rect.y + rect.height;
                    //     setContextMenu({left: left, top: top, listItems: actions, isRightAligned: true, onSelect: (action)=>{
                    //             onAction(action);
                    //             setContextMenu(null);
                    //         }});
                    // }
                    setShowPopup(true);
                  }}
                />
              )}
              {showPopup && (
                <Popup
                  onAction={(action) => {
                    onAction(action);
                    setShowPopup(false);
                  }}
                  menuItems={actions}
                  onClose={() => setShowPopup(false)}
                  rightJustify={true}
                />
              )}
            </Header>
            <ImageContainer onClick={() => onRunReport()}>
              {imageName === "barChart" && <ChartImage fill={imageFillColor} />}
            </ImageContainer>
          </ReportContainer>
          <Spacer space={13} unit={"px"} />
          <Label>{title}</Label>
        </Container>
        {/*{contextMenu &&*/}
        {/*    <ContextMenu onSelect={contextMenu.onSelect} listItems={contextMenu.listItems} top={contextMenu.top} left={contextMenu.left} isRightAligned={contextMenu.isRightAligned} maxHeight={contextMenu.maxHeight} onClose={()=>setContextMenu(null)}/>*/}
        {/*}*/}
      </>
    );
  }
};

export default ReportTile;
