import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.password) {
        errors.password = 'Password is required'
    }

    if(!values.firstName) {
        errors.firstName = 'First name is required'
    }

    if(!values.lastName) {
        errors.lastName = 'Last name is required'
    }

    return errors;
}
