import React from "react";
import styles from "./EV54AlarmsCard.module.css";

const EV54AlarmsCard = ({ headingsObj, dataObj }) => {
  return (
    <ul className={styles.EV54AlarmsCard__reportList}>
      <li className={styles.EV54AlarmsCard__reportListItem}>
        {dataObj.actions}
        <div className={styles.EV54AlarmsCard__dataContainer}>
          <h1>{headingsObj.description}</h1>
          <p>{dataObj.description}</p>
        </div>
        <div className={styles.EV54AlarmsCard__dataContainer}>
          <h1>{headingsObj.occurred}</h1>
          <p>{dataObj.occurred}</p>
        </div>
      </li>
    </ul>
  );
};

export default EV54AlarmsCard;
