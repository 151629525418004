import React from "react";
import Spacer from "./Spacer";
import checkmarkImage from "../assets/checkmark.svg";

const Checkbox = ({ labelName, labelColor = "", onCheck, isOn }) => {
  const buttonStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20 + "px",
    height: 20 + "px",
    borderRadius: 2 + "px",
    borderColor: "var(--green)",
    borderStyle: "solid",
    borderWidth: 1 + "px",
    backgroundColor: "white",
    cursor: "pointer",
    outline: "none",
  };

  const buttonStyleChecked = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 20 + "px",
    height: 20 + "px",
    borderRadius: 2 + "px",
    borderColor: "var(--green)",
    borderStyle: "solid",
    borderWidth: 1 + "px",
    backgroundColor: "var(--green)",
    cursor: "pointer",
    outline: "none",
  };

  const labelStyle = {
    color: !labelColor ? "var(--blueGray)" : labelColor,
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14 + "px",
    lineHeight: 21 + "px",
  };

  return (
    <div className={"row"}>
      <button
        style={isOn ? buttonStyleChecked : buttonStyle}
        onClick={(e) => {
          // let val = !isChecked;
          // setIsChecked(val);
          // onCheck(val)
          let val = !isOn;
          onCheck(val, e);
        }}
      >
        {/*{isChecked &&*/}
        {/*    <img src={checkmarkImage} alt={'checkbox is checked'}/>*/}
        {/*}*/}
        {isOn && <img src={checkmarkImage} alt={"checkbox is checked"} />}
      </button>
      <Spacer space={10} unit={"px"} />
      <label style={labelStyle}>{labelName}</label>
    </div>
  );
};

export default Checkbox;
