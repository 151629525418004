import * as React from "react";
import { useContext } from "react";
import { ScreenContext } from "../../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import {
  AccumulationChartComponent,
  AccumulationSeriesCollectionDirective,
  AccumulationSeriesDirective,
  AccumulationLegend,
  PieSeries,
  AccumulationDataLabel,
  AccumulationTooltip,
  AccumulationSelection,
  Inject,
  Selection,
  AccumulationAnnotationsDirective,
  AccumulationAnnotationDirective,
  ChartAnnotation,
  AccumulationAnnotation,
  Offset,
} from "@syncfusion/ej2-react-charts";
import { Browser } from "@syncfusion/ej2-base";

const ScriptTypeGraph = ({
  graphData,
  graphId,
  graphTitle,
  palette,
  viewWidth,
}) => {
  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const load = (args) => {
    let selectedTheme = window.location.hash.split("/")[1];
    selectedTheme = selectedTheme ? selectedTheme : "Material";
    args.accumulation.theme = (
      selectedTheme.charAt(0).toUpperCase() + selectedTheme.slice(1)
    )
      .replace(/-dark/i, "Dark")
      .replace(/light/i, "Light")
      .replace(/contrast/i, "Contrast");
  };
  return (
    <div className="control-pane">
      <div className="control-section">
        <AccumulationChartComponent
          titleStyle={{
            size: "16px",
            fontFamily: "Poppins",
            fontWeight: "Normal",
          }}
          title={graphTitle}
          style={
            smScreen
              ? { width: `${viewWidth - 32}px`, height: "288px" }
              : { width: "450px", height: "288px" }
          }
          id={graphId}
          load={load.bind(this)}
          legendSettings={{
            visible: true,
            toggleVisibility: false,
            position: Browser.isDevice ? "Bottom" : "Right",
            height: "43%",
            width: "20%",
            textWrap: "Wrap",
            maximumLabelWidth: 66,
          }}
          enableSmartLabels={true}
          enableAnimation={false}
          selectionMode={"Point"}
          center={{ x: "50%", y: "50%" }}
          enableBorderOnMouseMove={false}
        >
          <Inject
            services={[
              AccumulationLegend,
              PieSeries,
              AccumulationDataLabel,
              AccumulationTooltip,
              AccumulationSelection,
              Selection,
              ChartAnnotation,
              AccumulationAnnotation,
            ]}
          />
          <AccumulationSeriesCollectionDirective>
            <AccumulationSeriesDirective
              palettes={palette}
              dataSource={graphData}
              xName="x"
              yName="y"
              explode={false}
              // explodeOffset="10%"
              explodeIndex={0}
              startAngle={0}
              innerRadius="0%"
              dataLabel={{
                visible: true,
                position: "Inside",
                name: "text",
                font: { fontWeight: "400", color: "#000", size: "14px" },
                connectorStyle: { length: "20px", type: "Curve" },
              }}
              radius="85%"
            />
          </AccumulationSeriesCollectionDirective>
          <AccumulationAnnotationsDirective>
            <AccumulationAnnotationDirective region="Series" x="52%" y="50%" />
          </AccumulationAnnotationsDirective>
        </AccumulationChartComponent>
      </div>
    </div>
  );
};
export default ScriptTypeGraph;
