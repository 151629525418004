import React, { useEffect, useState, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import styles from "./FacilityDispensingOrdersCard.module.css";
import { NavbarContext } from "../contexts/NavbarContext";
import { ReactComponent as Actions } from "../assets/more-actions.svg";
import { ReactComponent as PatientsIcon } from "../assets/people.svg";
import { ReactComponent as OrdersIcon } from "../assets/capsule-and-pill.svg";
import { ReactComponent as WarningIcon } from "../assets/warning-sign.svg";
import { ReactComponent as ClockIcon } from "../assets/clock-icon.svg";
import { ReactComponent as CompleteIcon } from "../assets/complete-icon.svg";
import { ReactComponent as TrayIcon } from "../assets/grid-9.svg";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import { useKeyPress } from "./InputHooks";
import Popup from "./PopupMenu/Popup";
import RoleCheckService from "../services/RoleCheckService";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//region CSS
const ProgressCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 92px;
  box-sizing: border-box;
  padding: 16px;
  border-radius: 4px;
  border-bottom: 4px solid #f2f4f6;
  user-select: none;
  cursor: pointer;
`;
const Progress = styled.div`
  position: absolute;
  left: 0;
  bottom: -4px;
  height: 4px;
  border-radius: 4px;
  border-bottom: 4px solid #089bab;
  user-select: none;
  z-index: 10;
`;
const CardHeader = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;
const CardTitle = styled.h1`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #252631;
`;
const CardBody = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
`;
const CountBox = styled.div`
  width: 57px;
  display: flex;
  align-items: center;
  border-right: 1px solid rgba(151, 151, 151, 0.2);
  margin-right: 20px;
`;
const Count = styled.p`
  margin: 0 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #089bab;
`;
const TimeBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;
const Time = styled.p`
  margin: 0 0 0 6px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #98a9bc;
`;
const ExpandRight = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  justify-content: flex-end;
`;
const CardFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const FooterText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  color: #252631;
`;
// const Popup = styled.div`
//   position: absolute;
//   width: 200px;
//   z-index: 50;
//   background: #FFFFFF;
//   box-shadow: 0 2px 16px rgba(153, 155, 168, 0.34);
//   border-radius: 10px;
//   box-sizing: border-box;
//   padding: 16px;
// `;
// const PopupMenuItem = styled.div`
//   display: flex;
//   align-items: center;
//   width: 100%;
//   height: 32px;
//   cursor: pointer;
//   border-bottom: 1px solid #EBEDF8;
// `;
// const PopupText = styled.p`
//   margin: 0;
//   font-style: normal;
//   font-weight: 300;
//   font-size: 12px;
//   line-height: 32px;
//   color: #121A2D;
//   &:hover {
//     font-weight: 500;
//     color: #089BAB;
//   }
// `;

//endregion

const FacilityDispensingOrdersCard = ({
  dispensingOrder,
  trayDataObj,
  patientCount,
  bagCount,
  alertCount,
  progress,
  dueTime,
  onAction,
  onClickOrder,
  onDoubleClickOrder,
  isSelected,
  onDrag,
  user,
  site,
  trayNeeds,
  onTrayNeeds,
}) => {
  // const notProgressCardEl = useRef();
  const { t } = useTranslation("facilityDispensingOrdersCard");

  const { handleActiveNavbarMenuItemViaContext } = useContext(NavbarContext);

  const navigate = useNavigate();
  const keyPressed = useKeyPress("Escape");
  const [showPopup, setShowPopup] = useState(false);
  const [orderSelected, setOrderSelected] = useState(false);

  const { smallScreen } = useContext(ScreenContext);

  const smScreen = useMediaQuery(smallScreen);

  const trayNeedsExist =
    dispensingOrder.type !== "retail" && trayNeeds && trayNeeds > 0;

  const getQty = () => {
    if (dispensingOrder.type === "retail") {
      return bagCount || 0;
    } else {
      return bagCount || 0;
    }
  };

  useEffect(() => {
    setOrderSelected(isSelected);
  }, [isSelected]);

  useEffect(() => {
    if (keyPressed) {
      setShowPopup(false);
    }
  }, [keyPressed]);

  const handleCardClick = (e) => {
    if (dispensingOrder.state === "pending") {
      orderSelected ? setOrderSelected(false) : setOrderSelected(true);
    }

    onClickOrder(dispensingOrder, e); // use objects!
  };

  const getName = () => {
    if (dispensingOrder.type === "retail") {
      return dispensingOrder.patientInfo.name;
    }
    return dispensingOrder.facility?.name || "Invalid Facility Name";
  };

  const errorExists = () => {
    return dispensingOrder.errorExists;
  };

  const getPendingMenuItems = () => {
    const menuItems = [];
    if (
      RoleCheckService.viewOrderDetails(user) &&
      dispensingOrder.dispOrderErrorDescription === "Multi-Facility Error"
    ) {
      menuItems.push({
        type: "assignFacility",
        description: "Assign Facility",
      });
    }
    if (RoleCheckService.viewOrderDetails(user)) {
      menuItems.push({ type: "review", description: "View Details" });
    }
    if (RoleCheckService.routeOrders(user)) {
      menuItems.push({ type: "sendToDevice", description: "Send to device" });
    }
    if (RoleCheckService.deleteOrders(user)) {
      menuItems.push({ type: "remove", description: "Remove" });
    }
    return menuItems;
  };

  const getApprovedMenuItems = () => {
    const menuItems = [];
    if (RoleCheckService.reRouteOrders(user)) {
      menuItems.push({
        type: "sendToAnotherPharmacy",
        description: "Send to another pharmacy",
      });
    }
    if (RoleCheckService.routeOrders(user)) {
      menuItems.push({ type: "sendToDevice", description: "Send to device" });
    }
    if (RoleCheckService.deleteOrders(user)) {
      menuItems.push({ type: "remove", description: "Remove" });
    }
    return menuItems;
  };

  const getRoutedMenuItems = () => {
    const menuItems = [];
    if (RoleCheckService.canResetAndDeleteOrders(user)) {
      menuItems.push({
        type: "resetToPending",
        description: "Reset to pending",
      });
    }
    return menuItems;
  };

  const getProcessingMenuItems = () => {
    const menuItems = [];
    if (RoleCheckService.canResetAndDeleteOrders(user)) {
      menuItems.push({
        type: "resetToPending",
        description: "Reset to pending",
      });
    }
    return menuItems;
  };

  const getPackagedMenuItems = () => {
    const menuItems = [];
    if (RoleCheckService.canResetAndDeleteOrders(user)) {
      menuItems.push({
        type: "resetToRouted",
        description: "Reset to routed",
      });
    }
    if (RoleCheckService.canResetAndDeleteOrders(user)) {
      menuItems.push({
        type: "resetToPending",
        description: "Reset to pending",
      });
    }
    return menuItems;
  };

  const getPopup = (dispensingState) => {
    switch (dispensingState) {
      case "pending":
        return (
          <Popup
            rightJustify={true}
            menuItems={getPendingMenuItems()}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
      case "approved":
        return (
          <Popup
            rightJustify={true}
            menuItems={getApprovedMenuItems()}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
      case "routed":
        return (
          <Popup
            rightJustify={true}
            menuItems={getRoutedMenuItems()}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
      case "processing":
        return (
          <Popup
            rightJustify={true}
            menuItems={getProcessingMenuItems()}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
      case "packaged":
      case "partially_packaged":
        return (
          <Popup
            rightJustify={true}
            menuItems={getPackagedMenuItems()}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
      default:
        return (
          <Popup
            rightJustify={true}
            menuItems={[{ type: "undefined", description: "Undefined" }]}
            onClose={() => setShowPopup(false)}
            onAction={(menuItem) => {
              setShowPopup(false);
              onAction(menuItem);
            }}
          />
        );
    }
  };

  const handleTrayNeeds = () => {
    onTrayNeeds();
  };

  const handleTrayIconClick = () => {
    handleActiveNavbarMenuItemViaContext(10); // make Devices active
    navigate("/devices", {
      state: {
        site: site,
        device: trayDataObj.dispensingOrder.routedToName,
        order: trayDataObj.dispensingOrder._id,
      },
    });
  };

  if (!progress) {
    return (
      <div
        // ref={notProgressCardEl}
        className={
          alertCount || orderSelected > 0
            ? `${styles.card} ${styles["card--selected"]}`
            : styles.card
        }
        onDoubleClick={(e) => {
          onDoubleClickOrder(dispensingOrder);
        }}
        onClick={(e) => {
          //cardType && cardType === "approved" ? handleApprovedCardClick : null
          handleCardClick(e);
        }}
        draggable={dispensingOrder.state === "pending"}
        onDrag={(e) => {
          if (onDrag) {
            onDrag(e, dispensingOrder);
          }
        }}
      >
        <CardHeader>
          <CardTitle
            style={errorExists() ? { color: "red" } : { color: "#252631" }}
          >
            {getName()}
          </CardTitle>
          <ExpandRight>
            <Actions
              fill={"#778CA2"}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setShowPopup(!showPopup);
              }}
            />
            {showPopup && getPopup(dispensingOrder.state)}
          </ExpandRight>
        </CardHeader>
        <CardBody>
          <CountBox>
            <PatientsIcon fill={"#778CA2"} />
            <Count>{patientCount}</Count>
          </CountBox>
          <CountBox>
            <OrdersIcon fill={"#778CA2"} />
            <Count>{getQty()}</Count>
          </CountBox>
          {alertCount > 0 && (
            <CountBox>
              <WarningIcon fill={alertCount > 0 ? "#FFAB2B" : "#778CA2"} />
              <Count>{alertCount || 0}</Count>
            </CountBox>
          )}
          <TimeBox>
            <ClockIcon fill={"#778CA2"} />
            <Time>{dueTime}</Time>
          </TimeBox>
        </CardBody>
        <CardFooter>
          <FooterText>
            {dispensingOrder.type === "retail"
              ? dispensingOrder.rxNumber
              : dispensingOrder.dispensingOrderId}
          </FooterText>
          <div className={styles.footerIcons}>
            {trayDataObj &&
              !!trayDataObj.trayChecks.length &&
              dispensingOrder.state === "routed" && (
                <div className={styles.trayIconContainer}>
                  <TrayIcon
                    icon={faClipboardList}
                    style={
                      trayNeedsExist
                        ? { cursor: "pointer", marginRight: "5px" }
                        : { cursor: "pointer" }
                    }
                    onClick={() => handleTrayIconClick()}
                  />
                </div>
              )}
            {trayNeedsExist && (
              <FontAwesomeIcon
                icon={faClipboardList}
                color={"#FF0000"}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  handleTrayNeeds();
                }}
              />
            )}
          </div>
        </CardFooter>
      </div>
    );
  } else {
    return (
      <ProgressCard
        draggable={dispensingOrder.state === "pending"}
        onDrag={(e) => {
          if (onDrag) {
            onDrag(e, dispensingOrder);
          }
        }}
        style={
          alertCount || orderSelected > 0
            ? {
                border: "2px solid #FFAB2B",
                backgroundColor: progress === 100 ? "#F4F6F7" : "#ffffff",
              }
            : {
                border: "2px solid #FFFFFF",
                backgroundColor: progress === 100 ? "#F4F6F7" : "#ffffff",
              }
        }
        onDoubleClick={(e) => {
          onDoubleClickOrder(dispensingOrder);
        }}
        onClick={(e) => {
          //cardType && cardType === "approved" ? handleApprovedCardClick : null
          handleCardClick(e);
        }}
      >
        <Progress
          style={{
            width: progress + "%",
            borderColor: alertCount > 0 ? "#FFAB2B" : "#089BAB",
          }}
        />
        <CardHeader>
          <CardTitle
            style={errorExists() ? { color: "red" } : { color: "#252631" }}
          >
            {getName()}
          </CardTitle>
          <ExpandRight>
            <Actions
              fill={"#778CA2"}
              onClick={() => {
                setShowPopup(!showPopup);
              }}
            />
            {showPopup && getPopup(dispensingOrder.state)}
          </ExpandRight>
        </CardHeader>
        <CardBody>
          <CountBox>
            <PatientsIcon fill={"#778CA2"} />
            <Count>{patientCount}</Count>
          </CountBox>
          <CountBox>
            <OrdersIcon fill={"#778CA2"} />
            <Count>{bagCount || 0}</Count>
          </CountBox>
          {alertCount > 0 && progress < 100 && (
            <CountBox>
              <WarningIcon fill={alertCount > 0 ? "#FFAB2B" : "#778CA2"} />
              <Count>{alertCount || 0}</Count>
            </CountBox>
          )}
          {progress === 100 && (
            <CountBox>
              <CompleteIcon fill={"#089BAB"} />
            </CountBox>
          )}
          <TimeBox>
            <ClockIcon fill={"#778CA2"} />
            <Time>{dueTime}</Time>
          </TimeBox>
        </CardBody>
        <CardFooter>
          <FooterText>
            {dispensingOrder.type === "retail"
              ? dispensingOrder.rxNumber
              : dispensingOrder.dispensingOrderId}
          </FooterText>
        </CardFooter>
      </ProgressCard>
    );
  }
};

export default FacilityDispensingOrdersCard;
