import React, {useEffect, useRef, useState} from 'react';
import styles from './DeviceCoverage.module.css';
import { ReactComponent as DeviceIcon } from "../../../assets/Dispenser.svg";
import StockLocationService from "../../../services/StockLocationService";
import DeviceCoverageController from "./DeviceCoverageControler";
import Loader from "react-loader-spinner";

const DeviceCoverage = ({user, site, dispensingOrder, showBlisterPackagers, showPouchPackagers, showVialFillers, onDrop, onClick}) => {

    const mounted = useRef(false);
    const [devices, setDevices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(()=>{
        mounted.current = true;
        setIsLoading(true);
        StockLocationService.getDispensableLocations(user, site)
            .then(stockLocations=>{
                if(mounted.current){
                    const stockLocationIds = []
                    for(let i = 0; i < stockLocations.length; i ++){
                        stockLocationIds.push(stockLocations[i]._id);
                    }
                    StockLocationService.getDeviceCoverages(user, site, stockLocationIds, dispensingOrder).then(coveredDevices=>{
                        setDevices(coveredDevices);
                        setIsLoading(false);
                    }).catch(error=>{
                        setIsLoading(false);
                    })
                }

            })
            .catch(error=>{
                setIsLoading(false);
            })

        return () => {
            mounted.current = false;
        }
    }, [user, site, dispensingOrder])

    if(isLoading){
        return (
            <div className={styles.DeviceCoverage__row}>
                <Loader type="Rings" color="#089BAB" height={50} width={50}/>
            </div>
        )
    }
    return (
        <div className={styles.DeviceCoverage__row}>
            {devices.map(device=>{

                const medCount = device.medCount || 0;
                let loaded = 0;
                let available = 0;
                if(medCount){
                    loaded = device.internalCanisterCount;// ((device.internalCanisterCount / medCount) * 100).toFixed(0);
                    available = device.externalCanisterCount; // (( (device.internalCanisterCount  + device.externalCanisterCount)  / medCount) * 100).toFixed(0);
                }

                if(
                    (device.type.type === "BLISTER_PACKAGER" && showBlisterPackagers && dispensingOrder.facility.packagingMethod.type === 'BLISTER_CARD') ||
                    (device.type.type === "POUCH_PACKAGER" && showPouchPackagers && dispensingOrder.facility.packagingMethod.type === 'POUCH') ||
                    (device.type.type === "VIAL_FILLER" && showVialFillers && dispensingOrder.facility.packagingMethod.type === 'VIAL')
                ) {
                    return (
                        <div
                            key={device._id}
                            className={styles.DeviceCoverage__deviceContainer}
                            onDragOver={e=>{e.stopPropagation(); e.preventDefault();}}
                            onDragEnter={e=>{e.stopPropagation()}}
                            onDrop={(e)=>{
                                onDrop(e, device)
                            }}
                            onClick={()=> {
                                onClick(device)
                            }}
                        >
                            <h1 style={device.machineAlarms.length > 0 ? {color: '#FF0000'}:{}}>{device.barcode}</h1>
                            <DeviceIcon fill={"#3e3f42"}/>
                            <h2><span style={loaded < 1 ? {color: 'red'} : {color: 'green'}}>{loaded} </span>loaded</h2>
                            <h2><span style={available < 1 ? {color: 'red'} : {color: 'green'}}>{available} </span>available</h2>
                        </div>
                    )
                }
            })}
        </div>
    )
}


export default DeviceCoverage;
