import { io } from "socket.io-client";
import DispensingOrderService from "../../../services/DispensingOrderService";
import dispensingOrders from "../DispensingOrders";

class PendingDispensingOrdersController {
  constructor(user) {
    this.user = user;
  }

  getDetails = async (dispensingOrder, site) => {
    return await DispensingOrderService.getDetails(
      this.user,
      site,
      dispensingOrder
    );
  };

  clearError = async (dispensingOrder, site) => {
    return await DispensingOrderService.clearError(
      this.user,
      site,
      dispensingOrder
    );
  };

  approveOrder = async (dispensingOrder) => {
    await DispensingOrderService.resetOrder(
      this.user,
      dispensingOrder.site,
      dispensingOrder,
      "pending"
    );
  };

  rejectOrder = async (dispensingOrder) => {
    await DispensingOrderService.delete(
      this.user,
      dispensingOrder.site,
      dispensingOrder
    );
  };

  productAdded = async (dispensingOrderItem, product) => {
    await DispensingOrderService.addProduct(
      this.user,
      dispensingOrderItem,
      product
    );
  };

  substituteProduct = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.substituteProduct(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateAdminDateTime = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateAdminDateTime(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateOrderPriority = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateOrderPriority(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updateQuantity = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updateQuantity(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  updatePatientLocation = async (
    originalDispensingOrderItem,
    modifiedDispensingOrderItem
  ) => {
    await DispensingOrderService.updatePatientLocation(
      this.user,
      originalDispensingOrderItem,
      modifiedDispensingOrderItem
    );
  };

  splitOrder = async (
    originalDispensingOrder,
    originalDispensingOrderItems,
    splitDispensingOrderItems
  ) => {
    await DispensingOrderService.splitOrder(
      this.user,
      originalDispensingOrder,
      originalDispensingOrderItems,
      splitDispensingOrderItems
    );
  };

  deleteDispensingOrderItem = async (dispensingOrderItem) => {
    return await DispensingOrderService.deleteDispensingOrderItem(
      this.user,
      dispensingOrderItem
    );
  };
}

export default PendingDispensingOrdersController;
