import React from 'react';
import backgroundView from "../../assets/Background.jpg";
import {ReactComponent as CloseIcon} from "../../assets/x.svg";
import picFrame from "../../assets/pic-frame-empty.svg"
import Spacer from "../../components/Spacer";
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useLocation} from 'react-router-dom';

const AccountSetupConfirmationView =(props) => {

    const location = useLocation();
    const { t } = useTranslation('createAccount');

    const account = location.state.account;
    const administrator = location.state.administrator;
    const accountSecurity = location.state.account.accountSecurity;
    const message = t('message');
    const instructions = accountSecurity.type === 'EMAIL' ?
        t('emailInstructions') :
        t('usernameInstructions');

    const containerStyle = {
        backgroundImage: `url(${backgroundView})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'norepeat',
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 0,
        width: 100+'vw',
        height: 100+'vh',
    }

    const viewStyle = {
        backgroundColor: 'white',
        borderRadius: 20+'px',
        width: 633+'px',
        height: 568+'px'
    }

    const headerStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        padding: 40+'px'
    }

    const nameStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 16+'px',
        lineHeight: 30+'px',
        color: '#454B60'
    }

    const companyStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 18+'px',
        lineHeight: 30+'px',
        textAlign: 'center',
        color: '#454B60'
    }

    const messageStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 600,
        fonSize: 22+'px',
        lineHeight: 33+'px',
        textAlign: 'center',
        letterSpacing: 0.15125+'px',
        color: 'var(--green)'
    }

    const instructionsStyle = {
        margin: 0,
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 14+'px',
        lineHeight: 30+'px',
        textAlign: 'center',
        color: '#454B60'
    }

    const bodyStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    }

    const buttonStyle = {
        cursor: 'pointer',
        minHeight: 48+'px',
        width: 213+'px',
        outline: 'none',
        border: 'none',
        borderRadius: 10+'px',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: 14+'px',
        lineHeight: 21+'px',
        textAlign: 'center',
        backgroundColor: 'var(--green)',
        color: 'white'
    }

    return (
        <div style={containerStyle}>
            <div style={viewStyle}>
                <header style={headerStyle}>
                    <CloseIcon fill={'#121A2D'}/>
                </header>
                <section style={bodyStyle}>
                    <img src={picFrame} alt={'pic frame'}/>
                    <Spacer space={20} unit={'px'}/>
                    <p style={nameStyle}>{administrator.firstName} {administrator.lastName}</p>
                    <p style={companyStyle}>{account.companyName}</p>
                    <Spacer space={30} unit={'px'}/>
                    <p style={messageStyle}>{message}</p>
                    <Spacer space={15} unit={'px'}/>
                    <p style={instructionsStyle}>{instructions}</p>
                    <Spacer space={50} unit={'px'}/>
                    <NavLink
                        to={{
                            pathname: '/'
                        }}>
                        <button style={buttonStyle}>{t('continueButtonLabel')}</button>
                    </NavLink>
                </section>
            </div>
        </div>
    )
}

export default AccountSetupConfirmationView;
