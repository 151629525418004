//import LocalStorage from "./LocalStorage";
//import AuthService from "./AuthService";

class ApiService {
  //static token = LocalStorage.getToken();

  static BASE_URL = process.env.REACT_APP_API_HOST ?? "/api";

  static handleResponseError = (response) => {
    if (response?.status === 401) {
      // let authService = new AuthService();
      // authService.signOut();
      // can we redirect from here to sign in?
    } else {
      throw response?.data
        ? response.data.message || response.data
        : response?.statusText || "There was a server error processing request";
    }
  };
}

export default ApiService;
