/* 
Example of usage

<SFDropDownList
  id={"Dashboard2_1"}
  label={"Select Site"}
  defaultValue={
    user?.defaultSite?.name ? user.defaultSite.name : null
  }
  placeHolder={"Select Site"}
  dropDownList={dropDownList}
  onSelection={handleSelectedSite}
/>

where, 
defaultValue="PARK'S PHARMACY"

dropDownList=[
                {Item: "All sites", Value: 1},
                {Item: "Park 2", Value: 2},
                {Item: "PARK'S PHARMACY", Value: 3},
                {Item: "ROB'S SECOND SITE", Value: 4},
                {Item: "ROB'S TEST SITE", Value: 5},
              ]

handleSelectedSite=(siteName, siteValue) => {
    // siteName is the selected Item
    // siteValue is the selected Item's value

    let updatedSelectedSite = sites.find((site) => site?.name === siteName);
    setSelectedSite(updatedSelectedSite ? updatedSelectedSite : null);
}
*/

import React from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import "./SFDropDownList.css";
import styles from "./SFDropDownList.module.css";

const SFDropDownList = ({
  id,
  label,
  defaultValue,
  placeHolder,
  dropDownList,
  onSelection,
}) => {
  // maps the appropriate column to fields property
  const fields = { text: "Item", value: "Value" };
  // instance of DropDownList component
  let dropDownListObj;

  const onSelect = (selectEventObj) => {
    onSelection(
      selectEventObj?.itemData?.Item,
      selectEventObj?.itemData?.Value
    );
  };

  let defaultListObj;

  if (defaultValue) {
    defaultListObj = dropDownList.find((item) => item.Item === defaultValue);
  } else {
    defaultListObj = "";
  }

  return (
    // specifies the tag for render the DropDownList component
    <div className={styles.SFDropDownList__labelContainer}>
      {label && <p className={styles.SFDropDownlist__label}>{label}</p>}
      <DropDownListComponent
        id={id}
        ref={(scope) => {
          dropDownListObj = scope;
        }}
        popupHeight="260px"
        fields={fields}
        dataSource={dropDownList ? dropDownList : []}
        placeholder={placeHolder}
        value={defaultListObj ? defaultListObj.Value : ""}
        select={onSelect}
      />
    </div>
  );
};
export default SFDropDownList;
