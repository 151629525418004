import ProductService from "../services/ProductService";
import StockLocationService from "../services/StockLocationService";

/*
    MODEL: StockLocation

    const stockLocationSchema = new Schema({
    isEnabled: {type: Boolean, default: true},
    isControlled: {type: Boolean, default: false},
    site: {type: Types.ObjectId, ref: 'Site', required: true},
    account: {type: Types.ObjectId, ref: 'Account', required: true},
    parent: {type: Types.ObjectId, ref: 'StockLocation'},
    children: [{type: Types.ObjectId, ref: 'StockLocation'}],
    productAssociations: [{
        product: {type: Types.ObjectId, ref: 'Product'},
        min: {type: Number, default: 0},
        max: {type: Number, default: 0},
    }],
    name: {type: String, required: true},
    barcode: {type: String},
    type: {},
    device: {type: Types.ObjectId, ref: 'Device'},
    orderPriority: {type: String, default: 'Normal'}
});
*/

class StockLocationController {
  constructor(user, site) {
    this.user = user;
    this.site = site;
  }

  /*
        Pass in null to parent if you want all the root StockLocations.  Otherwise, the
        children of parent will be returned.
     */
  async getStockLocations(selectedObj, callback) {
    StockLocationService.list(this.user, this.site)
      .then((stockLocations) => {
        const filteredData = stockLocations.filter((o) => {
          return selectedObj
            ? o.parent
              ? o.parent._id === selectedObj._id
              : false
            : o.parent === null;
        });
        callback(null, filteredData);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  /*
        Make sure you pass in a StockLocation model that conforms to the schema above.  If this is a child,
        make sure to set the parent
     */
  async createStockLocation(stockLocation, callback) {
    StockLocationService.create(this.user, this.site, stockLocation)
      .then((stockLocation) => {
        callback(null, stockLocation);
      })
      .catch((error) => {
        callback(error);
      });
  }

  async updateStockLocation(stockLocation, callback) {
    StockLocationService.update(this.user, this.site, stockLocation)
      .then((stockLocation) => {
        if (callback) {
          callback(null, stockLocation);
        }
      })
      .catch((error) => {
        if (callback) {
          callback(error);
        }
      });
  }

  /*
          Get all products for a site.
          Returns a sorted array of Product (by description)
     */
  async getProducts(callback) {
    try {
      const products = await ProductService.list(this.user);
      callback(
        null,
        products.sort((a, b) => {
          if (a.primaryName < b.primaryName) {
            return -1;
          }
          if (b.primaryName < a.primaryName) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      callback(error, null);
    }
  }

  getStockedItems = async (stockLocation, product) => {
    const temp = await StockLocationService.getStockedItems(
      this.user,
      this.site,
      stockLocation
    );
    return temp.filter((o) => o.product._id === product._id);
  };

  deleteStockLocation = async (stockLocation, cb) => {
    StockLocationService.delete(this.user, this.site, stockLocation)
      .then(() => {
        cb();
      })
      .catch((error) => {
        cb(error);
      });
  };

  getStockLocationsConfigFile = async (user) => {
    return await StockLocationService.getStockLocationsConfigFile(user);
  };
}

export default StockLocationController;
