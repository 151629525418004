import ApiService from "./ApiService";
import axios from "axios";

class AuthService extends ApiService {
  static logIn = async (credentials) => {
    try {
      let response = await axios.post(`${ApiService.BASE_URL}/log-in`, {
        credentials: credentials,
      });
      return response.data.user; // account document
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static logOut = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/users/${user._id}/log-out`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static changePassword = async (user, oldPassword, newPassword) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      await axios.post(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/users/${user._id}/change-password`,
        { oldPassword: oldPassword, newPassword: newPassword },
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static verifyUserToken = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      return await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/users/${user._id}/verify-user-token`,
        rc
      );
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default AuthService;
