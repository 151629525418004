import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./PendingDispensingOrderCard.module.css";

const PendingDispensingOrderCard = ({
  data,
  onClickHandler,
  selectedExternalObject,
}) => {
  // const { t, i18n } = useTranslation("products");
  return (
    <div
      className={
        selectedExternalObject
          ? `${styles.pendingDispensingOrderCard__container} ${styles["pendingDispensingOrderCard__container--selected"]}`
          : styles.pendingDispensingOrderCard__container
      }
      onClick={() => onClickHandler()}
    >
      <div className={styles.pendingDispensingOrderCard__dataList}>
        <div
          className={`${styles.pendingDispensingOrderCard__rowGroup} ${styles["pendingDispensingOrderCard__rowGroup--name"]}`}
        >
          <div
            className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
          >
            <div className={`${styles["pendingDispensingOrderCard__heading"]}`}>
              {data.patientHeading}
            </div>
            <div
              className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value--name"]} ${styles["pendingDispensingOrderCard__value"]}`}
            >
              {data.patient}
            </div>
          </div>
          <div className={styles.pendingDispensingOrderCard__actions}>
            {data.actions}
          </div>
        </div>

        <div
          className={
            !data.error
              ? styles.pendingDispensingOrderCard__rowGroupContainer
              : styles[
                  "pendingDispensingOrderCard__rowGroupContainer--withError"
                ]
          }
        >
          {data.error && (
            <div className={styles.pendingDispensingOrderCard__rowGroup}>
              <div
                className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
              >
                <div
                  className={`${styles["pendingDispensingOrderCard__heading"]}`}
                >
                  {data.errorHeading}
                </div>
                <div
                  className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value"]}`}
                >
                  {data.error}
                </div>
              </div>
            </div>
          )}

          <div className={styles.pendingDispensingOrderCard__rowGroup}>
            <div
              className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
            >
              <div
                className={`${styles["pendingDispensingOrderCard__heading"]}`}
              >
                {data.productHeading}
              </div>
              <div
                className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value"]}`}
              >
                {data.product}
              </div>
            </div>
          </div>

          <div className={styles.pendingDispensingOrderCard__rowGroup}>
            <div
              className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
            >
              <div
                className={`${styles["pendingDispensingOrderCard__heading"]}`}
              >
                {data.rxNumberHeading}
              </div>

              <div className={`${styles["pendingDispensingOrderCard__value"]}`}>
                {`${data.rxNumber} @ ${data.adminTime.slice(0, -3)} on ${
                  data.adminDate
                }`}
              </div>
            </div>
          </div>

          <div className={styles.pendingDispensingOrderCard__rowGroup}>
            <div
              className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
            >
              <div
                className={`${styles["pendingDispensingOrderCard__heading"]}`}
              >
                {data.idHeading}
              </div>

              <div
                className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value"]}`}
              >
                {data.id}
              </div>
            </div>
          </div>
        </div>

        {data.errorName && (
          <div
            className={`${styles.pendingDispensingOrderCard__rowGroup} ${styles["pendingDispensingOrderCard__rowGroup--error"]}`}
          >
            <div
              className={`${styles.pendingDispensingOrderCard__colGroup} $${styles["pendingDispensingOrderCard__colGroup--1"]}`}
            >
              <div
                className={`${styles["pendingDispensingOrderCard__heading"]}`}
              >
                {data.errorHeading}
              </div>
              <div
                className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value--error"]} ${styles["pendingDispensingOrderCard__value"]}`}
              >
                {data.error}
              </div>
            </div>
          </div>
        )}

        <div className={styles.pendingDispensingOrderCard__rowGroup}>
          <div
            className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
          >
            <div className={`${styles["pendingDispensingOrderCard__heading"]}`}>
              {data.facilityHeading}
            </div>
            <div className={`${styles["pendingDispensingOrderCard__value"]}`}>
              {data.facility}
            </div>
          </div>

          <div
            className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--2"]}`}
          >
            <div className={`${styles["pendingDispensingOrderCard__heading"]}`}>
              {data.locationHeading}
            </div>
            <div
              className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value--alignRight"]}`}
            >
              {data.location}
            </div>
          </div>
        </div>

        <div
          className={`${styles.pendingDispensingOrderCard__rowGroup} ${styles["pendingDispensingOrderCard__rowGroup--noBorder"]}`}
        >
          <div
            className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--1"]}`}
          >
            <div className={`${styles["pendingDispensingOrderCard__heading"]}`}>
              {data.priorityHeading}
            </div>
            <div className={`${styles["pendingDispensingOrderCard__value"]}`}>
              {data.priority}
            </div>
          </div>

          <div
            className={`${styles.pendingDispensingOrderCard__colGroup} ${styles["pendingDispensingOrderCard__colGroup--2"]}`}
          >
            <div className={`${styles["pendingDispensingOrderCard__heading"]}`}>
              {data.quantityHeading}
            </div>
            <div
              className={`${styles["pendingDispensingOrderCard__value"]} ${styles["pendingDispensingOrderCard__value--alignRight"]}`}
            >
              {data.quantity}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingDispensingOrderCard;
