import React, { useEffect, useState, useContext, Fragment } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../components/Button";
import backIcon from "../../assets/back_icon.svg";
import { ReactComponent as ExcelIcon } from "../../assets/excel.svg";
import { ReactComponent as PDFIcon } from "../../assets/pdf.svg";
import { ReactComponent as Funnel } from "../../assets/funnel.svg";
import { ReactComponent as ExpandIcon } from "../../assets/add-icon-small.svg";
import { ReactComponent as CollapseIcon } from "../../assets/subtract-circle-icon.svg";
import { ReactComponent as GroupIcon } from "../../assets/brackets-curly.svg";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import Spacer from "../../components/Spacer";
import WaitIndicator from "../../components/WaitIndicator";
import ReportService from "../../services/ReportService";
import ErrorBanner from "../../components/ErrorBanner";
import FilterPopup from "../../components/FilterPopup";
import { useKeyPress } from "../../components/InputHooks";
import PDFReportViewer from "./PDFReportViewer";
import ReportExcelExporter from "./ReportExcelExporter";
import { getPreferredFieldName } from "./lookups/getPreferredFieldName";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import ProductionReportCardList from "./MobileReportViews/ProductionReport/ProductionReportCardList";
import SummaryBagReport from "./CustomizedReports/SummaryBagReport/SummaryBagReport";
import {
  solveFunction,
  getColumnAverages,
  getColumnSums,
  getDataFields,
} from "./reportViewerFunctions";
import styles from "./ReportViewer.module.css";

const ReportViewer = ({
  user,
  reportObj,
  isPreview,
  onClose,
  customizedReports,
  collectionFieldDictionary,
}) => {
  const { t } = useTranslation("reportViewer");

  const { smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [tableWidthWithScroll, setTableWidthWithScroll] = useState("100%");
  const [reportContainerHeight, setReportContainerHeight] = useState("100%");
  const [selectionRange, setSelectionRange] = useState({});
  const [showDateTimeRangePicker, setShowDateTimeRangePicker] = useState(false);
  const [fetchingData, setFetchingData] = useState(false);
  const [error, setError] = useState("");
  const [numSelectedRows, setNumSelectedRows] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]); // a selected row (array element) is assigned a value of 1; 0 if not selected.

  const [reportData, setReportData] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showFilter, setShowFilter] = useState(null);
  const [filterList, setFilterList] = useState([]);
  const [sortPreference, setSortPreference] = useState({
    colIndex: 0,
    ascending: true,
  });
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [exportToExcel, setExportToExcel] = useState(false);
  const [columnWidths, setColumnWidths] = useState({});
  const [columnSums, setColumnSums] = useState([]);
  const [columnAverages, setColumnAverages] = useState([]);
  const [functionNames, setFunctionNames] = useState([]);
  const [orderedFunctionNames, setOrderedFunctionNames] = useState([]);
  const [functionLookup, setFunctionLookup] = useState({});
  const [functionLookupComplete, setFunctionLookupComplete] = useState(false);
  const [showAVGRow, setShowAVGRow] = useState(false);
  const [showSUMRow, setShowSUMRow] = useState(false);
  const [runReport, setRunReport] = useState(false);
  const [collectionSequence, setCollectionSequence] = useState([]);
  const [largeViewData, setLargeViewData] = useState([]);
  const [groupedData, setGroupedData] = useState([]);
  const [groupedDataColumnSums, setGroupedDataColumnSums] = useState([]);
  const [columnDataTypes, setColumnDataTypes] = useState([]);
  const [selectedGroupRowIndex, setSelectedGroupRowIndex] = useState(-1);
  const [expansionActive, setExpansionActive] = useState(false);
  const [groupedField, setGroupedField] = useState("No grouping");
  const [activeGroupIconIndex, setActiveGroupIconIndex] = useState(null);
  const [showPDFAndXLSHeaderIcons, setShowPDFAndXLSHeaderIcons] =
    useState(true);
  const [showGroupHeaderButtons, setShowGroupHeaderButtons] = useState(true);
  const [fetchingCustomReportData, setFetchingCustomReportData] =
    useState(false);

  const addedWidthAdjustment = 34;

  const keyPressed = useKeyPress("Escape");
  if (showFilter && keyPressed) {
    setShowFilter(null);
  }

  const combinedReportFieldsRegular = [
    ...reportObj.fields,
    ...reportObj.functionFields,
  ];

  let combinedReportFields = combinedReportFieldsRegular;

  useEffect(() => {
    let tempGroupedColumnSums = [];
    groupedData.forEach((groupedRows, groupIndex) => {
      tempGroupedColumnSums.push([]);
      tempGroupedColumnSums[groupIndex].push([]);
      groupedRows.forEach(
        (tempGroupedColumnSumsRow, tempGroupedColumnSumsRowIndex) => {
          tempGroupedColumnSumsRow.forEach((datum, datumIndex) => {
            if (columnDataTypes[datumIndex] === "Number") {
              if (tempGroupedColumnSumsRowIndex === 0) {
                if (isNaN(datum)) {
                  tempGroupedColumnSums[groupIndex][datumIndex] = 0;
                } else {
                  tempGroupedColumnSums[groupIndex][datumIndex] = datum;
                }
              } else if (!isNaN(datum) && datum !== "") {
                tempGroupedColumnSums[groupIndex][datumIndex] += datum;
              }
            } else {
              if (tempGroupedColumnSumsRowIndex === 0) {
                tempGroupedColumnSums[groupIndex][datumIndex] = null;
              }
            }
          });
        }
      );
    });
    setGroupedDataColumnSums(tempGroupedColumnSums);
  }, [groupedData]);

  useEffect(() => {
    setReportContainerHeight(document.body.clientHeight - 320);
    let onresize = function () {
      setReportContainerHeight(document.body.clientHeight - 320);
    };
    window.addEventListener("resize", onresize);
    return () => window.removeEventListener("resize", onresize);
  }, []);

  useEffect(() => {
    if (!smScreen) {
      // Use a setTimeout to make sure the table width is updated before getting its width.
      setTimeout(() => {
        let element = document.querySelector("#tableContainer");
        let widthWithoutScroll = element?.offsetWidth;
        let widthWithScroll = element?.scrollWidth;
        setTableWidthWithScroll(
          widthWithScroll > widthWithoutScroll ? widthWithScroll : "100%"
        );
      }, "1000");
    }
  }, [reportData, smScreen]);

  /**************************  custom *****************************/
  useEffect(() => {
    if (
      reportObj?.customizedReport &&
      reportObj.customizedReport === "Summary Bag Report"
    ) {
      setShowPDFAndXLSHeaderIcons(false);
      setShowGroupHeaderButtons(false);
    }
  }, [reportObj]);
  /****************************************************************/

  useEffect(() => {
    if (isPreview && reportObj?.ignoreDateRange) {
      handleRunReport();
    }
  }, [isPreview, reportObj]);

  useEffect(() => {
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });

    const collections = reportObj.fields.map((field) => {
      return field.collection;
    });

    setCollectionSequence(collections);
  }, [reportObj]);

  useEffect(() => {
    if (reportObj?.groupedField && reportObj.groupedField !== "No grouping") {
      const groupedFieldIndex = reportObj.fields.findIndex((field) => {
        return `${field.collection}:${field.name}` === reportObj.groupedField;
      });
      if (groupedFieldIndex !== -1) {
        setActiveGroupIconIndex(groupedFieldIndex);
      }
    }
  }, []);

  // determine the maximum width of each column
  useEffect(() => {
    let maximumWidth = 40; // characters - not pixels
    let widths = [];
    for (let i = 0; i < columnFilters.length; i++) {
      let colFilter = columnFilters[i];
      let maxWidth =
        combinedReportFields[i]?.name?.length || combinedReportFields[i].length;
      for (let j = 0; j < colFilter.length; j++) {
        let d = colFilter[j];
        if (d.description.length > maxWidth) {
          maxWidth = d.description.length;
          if (maxWidth > maximumWidth) {
            maxWidth = maximumWidth;
          }
        }
      }
      if (maxWidth > maximumWidth) {
        maxWidth = maximumWidth;
      }
      widths.push(maxWidth * 10 + 12);
    }
    setColumnWidths(widths);
  }, [columnFilters]);

  useEffect(() => {
    let rowsSelected = 0;
    let tempSelectedRows = [];

    for (let i = 0; i < reportData.length; i++) {
      tempSelectedRows[i] = 0;
    }

    for (let row = 0; row < columnFilters.length; row++) {
      let rowSelected = false;
      columnFilters[row].forEach((columnFilter, columnFilterIndex) => {
        if (columnFilter.selected) {
          if (!rowSelected) {
            rowsSelected++;
          }
          tempSelectedRows[columnFilterIndex] = 1;
        }
      });

      if (rowsSelected === 0) {
        setNumSelectedRows(reportData.length);
      } else {
        setNumSelectedRows(rowsSelected);
      }
    }

    if (rowsSelected === 0) {
      tempSelectedRows.forEach((element, index) => {
        tempSelectedRows[index] = 1;
      });
    }

    setSelectedRows(tempSelectedRows);
  }, [columnFilters]);

  useEffect(() => {
    if (
      (functionLookupComplete || orderedFunctionNames.length === 0) &&
      reportData
    ) {
      getDataFields(
        combinedReportFields,
        reportObj,
        reportData,
        formatDate,
        functionLookup,
        handleColumnFilters
      );
    }
  }, [functionLookupComplete, reportData]);

  useEffect(() => {
    let tempFunctionNames = [];
    combinedReportFields.forEach((field) => {
      if (field.collection === "function") {
        tempFunctionNames.push(field.name);
      }
    });
    setFunctionNames(tempFunctionNames);
  }, []);

  // Creates and sets columnSums (state) and ColumnAverages (state)
  useEffect(() => {
    if (numSelectedRows > 0) {
      setColumnSums(
        getColumnSums(
          combinedReportFields,
          reportData,
          functionLookup,
          selectedRows,
          reportObj
        )
      );
      setColumnAverages(
        getColumnAverages(
          combinedReportFields,
          reportData,
          functionLookup,
          selectedRows,
          numSelectedRows,
          reportObj
        )
      );
    }
  }, [reportData, selectedRows, functionLookup, numSelectedRows, reportObj]);

  useEffect(() => {
    if (columnFilters.length > 0 && columnAverages.length > 0) {
      let show = false;

      for (let i = 0; i < columnAverages.length; i++) {
        if (columnAverages[i] >= 0) {
          show = true;
          break;
        }
      }
      setShowAVGRow(show);
    }
  }, [columnFilters, columnAverages]);

  useEffect(() => {
    if (columnFilters.length > 0 && columnSums.length > 0) {
      let show = false;

      for (let i = 0; i < columnSums.length; i++) {
        if (columnSums[i] >= 0) {
          show = true;
          break;
        }
      }
      setShowSUMRow(show);
    }
  }, [columnFilters, columnSums]);

  // Assigns to functionLookup a key which cooresponds to each function name and intializes each value to an empty array ([])
  useEffect(() => {
    if (functionNames.length > 0) {
      let functionsHolder = [...reportObj.functionFields];
      let tempOrderedFunctionNames = [];

      while (
        tempOrderedFunctionNames.length < reportObj.functionFields.length
      ) {
        for (let i = 0; i < functionsHolder.length; i++) {
          let place = false;
          reportObj.functionFields.forEach((field) => {
            if (
              !tempOrderedFunctionNames.includes(field.name) &&
              (tempOrderedFunctionNames.includes(field.operands[0].name) ||
                !functionNames.includes(field.operands[0].name)) &&
              (tempOrderedFunctionNames.includes(field.operands[1].name) ||
                !functionNames.includes(field.operands[1].name))
            ) {
              place = true;
            }
            if (place) {
              tempOrderedFunctionNames.push(field.name);
              place = false;
              setOrderedFunctionNames(tempOrderedFunctionNames);
            }
          });
        }
      }
      let tempFunctionLookup = {};
      tempOrderedFunctionNames.forEach((name) => {
        tempFunctionLookup[name] = [];
      });
      setFunctionLookup(tempFunctionLookup);
    }
  }, [functionNames]);

  useEffect(() => {
    // Assigns a value to each key in functionLookup and subsequently sets functionLookupComplete to true
    if (
      !functionLookupComplete &&
      Object.keys(functionLookup).length > 0 &&
      reportData.length > 0
    ) {
      let tempFunctionLookup;
      reportData.forEach((rowData, rowDataIndex) => {
        orderedFunctionNames.forEach((functionName, functionIndex) => {
          let fieldIndex = reportObj.functionFields.findIndex(
            (o) => o.name === functionName
          );
          let dataFieldText = solveFunction(
            reportObj.functionFields[fieldIndex],
            rowData,
            combinedReportFields,
            rowDataIndex,
            combinedReportFields,
            getPathValue,
            functionLookup
          );
          tempFunctionLookup = { ...functionLookup };
          tempFunctionLookup[functionName][rowDataIndex] = dataFieldText;
        });
      });
      setFunctionLookupComplete(true);
      setFunctionLookup(tempFunctionLookup);
    }
  }, [functionLookup, reportData]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsidePopupZone);

    return () => {
      document.removeEventListener("click", handleClickOutsidePopupZone);
    };
  });

  useEffect(() => {
    if (reportObj?.groupedField) {
      setGroupedField(reportObj.groupedField);
    }
  }, [reportObj]);

  useEffect(() => {
    {
      if (columnFilters && columnFilters.length && reportData) {
        const filteredLargeViewData = reportData
          .map((data, dataIndex) => {
            if (isFiltered(dataIndex)) {
              return combinedReportFields.map((reportObject, index) => {
                let dataFieldText =
                  columnFilters[index][dataIndex]?.description;
                return dataFieldText;
              });
            }
          })
          // strip any undefined entries from the array
          .filter((data) => data);
        setLargeViewData(filteredLargeViewData);
      }
    }
  }, [columnFilters, reportData]);

  useEffect(() => {
    if (largeViewData.length) {
      // const groupedField = reportObj.groupedField;

      const columnIndex = reportObj.fields.findIndex((fieldObject) => {
        return `${fieldObject.collection}:${fieldObject.name}` === groupedField;
      });

      let searched = [];
      let tempGroupedData = [];
      let tempGroupedDataIndex = 0;
      if (largeViewData && largeViewData?.length && largeViewData) {
        largeViewData.forEach((dataRowFirstLevel) => {
          if (
            dataRowFirstLevel &&
            columnIndex >= 0 &&
            !searched.includes(dataRowFirstLevel[columnIndex])
          ) {
            searched.push(dataRowFirstLevel[columnIndex]);
            largeViewData.forEach((dataRowSecondLevel) => {
              if (
                dataRowSecondLevel &&
                dataRowFirstLevel[columnIndex] ===
                  dataRowSecondLevel[columnIndex]
              ) {
                if (tempGroupedData.length - 1 >= tempGroupedDataIndex) {
                  tempGroupedData[tempGroupedDataIndex].push(
                    dataRowSecondLevel
                  );
                } else {
                  tempGroupedData[tempGroupedDataIndex] = [dataRowSecondLevel];
                }
              }
            });
            tempGroupedDataIndex++;
          }
        });
        setGroupedData(tempGroupedData);
      }
    }
  }, [largeViewData, groupedField]);

  let customReport;

  const customReportIndex = customizedReports.findIndex(
    (customReport) => customReport === reportObj.customizedReport
  );

  if (customReportIndex !== -1) {
    customReport = customizedReports[customReportIndex];
  }

  const handleClickOutsidePopupZone = () => {
    // Automatically closes all popups if a click is made outside of the popup zone.
    setShowFilter(null);
  };

  const handleClickInsidePopupZone = (event) => {
    // Stops the mouse-click event from bubbling up. If a click is made inside of the popup zone, this prevents a document click from calling handleClickOutsidePopupZone.
    event.stopPropagation();
  };

  const handleDateRangeSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  const handleFetchingCustomReportData = (boolVal) => {
    setFetchingCustomReportData(boolVal);
  };

  const handleRunReport = () => {
    setFetchingData(true);
    /**************************  custom *****************************/
    if (
      reportObj?.customizedReport &&
      reportObj.customizedReport === "Summary Bag Report"
    ) {
      handleFetchingCustomReportData(true);
    }
    /****************************************************************/

    const query = {
      startDate: selectionRange.startDate,
      stopDate: selectionRange.endDate,
      isPreview: isPreview,
      reportObj: reportObj,
    };

    // Get report data
    ReportService.run2(user, query)
      .then((results1) => {
        setReportData(results1);
        setRunReport(true);
        setFetchingData(false);
      })
      .catch((error) => {
        setError(error);
        setFetchingData(false);
      });
  };

  const handleColumnFilters = (val) => {
    setColumnFilters(val);
  };

  const formatDate = (item) => {
    if (item) {
      const date = new Date(item);
      return Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      }).format(date);
      //return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
    }
    return "";
  };

  const getPathValue = (path, data) => {
    let val = "";
    const parts = path.split(".");
    for (let i = 0; i < parts.length; i++) {
      if (!val) {
        val = data[`${parts[i]}`];
      } else {
        val = val[`${parts[i]}`];
      }
    }
    return val;
  };

  const handleFilterClick = (e, column, width) => {
    const list = columnFilters[column];
    if (list && list.length) {
      setFilterList(list);
      var rect = e.target.getBoundingClientRect();
      setShowFilter(
        showFilter
          ? null
          : { left: e.clientX, topaverage: e.clientY + rect.height * 2 }
      );
    }
  };

  const isFiltered = (dataIndex) => {
    // isFiltered return true if there are no selected filteres or a selected filter matches
    // this data element - look in each column
    let j = 0;
    for (let col = 0; col < combinedReportFields.length; col++) {
      let columnFilter = columnFilters[col];
      let description = columnFilter[dataIndex]?.description || "";
      if (
        columnFilter.some((o) => o.selected) &&
        !columnFilter.some((o) => o.description === description && o.selected)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleGroupClick = (index) => {
    const fieldToGroup = `${reportObj.fields[index].collection}:${reportObj.fields[index].name}`;
    setGroupedField(fieldToGroup);
    setExpansionActive(false);
    setSelectedGroupRowIndex(-1);
    setActiveGroupIconIndex(index);
  };

  const handleResetFieldGroupingToDefault = () => {
    if (reportObj?.groupedField) {
      setGroupedField(reportObj.groupedField);
      setExpansionActive(false);
      setSelectedGroupRowIndex(-1);
      if (reportObj?.groupedField && reportObj.groupedField !== "No grouping") {
        const groupedFieldIndex = reportObj.fields.findIndex((field) => {
          return `${field.collection}:${field.name}` === reportObj.groupedField;
        });
        if (groupedFieldIndex !== -1) {
          setActiveGroupIconIndex(groupedFieldIndex);
        }
      } else {
        setActiveGroupIconIndex(null);
      }
    } else {
      setActiveGroupIconIndex(null);
    }
  };

  const handleNoFieldGrouping = () => {
    setGroupedField("No grouping");
    setActiveGroupIconIndex(null);
    setExpansionActive(false);
    setSelectedGroupRowIndex(-1);
  };

  const getColumnSumAndAverage = (col) => {
    let sumAndAverageObject = { sum: "", average: "" };
    let sum = 0;
    let columnFilter = columnFilters[col];
    for (let i = 0; i < columnFilter?.length; i++) {
      if (isFiltered(i)) {
        let val = columnFilter[i].description;
        sum += Number(val);
      }
    }
    let average = sum / numSelectedRows;
    sumAndAverageObject.sum = sum;
    sumAndAverageObject.average = average;
    return { ...sumAndAverageObject };
  };

  const getFunnelColor = (col) => {
    let columnFilter = columnFilters[col];
    if (columnFilter && columnFilter.some((o) => o.selected)) {
      return "#089BAB";
    }
    return "#778CA2";
  };

  const toggleSortOrder = (colIndex) => {
    let pref = { ...sortPreference };
    if (colIndex === pref.colIndex) {
      pref.ascending = !pref.ascending;
    } else {
      pref.colIndex = colIndex;
      pref.ascending = false;
    }
    setSortPreference(pref);
    sortData(pref);
  };

  const sortData = (pref) => {
    let rows = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = [];
      for (let colIndex = 0; colIndex < columnFilters.length; colIndex++) {
        row.push(columnFilters[colIndex][i]);
      }
      rows.push(row);
    }
    rows.sort((a, b) => {
      if (a[pref.colIndex].description < b[pref.colIndex].description) {
        return pref.ascending ? -1 : 1;
      }
      if (b[pref.colIndex].description < a[pref.colIndex].description) {
        return pref.ascending ? 1 : -1;
      }
      return 0;
    });
    transposeData(rows);
  };

  const transposeData = (rows) => {
    let cols = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = rows[i];
      for (let rowIndex = 0; rowIndex < row.length; rowIndex++) {
        if (cols[rowIndex]) {
          cols[rowIndex] = cols[rowIndex].concat(row[rowIndex]);
        } else {
          cols.push([row[rowIndex]]);
        }
      }
    }
    setColumnFilters(cols);
  };

  // const getActionMenu = () => {
  //   return [...actionListItems];
  // };

  // const handleAction = (action) => {
  //   switch (action.type) {
  //     case "share":
  //       break;
  //     case "excel":
  //       setExportToExcel(true);
  //       break;
  //     case "pdf":
  //       setShowPDFViewer(true);
  //       break;
  //     case "stockLocations":
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const NormalTableHeadings = (obj, width, index) => {
    const increasedWidth = width + addedWidthAdjustment;
    return (
      <div
        className={styles.ReportViewer__reportHeaderGroup}
        key={index}
        style={{
          minWidth: `${increasedWidth}px`,
          maxWidth: `${increasedWidth}px`,
          minHeight: "20px",
        }}
      >
        <p
          className={styles.ReportViewer__reportHeaderText}
          onClick={() => toggleSortOrder(index)}
        >
          {index <= reportObj.fields.length - 1 ? (
            getPreferredFieldName(
              obj.collection,
              obj.name,
              collectionFieldDictionary,
              false,
              false
            )
          ) : (
            <span className={styles.ReportViewer__tooltip}>
              {obj.name}
              <span className={styles.ReportViewer__tooltiptext}>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {getPreferredFieldName(
                    obj.operands[0].collection,
                    obj.operands[0].name,
                    collectionFieldDictionary,
                    false,
                    false
                  )}
                </span>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {obj.operator.description}
                </span>
                <span className={styles.ReportViewer__tooltipSpan}>
                  {getPreferredFieldName(
                    obj.operands[1].collection,
                    obj.operands[1].name,
                    collectionFieldDictionary,
                    false,
                    false
                  )}
                </span>
              </span>
            </span>
          )}
        </p>
        <div
          onClick={(event) => handleClickInsidePopupZone(event)}
          style={{ cursor: "pointer" }}
        >
          <Funnel
            fill={getFunnelColor(index)}
            onMouseOver={(e) => {
              e.target.fill = "#089BAB";
            }}
            onClick={(e) =>
              handleFilterClick(e, index, 1000 / combinedReportFields.length)
            }
          />
        </div>
        <div
          className={
            activeGroupIconIndex === index
              ? `${styles.ReportViewer__groupIcon} ${styles["ReportViewer__groupIcon--active"]}`
              : `${styles.ReportViewer__groupIcon}`
          }
          onClick={() => handleGroupClick(index)}
        >
          <GroupIcon />
        </div>
        {showFilter && (
          <div onClick={(event) => handleClickInsidePopupZone(event)}>
            <FilterPopup
              list={filterList}
              left={showFilter.left}
              top={showFilter.top}
              onSelect={(item) => {
                let tempColumnFilters = [...columnFilters];
                let tempFilter = tempColumnFilters[item.index];
                let obj = tempFilter.find(
                  (o) => o.description === item.description
                );
                obj.selected = item.selected;
                setColumnFilters(tempColumnFilters);
              }}
            />
          </div>
        )}
      </div>
    );
  };

  let tempColumnDataTypes = [];

  const TableHeadings = combinedReportFields.map((obj, index) => {
    tempColumnDataTypes[index] = obj.dataType;
    let width = columnWidths[index];
    if (index === combinedReportFields.length - 1 && !columnDataTypes.length) {
      setColumnDataTypes(tempColumnDataTypes);
    }
    return NormalTableHeadings(obj, width, index);
  });

  const getViewerData = () => {
    let columnHeaders = [];
    for (let colIndex = 0; colIndex < combinedReportFields.length; colIndex++) {
      if (!columnHeaders[colIndex]) {
        columnHeaders.push(combinedReportFields[colIndex].name);
      }
    }

    let data = [];
    for (let i = 0; i < reportData.length; i++) {
      let row = [];
      let include = true;
      for (let colIndex = 0; colIndex < columnFilters.length; colIndex++) {
        if (isFiltered(i)) {
          row.push(columnFilters[colIndex][i].description);
        } else {
          include = false;
        }
      }
      if (include) {
        data.push(row);
      }
    }
    return { data: data, headers: columnHeaders };
  };

  if (showPDFViewer && !customReport) {
    if (!reportData) {
      setShowPDFViewer(false);
      return;
    }

    let obj = getViewerData();

    return (
      <PDFReportViewer
        title={reportObj.title}
        headers={obj.headers}
        groupedField={groupedField}
        data={groupedField === "No grouping" ? largeViewData : groupedData}
        collectionSequence={collectionSequence}
        onBack={() => setShowPDFViewer(false)}
        columnSums={columnSums}
        columnAverages={columnAverages}
        selectionRange={selectionRange}
        showAVGRow={showAVGRow}
        showSUMRow={showSUMRow}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  } else if (exportToExcel) {
    if (!reportData) {
      setExportToExcel(false);
      return;
    }
    let obj = getViewerData();
    return (
      <ReportExcelExporter
        title={reportObj.title}
        headers={obj.headers}
        groupedField={groupedField}
        data={groupedField === "No grouping" ? largeViewData : groupedData}
        collectionSequence={collectionSequence}
        onBack={() => setExportToExcel(false)}
        columnSums={columnSums}
        columnAverages={columnAverages}
        selectionRange={selectionRange}
        collectionFieldDictionary={collectionFieldDictionary}
      />
    );
  } else if (fetchingData) {
    // return <WaitIndicator message={"Generating report.  Please be patient."} />;
  }

  const handleGroupSelection = (rowIndex) => {
    if (selectedGroupRowIndex === rowIndex && expansionActive) {
      setExpansionActive(false);
      setSelectedGroupRowIndex(-1);
    } else {
      setExpansionActive(true);
      setSelectedGroupRowIndex(rowIndex);
    }
  };

  const handleShowPDFViewer = (boolVal) => {
    setShowPDFViewer(boolVal);
  };

  const handleResetSelectionDateRange = () => {
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
  };

  // Browswer view
  const LargeView = (
    <div
      className={
        !showPDFViewer
          ? styles.ReportViewer__main
          : styles.ReportViewer__mainWithCustomPDF
      }
    >
      {showDateTimeRangePicker && (
        <div className={styles.ReportViewer__overlay}>
          <div className={styles.ReportViewer__modal}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleDateRangeSelect}
              rangeColors={["#089BAB"]}
            />
            <br />
            <Button
              labelName={"Continue"}
              isPrimary={true}
              onClick={() => setShowDateTimeRangePicker(false)}
            />
          </div>
        </div>
      )}

      {error && (
        <div className={styles.ReportViewer__row}>
          <ErrorBanner message={error} onClose={() => setError(null)} />
          <Spacer space={10} unit={"px"} />
        </div>
      )}

      {fetchingData && (
        <WaitIndicator message={"Generating report.  Please be patient."} />
      )}

      {!showPDFViewer && (
        <div className={styles.ReportViewer__header}>
          <div className={styles.ReportViewer__titleGroup}>
            <div className={styles.ReportViewer__backButton}>
              <img src={backIcon} onClick={onClose} />
            </div>
            <p className={styles.ReportViewer__reportTitle}>
              {reportObj.title}
            </p>
            {showGroupHeaderButtons && (
              <Fragment>
                <button
                  className={styles.ReportViewer__resetButtons}
                  onClick={handleResetFieldGroupingToDefault}
                >
                  Default
                </button>
                <button
                  className={styles.ReportViewer__resetButtons}
                  onClick={handleNoFieldGrouping}
                >
                  No Groups
                </button>
              </Fragment>
            )}
          </div>
          {!reportObj.ignoreDateRange && (
            <div className={styles.ReportViewer__dateRangeGroup}>
              <p className={styles.ReportViewer__dateRangeLabel}>
                {t("dateFilterLabel")}
              </p>
              <p
                className={styles.ReportViewer__dateRange}
                onClick={() => setShowDateTimeRangePicker(true)}
              >
                {selectionRange.startDate?.toLocaleDateString() || ""}-
                {selectionRange.endDate?.toLocaleDateString() || ""}
              </p>
            </div>
          )}
          <div className={styles.ReportViewer__buttonGroup}>
            {runReport &&
              reportObj?.fields?.length &&
              showPDFAndXLSHeaderIcons && (
                <div className={styles.ReportViewer__fileIconContainer}>
                  <PDFIcon
                    className={styles.ReportViewer__fileIcon}
                    onClick={() => setShowPDFViewer(true)}
                  />
                </div>
              )}
            {runReport &&
              reportObj?.fields?.length &&
              showPDFAndXLSHeaderIcons && (
                <div className={styles.ReportViewer__fileIconContainer}>
                  <ExcelIcon
                    className={styles.ReportViewer__fileIcon}
                    onClick={() => setExportToExcel(true)}
                  />
                </div>
              )}
            <div className={styles.ReportViewer__runReportButtonContainer}>
              {(!isPreview || !reportObj.ignoreDateRange) && (
                <Button
                  labelName={t("runReportLabel")}
                  minWidth={"123px"}
                  isPrimary={true}
                  onClick={handleRunReport}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {/***************************  customized report *****************************/}
      {reportObj?.customizedReport &&
        reportObj.customizedReport === "Summary Bag Report" && (
          <SummaryBagReport
            reportObj={reportObj}
            reportData={reportData}
            selectionRange={selectionRange}
            onResetSelectionDateRange={handleResetSelectionDateRange}
            onShowPDFViewer={handleShowPDFViewer}
            fetchingCustomReportData={fetchingCustomReportData}
            onFetchingCustomReportData={handleFetchingCustomReportData}
          />
        )}
      {/***************************  customized report *****************************/}

      {!customReport && (
        <div
          id="tableContainer"
          className={styles.ReportViewer__tableContainer}
        >
          <div
            style={{ width: tableWidthWithScroll }}
            className={
              !groupedField || groupedField === "No grouping"
                ? styles.ReportViewer__reportHeader
                : `${styles.ReportViewer__reportHeader} ${styles["ReportViewer__reportHeader--grouping"]}`
            }
          >
            {TableHeadings}
          </div>
          <div
            style={{
              width: tableWidthWithScroll,
              height: reportContainerHeight,
            }}
            className={styles.ReportViewer__reportDataScrollView}
          >
            {(!groupedField || groupedField === "No grouping") &&
              columnFilters &&
              columnFilters.length > 0 &&
              reportData &&
              largeViewData.map((dataRow, index) => {
                return (
                  <div
                    key={index}
                    className={styles.ReportViewer__reportDataContainer1}
                  >
                    <div className={styles.ReportViewer__reportDataContainer2}>
                      {dataRow.map((datum, datumIndex) => {
                        let displayDatum = datum;
                        if (typeof datum === "string") {
                          displayDatum = datum.substring(0, 750);
                          if (datum.length > 750) {
                            displayDatum += "...";
                          }
                        }
                        let width = columnWidths[datumIndex];
                        return (
                          <div
                            key={datumIndex}
                            className={styles.ReportViewer__reportDataText}
                            style={{
                              minWidth: width
                                ? `${width + addedWidthAdjustment}px`
                                : "",
                              maxWidth: width
                                ? `${width + addedWidthAdjustment}px`
                                : "",
                            }}
                          >
                            {displayDatum}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            {groupedField !== "No grouping" &&
              columnFilters &&
              columnFilters.length > 0 &&
              reportData &&
              groupedData.map((groupedRow, groupedRowIndex) => {
                let groupHeight;
                if (groupedRow.length > 1 && groupedRow.length * 46 <= 500) {
                  groupHeight = `${groupedRow.length * 46}px`;
                } else if (groupedRow.length === 1) {
                  groupHeight = "80px";
                } else {
                  groupHeight = "500px";
                }

                return (
                  <div
                    key={groupedRowIndex}
                    className={styles.ReportViewer__reportDataContainerGrouping}
                    style={{
                      overflowY:
                        groupedRowIndex === selectedGroupRowIndex
                          ? "auto"
                          : "hidden",
                      minHeight:
                        groupedRowIndex === selectedGroupRowIndex &&
                        expansionActive
                          ? groupHeight
                          : "80px",
                      maxHeight:
                        groupedRowIndex === selectedGroupRowIndex &&
                        expansionActive
                          ? "500px"
                          : "80px",
                      justifyContent:
                        groupedRowIndex === selectedGroupRowIndex &&
                        expansionActive
                          ? "flex-start"
                          : "center",
                      border:
                        groupedRowIndex === selectedGroupRowIndex &&
                        expansionActive
                          ? "1px solid #089bab"
                          : "1px solid #e8ecef",
                      cursor:
                        groupedData[groupedRowIndex].length > 1
                          ? "pointer"
                          : "default",
                    }}
                    onClick={
                      groupedData[groupedRowIndex].length > 1
                        ? () => {
                            handleGroupSelection(groupedRowIndex);
                          }
                        : () => {}
                    }
                  >
                    {groupedRow
                      .map((dataRow, dataRowIndex) => {
                        return (
                          <div
                            key={dataRowIndex}
                            className={`${styles.ReportViewer__dataRow} ${styles["ReportViewer__dataRow--grouping"]}`}
                            style={{
                              borderBottom:
                                groupedRowIndex === selectedGroupRowIndex &&
                                expansionActive &&
                                dataRowIndex !== groupedRow.length - 1
                                  ? "1px solid rgba(211, 211, 211, 0.5"
                                  : "none",
                            }}
                          >
                            {groupedData[groupedRowIndex].length > 1 &&
                              dataRowIndex === 0 &&
                              (groupedRowIndex === selectedGroupRowIndex &&
                              expansionActive ? (
                                <div
                                  className={
                                    styles.ReportViewer__expandOrCollapseIconContainer
                                  }
                                >
                                  <CollapseIcon />
                                </div>
                              ) : (
                                <div
                                  className={
                                    styles.ReportViewer__expandOrCollapseIconContainer
                                  }
                                >
                                  <ExpandIcon />
                                </div>
                              ))}

                            {dataRow.map((datum, datumIndex) => {
                              let width = columnWidths[datumIndex];

                              return (
                                <div
                                  key={datumIndex}
                                  className={
                                    styles.ReportViewer__reportDataText
                                  }
                                  style={{
                                    minWidth: width
                                      ? `${width + addedWidthAdjustment}px`
                                      : "",
                                    maxWidth: width
                                      ? `${width + addedWidthAdjustment}px`
                                      : "",
                                  }}
                                >
                                  {columnDataTypes.length &&
                                  groupedDataColumnSums.length &&
                                  groupedDataColumnSums.length ===
                                    groupedData.length &&
                                  columnDataTypes[datumIndex] === "Number" &&
                                  (!expansionActive ||
                                    selectedGroupRowIndex !== groupedRowIndex)
                                    ? groupedDataColumnSums[groupedRowIndex][
                                        datumIndex
                                      ]
                                    : datum}
                                </div>
                              );
                            })}
                          </div>
                        );
                      })
                      .filter(
                        (filteredRow, index) =>
                          index === 0 ||
                          (groupedRowIndex === selectedGroupRowIndex &&
                            expansionActive)
                      )}
                  </div>
                );
              })}
          </div>
          {(showAVGRow || showSUMRow) && (
            <div className={styles.ReportViewer__reportFooter}>
              {combinedReportFields.map((obj, index) => {
                let width = columnWidths[index];
                let sumValue;
                let averageValue;
                if (obj.sum || obj.average) {
                  sumValue = Number(getColumnSumAndAverage(index).sum).toFixed(
                    2
                  );
                  averageValue = Number(
                    getColumnSumAndAverage(index).average
                  ).toFixed(2);
                }
                return (
                  <div
                    className={styles.ReportViewer__columnSumGroup}
                    key={index}
                  >
                    {showSUMRow && (
                      <div
                        className={styles.ReportViewer__reportDataText}
                        style={{
                          minWidth: width
                            ? `${width + addedWidthAdjustment}px`
                            : "",
                          maxWidth: width
                            ? `${width + addedWidthAdjustment}px`
                            : "",
                          minHeight: "20px",
                        }}
                      >
                        <span
                          className={styles.ReportViewer__columnSumGroupHeading}
                        >
                          {index === 0 && "SUM"}
                        </span>
                        <span
                          className={styles.ReportViewer__columnSumGroupData}
                        >
                          {obj.sum ? sumValue : "--"}
                        </span>
                      </div>
                    )}
                    {showAVGRow && (
                      <div
                        className={styles.ReportViewer__reportDataText}
                        style={{
                          minWidth: width
                            ? `${width + addedWidthAdjustment}px`
                            : "",
                          maxWidth: width
                            ? `${width + addedWidthAdjustment}px`
                            : "",
                          minHeight: "20px",
                        }}
                      >
                        <span
                          className={styles.ReportViewer__columnSumGroupHeading}
                        >
                          {index === 0 && "AVG"}
                        </span>
                        <span
                          className={styles.ReportViewer__columnSumGroupData}
                        >
                          {obj.average ? averageValue : "--"}
                        </span>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );

  const MobileView = () => {
    let headingsMobile = combinedReportFieldsRegular.map((field) => {
      return field.name;
    });

    let dataMobile =
      reportData &&
      reportData.map((reportDataRow) => {
        return reportDataRow.map((reportDataRowItem) => reportDataRowItem);
      });

    return (
      <div className={styles.ReportViewer__main}>
        {showDateTimeRangePicker && (
          <div className={styles.ReportViewer__overlay}>
            <div className={styles.ReportViewer__modal}>
              <DateRangePicker
                ranges={[selectionRange]}
                onChange={handleDateRangeSelect}
                rangeColors={["#089BAB"]}
              />
              <br />
              <Button
                labelName={"Continue"}
                isPrimary={true}
                onClick={() => setShowDateTimeRangePicker(false)}
              />
            </div>
          </div>
        )}
        {error && (
          <div className={styles.ReportViewer__row}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
            <Spacer space={10} unit={"px"} />
          </div>
        )}
        <div className={styles.ReportViewer__header}>
          <div className={styles.ReportViewer__titleGroup}>
            <div className={styles.ReportViewer__backButton}>
              <img src={backIcon} onClick={onClose} />
            </div>
            <p className={styles.ReportViewer__reportTitle}>
              {reportObj.title}
            </p>
          </div>
          {!reportObj.ignoreDateRange && (
            <div className={styles.ReportViewer__dateRangeGroup}>
              <p className={styles.ReportViewer__dateRangeLabel}>
                {t("dateFilterLabel")}
              </p>
              <p
                className={styles.ReportViewer__dateRange}
                onClick={() => setShowDateTimeRangePicker(true)}
              >
                {selectionRange.startDate?.toLocaleDateString() || ""}-
                {selectionRange.endDate?.toLocaleDateString() || ""}
              </p>
            </div>
          )}
        </div>
        <div className={styles.ReportViewer__buttonGroup}>
          <div className={styles.ReportViewer__runReportButtonContainer}>
            {(!isPreview || !reportObj.ignoreDateRange) && (
              <Button
                labelName={t("runReportLabel")}
                minWidth={"123px"}
                isPrimary={true}
                onClick={handleRunReport}
              />
            )}
          </div>
        </div>
        {runReport && (
          <ProductionReportCardList
            headings={headingsMobile}
            data={dataMobile}
          />
        )}
      </div>
    );
  };

  return smScreen ? MobileView() : LargeView;
};

export default ReportViewer;
