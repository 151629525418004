import ApiService from "./ApiService";
import axios from "axios";
import { stringify } from "../functions/utilities";
import CircularStructureStringify from "circular-structure-stringify";

class LabelService extends ApiService {
  static getLabels = async (user) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels`;
      let response = await axios.get(url, rc);
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getLabel = async (user, label) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`,
        label,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static createLabel = async (
    user,
    token,
    name,
    labelType,
    machineType,
    customizedLabel,
    template
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels`;
      let response = await axios.post(url, {
        user: user,
        token: token,
        name: name,
        labelType: labelType,
        machineType: machineType,
        customizedLabel: customizedLabel,
        template: CircularStructureStringify(template),
        rc: rc,
      });
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static updateLabel = async (
    user,
    token,
    label,
    name,
    labelType,
    machineType,
    customizedLabel,
    template
  ) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`;
      const labelTemplate = label.template;
      const labelModified = {
        ...label,
        template: CircularStructureStringify(labelTemplate),
      };

      let response = await axios.put(url, {
        user: user,
        token: token,
        label: labelModified,
        name: name,
        labelType: labelType,
        machineType: machineType,
        customizedLabel: customizedLabel,
        template: CircularStructureStringify(template),
        rc: rc,
      });
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static getLabel = async (user, label) => {
    try {
      const rc = {
        headers: { "x-access-token": user.token },
      };
      let response = await axios.get(
        `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`,
        label,
        rc
      );
      return response.data;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static deleteLabel = async (user, label) => {
    try {
      let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/labels/${label._id}`;

      const rc = {
        headers: { "x-access-token": user.token },
      };
      return await axios.delete(url, rc);
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default LabelService;
