import { io } from "socket.io-client";

class DeviceCoverageController {
    constructor(user) {
        this.user = user;
    }

    initializeSocket() {
        const BASE_URL = process.env.REACT_APP_HOST || "";
        this.socket = io(`${BASE_URL}/device-coverage`);
        this.socket.on("connect", () => {
            console.log(
                `Client DeviceCoverageController Socket ${this.socket.id} is connected`
            );
        });
        this.socket.on("disconnect", () => {
            console.log(`Client DeviceCoverageController Socket ${this.socket.id} has disconnected`);
        });
        this.socket.on("coverage", (data) => this.handleCoverage(data));
    }

    releaseSocket() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }

    getDeviceCoverages = (devices, dispensingOrder, callback) => {
        this.callback = callback;
        const data = {
            devices: devices,
            dispensingOrder: dispensingOrder
        }
        this.socket.emit('get-device-coverage', data);
    }

    handleCoverage = (devices) => {
        if(this.callback){
            this.callback(devices);
        }
    }
}

export default DeviceCoverageController;
