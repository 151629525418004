import styled from 'styled-components';
import checkmarkImage from '../assets/checkmark.svg';
import React, {useEffect, useState} from "react";

//region CSS
const Popup = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 99;
  max-height: 300px;
  overflow: auto;
  background: #FFFFFF;
  border: 1px solid #EAEAEA;
  box-shadow: 0 5px 14px rgba(120, 146, 141, 0.05);
  box-sizing: border-box;
  padding:17px;
`;
const ListItem = styled.div`
  display: flex;
  align-items: center;
  height: 32px;
  border-bottom: 1px solid #EBEDF8;
  width: 100%
`;
const Checkbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border-color: #089BAB;
  border-style: solid;
  border-width: 1px;
  background-color: #ffffff;
  cursor: pointer;
  outline: none;
`;
const SelectedCheckbox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 2px;
  border-color: #089BAB;
  border-style: solid;
  border-width: 1px;
  background-color: #089BAB;
  cursor: pointer;
  outline: none;
`;
const Label = styled.p`
  margin: 0 0 0 5px;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 32px;
  color: #121A2D;
`;
//endregion

const FilterPopup = ({left, top, list, onSelect/*, onClear*/}) => {

    const [data, setData] = useState([]);

    useEffect(()=>{
        let tempData = [];
        list.forEach(item => {
            if(!tempData.some(o=>o.description === item.description)){
                tempData.push(item);
            }
        })
        setData(tempData);
    },[list])

    return (
        <Popup style={{left: left, top: top}}>
            {/*{list.some(o=>!o.selected) &&*/}
            {/*    <ListItem>*/}
            {/*        <SelectedCheckbox>*/}
            {/*            <img src={checkmarkImage} alt={'checkbox is checked'}/>*/}
            {/*        </SelectedCheckbox>*/}
            {/*        <Label>All</Label>*/}
            {/*    </ListItem>*/}
            {/*}*/}
            {/*{list.some(o=>o.selected) &&*/}
            {/*    <ListItem>*/}
            {/*        <Checkbox onClick={()=>onClear(list[0].index)}/>*/}
            {/*        <Label>All</Label>*/}
            {/*    </ListItem>*/}
            {/*}*/}
            {/* Dynamic content */}
            {data.filter(o=>o.description).sort((a,b)=>{
                if(a.description > b.description){return -1}
                if(b.description > a.description){return 1}
                return 0;
            }).map(item => {
                if(item.selected){
                    return (
                        <ListItem key={item.description}>
                            <SelectedCheckbox onClick={()=>{
                                item.selected = !item.selected
                                onSelect(item);
                            }}>
                                <img src={checkmarkImage} alt={'checkbox is checked'}/>
                            </SelectedCheckbox>
                            <Label>{item.description}</Label>
                        </ListItem>
                    )
                } else {
                    return (
                        <ListItem key={item.description}>
                            <Checkbox onClick={()=>{
                                item.selected = !item.selected
                                onSelect(item);
                            }}/>
                            <Label>{item.description}</Label>
                        </ListItem>
                    )
                }
            })}
        </Popup>
    )
}

export default FilterPopup;
