import React, { useState, useEffect, useContext } from "react";
import TextInput from "./TextInput";
import Spacer from "./Spacer";
import DropdownInput from "./DropDownInput/DropdownInput";
import axios from "axios";
import Checkbox from "./Checkbox";
import { useTranslation } from "react-i18next";
import { ScreenContext } from "../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import AddessService from "../services/AddessService";

const Address = ({
  address,
  onChange,
  showSameAsShipping,
  onSameAsShipping,
  errors,
}) => {
  const { t } = useTranslation("address");

  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState(null);
  const [addr, setAddr] = useState(address);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    AddessService.getCountries()
      .then((result) => {
        let transformedCountries = result.countries
          .sort((a, b) => {
            if (a.country < b.country) {
              return -1;
            }
            if (a.country > b.country) {
              return 1;
            }
            return 0;
          })
          .map((country) => {
            return {
              type: country.code,
              description: country.country,
              states: country.states.map((state) => {
                return {
                  type: state,
                  description: state,
                };
              }),
            };
          });
        setCountries(transformedCountries);
        setCountry(transformedCountries.find((o) => o.type === "US"));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    setAddr(address);
  }, [address]);

  return (
    <section className={"column"}>
      {showSameAsShipping && (
        <>
          <Spacer space={10} unit={"px"} />
          <section className={"row"}>
            <Checkbox
              labelName={t("sameAsShippingLabel")}
              isOn={addr ? addr.sameAsShipping : false}
              onCheck={(isOn) => {
                onSameAsShipping(isOn);
              }}
            />
          </section>
          <Spacer space={20} unit={"px"} />
        </>
      )}
      <section className={smScreen ? "column" : "row"}>
        <section className={"column"}>
          <DropdownInput
            listElements={countries}
            labelName={t("countryLabel")}
            isEnabled={true}
            value={addr ? addr.country || "" : ""}
            placeholder={""}
            validationText={errors ? errors.country : ""}
            onSelection={(value) => {
              addr.country = value.description;
              setCountry(value);
              onChange(addr);
            }}
          />
          <TextInput
            labelName={t("street1Label")}
            isEnabled={true}
            value={addr ? addr.street1 || "" : ""}
            placeholder={""}
            validationText={errors ? errors.street1 : ""}
            type={"text"}
            onChange={(value) => {
              addr.street1 = value;
              onChange(addr);
            }}
            focus={true}
          />
          <TextInput
            labelName={t("street2Label")}
            isEnabled={true}
            value={addr ? addr.street2 || "" : ""}
            placeholder={""}
            validationText={""}
            type={"text"}
            onChange={(value) => {
              addr.street2 = value;
              onChange(addr);
            }}
            focus={true}
          />
        </section>
        {lgScreen && <Spacer space={10} unit={"%"} />}
        {mdScreen && <Spacer space={20} unit={"px"} />}
        <section className={"column"}>
          <TextInput
            labelName={t("cityLabel")}
            isEnabled={true}
            value={addr ? addr.city || "" : ""}
            placeholder={""}
            validationText={errors ? errors.city : ""}
            type={"text"}
            onChange={(value) => {
              addr.city = value;
              onChange(addr);
            }}
            focus={true}
          />
          <section className={smScreen || mdScreen ? "column" : "row"}>
            <section className={mdScreen ? "row w-100" : "row w-60"}>
              <DropdownInput
                listElements={country?.states || []}
                labelName={t("stateLabel")}
                isEnabled={true}
                value={addr ? addr.state || "" : ""}
                placeholder={""}
                validationText={errors ? errors.state : ""}
                onSelection={(value) => {
                  addr.state = value.description;
                  onChange(addr);
                }}
                size={100}
              />
            </section>
            <section className={mdScreen ? "row w-5" : "row w-10"} />
            <section className={"row w-30"}>
              <TextInput
                labelName={t("zipcodeLabel")}
                isEnabled={true}
                value={addr ? addr.zipcode || "" : ""}
                placeholder={""}
                validationText={errors ? errors.zipcode : ""}
                type={"text"}
                onChange={(value) => {
                  addr.zipcode = value;
                  onChange(addr);
                }}
                focus={true}
              />
            </section>
          </section>
        </section>
      </section>
    </section>
  );
};

export default Address;
