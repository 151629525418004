import React, { useEffect } from "react";

const Text = ({
  node,
  onClick,
  onDrop,
  nodeClicked,
  nodeDragged,
  onDragOver,
  onDragStart,
  onDrag,
  selectedObjectBorderColor,
  onInputFocused,
}) => {
  useEffect(() => {
    onInputFocused(false);
  }, []);

  const position = node.nodeId;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;
  const textColor = node.textColor;
  const defaultValue = node.defaultValue;
  const font = node.font;
  const bold = node.bold;
  const italic = node.italic;
  const underline = node.underline;
  const textAlignment = node.textAlignment;
  const fontSize = node.fontSize;
  const wordWrap = node.wordWrap;
  const textLines = node.textLines;

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

  if (Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) >
    Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) >
    Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  let minWidth = widthmm.toString() + "mm";
  let height = heightmm.toString() + "mm";

  const style = {
    position: "absolute",
    cursor: "pointer",
    left: x,
    top: y,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "auto",
    minWidth: minWidth,
    height: wordWrap ? "min-content" : height,
    fontFamily: `${font}, sans-serif`,
    fontSize: `${fontSize}px`,
    color: textColor,
    fontWeight: bold ? "bold" : "normal",
    fontStyle: italic ? "italic" : "normal",
    textDecoration: underline ? "underline" : "none",
    textAlign: textAlignment ? textAlignment : "left",
    border:
      nodeClicked === node ? `2px dotted ${selectedObjectBorderColor}` : "none",
    whiteSpace: wordWrap ? "normal" : "nowrap",
    padding: "3px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: textLines,
    lineClamp: textLines,
    WebkitBoxOrient: "vertical",
    boxSizing: "border-box",
  };

  return (
    <div
      position={position}
      style={style}
      draggable={true}
      onDragOver={onDragOver}
      onDrop={nodeDragged !== node ? onDrop : () => {}}
      onDrag={onDrag}
      onDragStart={onDragStart}
      onClick={onClick}
    >
      {defaultValue}
    </div>
  );
};

export default Text;
