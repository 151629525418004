import React from "react";
import { useState, useRef, useLayoutEffect, useEffect } from "react";
import { useKeyPress } from "./InputHooks";

const ContextMenu = ({
  top,
  left,
  listItems,
  onSelect,
  maxHeight,
  isRightAligned,
  onClose,
  minWidth,
  width = "auto",
  smScreen = false,
}) => {
  const targetRef = useRef();

  const containerStyle = {
    padding: "17px",
    overflow: "auto",
    backgroundColor: "#fff",
    borderRadius: "10px",
    border: "1px solid #EAEAEA",
    boxShadow: "0px 5px 14px rgba(120, 146, 141, 0.05)",
    width: width,
    minWidth: minWidth || "auto",
    maxHeight: maxHeight || "200px",
    position: "absolute",
    top: top || 0,
    left: "5px",
    display: "flex",
    flexDirection: "column",
    zIndex: 70,
  };

  const listItemStyle = {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "auto",
    borderBottom: "1px solid #EBEDF8",
    cursor: "pointer",
    overflow: smScreen ? "hidden" : "visible",
    textOverflow: smScreen ? "ellipsis" : "clip",
    whiteSpace: smScreen ? "nowrap" : "normal",
  };

  const [hoverIndex, setHoverIndex] = useState(null);

  const listItemTextStyle = {
    fontStyle: "normal",
    fontWeight: 300,
    fontSize: "12px",
    lineHeight: "32px",
    color: "#121A2D",
    margin: 0,
  };

  const listItemHoverStyle = {
    color: "#089BAB",
  };

  // document.addEventListener('mousedown', (e)=>{
  //     if(targetRef.current && !targetRef.current.contains(e.target)){
  //         onClose();
  //     }
  // })

  const escapePressed = useKeyPress("Escape");

  useEffect(() => {
    if (escapePressed) {
      onClose();
      return null;
    }

    return () => {};
  }, [escapePressed, onClose]);

  const [leftOffset, setLeftOffset] = useState(0);

  useLayoutEffect(() => {
    if (targetRef.current) {
      setLeftOffset(
        isRightAligned ? left - targetRef.current.offsetWidth : left
      );
    }

    return () => {};
  }, [isRightAligned, left]);

  return (
    <div style={{ ...containerStyle, left: leftOffset }} ref={targetRef}>
      {listItems.length &&
        listItems.map((listItem) => {
          return (
            <div
              key={listItem.type}
              style={listItemStyle}
              onMouseEnter={() => setHoverIndex(listItem.type)}
              onMouseUp={(e) => {
                onSelect(listItem);
                e.stopPropagation();
              }}
              onMouseLeave={() => setHoverIndex(null)}
            >
              <p
                style={
                  listItem.type === hoverIndex
                    ? { ...listItemTextStyle, ...listItemHoverStyle }
                    : listItemTextStyle
                }
              >
                {listItem.description}
              </p>
            </div>
          );
        })}
    </div>
  );
};

export default ContextMenu;
