import React, { useEffect, useState, useRef } from "react";

const Rectangle = ({
  nodesRef,
  node,
  nodeClicked,
  handleUpdateNode,
  nodeDragged,
  canvasPositionData,
  children,
  onClick,
  onDragOver,
  onDrop,
  onDragStart,
  onDrag,
  selectedObjectBorderColor,
  onInputFocused,
}) => {
  useEffect(() => {
    onInputFocused(false);
  }, []);

  const mainRectangleComponent = useRef(null);

  const position = node.nodeId;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;
  const borderOn = node.borderOn;
  const borderColor = node.borderColor;

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  useEffect(() => {
    let mainRectangleComponentPositionData =
      mainRectangleComponent.current?.getBoundingClientRect();

    if (node && node.widthmm && mainRectangleComponentPositionData) {
      let mainRectangleWidth = mainRectangleComponentPositionData.width;

      let mainRectangleXPositionPx =
        (1 / 2) * canvasPositionData.width - (1 / 2) * mainRectangleWidth;

      let mainRectangleXPositionmm = mainRectangleXPositionPx * 0.2645833333;

      let tempNode = { ...node };

      tempNode.xPosmm = mainRectangleXPositionmm.toString();
      tempNode.yPosmm = "0";

      if (tempNode.nodeId === 0) {
        tempNode.childNodes.forEach((childNode) => {});
      }

      /** This prevented drag and drop during edit mode **/
      /** Check on later **/
      // if (tempNode.nodeId === 0) {
      //   tempNode.childNodes.forEach((childNode) => {
      //     childNode.parent = tempNode;
      //   });
      // }

      handleUpdateNode(0, tempNode);
    }
  }, [canvasPositionData.width, node.widthmm]);

  if (node.parent) {
    const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
    const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
    const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
    const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

    if (Number(xPosmm) < paddingLeftmm) {
      adjustedXPosmm = paddingLeftmm;
    } else if (
      Number(xPosmm) + Number(widthmm) >
      Number(node.parent.widthmm) - paddingRightmm
    ) {
      adjustedXPosmm =
        Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
    }

    if (Number(yPosmm) < paddingTopmm) {
      adjustedYPosmm = paddingTopmm;
    } else if (
      Number(yPosmm) + Number(heightmm) >
      Number(node.parent.heightmm) - paddingBottommm
    ) {
      adjustedYPosmm =
        Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
    }
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  const style = {
    cursor: "pointer",
    position: node.nodeId === 0 ? "relative" : "absolute",
    left: x,
    top: y,
    width: `${widthmm}mm`,
    height: `${heightmm}mm`,
    overflow: "hidden",
    boxSizing: "border-box",
  };

  if (node.nodeId === 0) {
    return (
      <div
        ref={mainRectangleComponent}
        position={position}
        style={
          borderOn && nodeClicked !== node
            ? { ...style, border: `1px solid ${borderColor}` }
            : borderOn && nodeClicked === node
            ? { ...style, border: `2px dotted ${selectedObjectBorderColor}` }
            : !borderOn && nodeClicked === node
            ? { ...style, border: `2px dotted ${selectedObjectBorderColor}` }
            : { ...style, border: `1px dotted rgb(167, 168, 162)` }
        }
        draggable={node.nodeId === 0 ? false : true}
        onDragOver={onDragOver}
        onDrop={
          nodeDragged !== node && node.type !== "image" ? onDrop : () => {}
        }
        onDragStart={onDragStart}
        onDrag={onDrag}
        onClick={onClick}
      >
        {children}
      </div>
    );
  } else
    return (
      <div
        position={position}
        style={
          borderOn && nodeClicked !== node
            ? { ...style, border: `1px solid ${borderColor}` }
            : borderOn && nodeClicked === node
            ? { ...style, border: `2px dotted ${selectedObjectBorderColor}` }
            : !borderOn && nodeClicked === node
            ? { ...style, border: `2px dotted ${selectedObjectBorderColor}` }
            : { ...style, border: `1px dotted rgb(167, 168, 162)` }
        }
        draggable={node.nodeId === 0 ? false : true}
        onDragOver={onDragOver}
        onDrop={
          nodeDragged !== node && node.type !== "image" ? onDrop : () => {}
        }
        onDragStart={onDragStart}
        onDrag={onDrag}
        onClick={onClick}
      >
        {children}
      </div>
    );
};

export default Rectangle;
