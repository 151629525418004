import EmailValidator from 'email-validator';

export default function validate(values) {
    let errors = {};

    if(!values.account.companyName) {
        errors.companyName = 'Company name is required'
    }

    if(values.account.accountSecurity.type === 'USERNAME' && !values.administrator.username) {
        errors.username = 'Username is required'
    }

    if(values.account.accountSecurity.type === 'USERNAME' && !values.administrator.password) {
        errors.password = 'Password is required'
    }

    if(!values.account.shippingAddress.street1) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.street1 = 'Street is required'
    }

    if(!values.account.shippingAddress.city) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.city = 'City is required'
    }

    if(!values.account.shippingAddress.state) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.state = 'State is required'
    }

    if(!values.account.shippingAddress.zipcode) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.zipcode = 'Zipcode is required'
    }

    if(!values.account.shippingAddress.country) {
        if(!errors.shippingAddress){errors.shippingAddress = {}}
        errors.shippingAddress.country = 'Country is required'
    }

    if(!values.account.billingAddress.street1) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.street1 = 'Street is required'
    }

    if(!values.account.billingAddress.city) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.city = 'City is required'
    }

    if(!values.account.billingAddress.state) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.state = 'State is required'
    }

    if(!values.account.billingAddress.zipcode) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.zipcode = 'Zipcode is required'
    }

    if(!values.account.billingAddress.country) {
        if(!errors.billingAddress){errors.billingAddress = {}}
        errors.billingAddress.country = 'Country is required'
    }

    if(!values.administrator.firstName) {
        errors.firstName = 'First name is required'
    }

    if(!values.administrator.lastName) {
        errors.lastName = 'Last name is required'
    }

    if(values.account.accountSecurity.type === 'EMAIL' && !EmailValidator.validate(values.administrator.emailAddress)) {
        errors.emailAddress = 'Email address is required'
    }

    // for(let i = 0; i < values.account.phoneNumbers; i++) {
    //     let phoneNumber = values.account.phoneNumbers[i];
    //     if(!phoneNumber) {
    //         errors.phoneNumbers.push('A number is required');
    //     } else {
    //         errors.phoneNumbers.push('');
    //     }
    // }

    return errors;
}
