import React from "react";
import { ReactComponent as ExpandIcon } from "../../../../assets/add-icon-small.svg";
import styles from "./FillListCard.module.css";

const FillListCard = ({ data, expandIcon }) => {
  return (
    <div className={styles.fillListCard__container}>
      <div className={styles.fillListCard__dataList}>
        <div className={styles.fillListCard__rowGroup}>
          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--1"]}`}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.idHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>{data.id}</div>
          </div>

          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--2"]}`}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.positionHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>
              {data.position}
            </div>
          </div>
          {expandIcon && (
            <div className={styles.productCard__expandIconContainer}>
              <ExpandIcon className={styles.productCard__expandIcon} />
            </div>
          )}
        </div>

        <div className={styles.fillListCard__rowGroup}>
          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--1"]}`}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.lastFilledHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>
              {data.lastFilled}
            </div>
          </div>

          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--2"]}`}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.userHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>
              {data.lastUser}
            </div>
          </div>
          {/* {expandIcon && (
            <div className={styles.productCard__expandIconContainer}>
              <ExpandIcon className={styles.productCard__expandIcon} />
            </div>
          )} */}
        </div>

        <div className={styles.fillListCard__rowGroup}>
          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--1"]}`}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.descriptionHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>
              {data.description}
            </div>
          </div>
        </div>

        <div className={styles.fillListCard__rowGroup}>
          <div
            className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--1"]} `}
          >
            <div className={`${styles["fillListCard__heading"]}`}>
              {data.maxHeading}
            </div>
            <div className={`${styles["fillListCard__value"]}`}>{data.max}</div>
          </div>

          <div
            className={`${styles.fillListCard__rowGroup} ${styles["fillListCard__colGroup--2"]} `}
          >
            <div
              className={`${styles.fillListCard__colGroup} ${styles["fillListCard__colGroup--2of3"]}`}
            >
              <div className={`${styles["fillListCard__heading"]}`}>
                {data.needHeading}
              </div>
              <div className={`${styles["fillListCard__value"]}`}>
                {data.need}
              </div>
            </div>

            <div className={`${styles.fillListCard__colGroup}`}>
              <div className={`${styles["fillListCard__heading"]}`}>
                {data.qohHeading}
              </div>
              <div className={`${styles["fillListCard__value"]}`}>
                {data.qoh}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FillListCard;
