import ApiService from "./ApiService";
import axios from "axios";

class ConfigService extends ApiService {
  static roles = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/roles`);
      return response.data.roles;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static pharmacyTypes = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/pharmacyTypes`);
      return response.data.pharmacyTypes;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static packagingMethods = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/packagingMethods`);
      return response.data.packagingMethods;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static stockLocations = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/stockLocations`);
      return response.data.stockLocations;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static languages = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/languages`);
      return response.data.languages;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static routes = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/routes`);
      return response.data.routes;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static dosageForms = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/dosageForms`);
      return response.data.dosageForms;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static controlLevels = async (country) => {
    try {
      let response = await axios.get(
        `${ApiService.BASE_URL}/control-levels/${country}`
      );
      return response.data.controlLevels;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static reportTemplateTree = async () => {
    try {
      let response = await axios.get(
        `${ApiService.BASE_URL}/report-template-tree`
      );
      return response.data.reportTemplateTree;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };

  static reportConcepts = async () => {
    try {
      let response = await axios.get(`${ApiService.BASE_URL}/report-concepts`);
      return response.data.reportConcepts;
    } catch (error) {
      ApiService.handleResponseError(error.response);
    }
  };
}

export default ConfigService;
