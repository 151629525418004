import React, { useRef, useEffect } from "react";
import * as ReactDOMServer from "react-dom/server";
import { BarcodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { QRCodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { DataMatrixGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import styles from "./BarcodeComponent.module.css";

const BarcodeComponent = ({ node }) => {
  const position = node.nodeId;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;
  const value = node.value;
  const symbology = node.symbology;

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
  const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
  const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
  const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

  if (Number(xPosmm >= 0) && Number(xPosmm) < paddingLeftmm) {
    adjustedXPosmm = paddingLeftmm;
  } else if (
    Number(xPosmm) + Number(widthmm) <= node.parent.widthmm &&
    Number(xPosmm) + Number(widthmm) >
      Number(node.parent.widthmm) - paddingRightmm
  ) {
    adjustedXPosmm =
      Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
  }

  if (Number(yPosmm >= 0) && Number(yPosmm) < paddingTopmm) {
    adjustedYPosmm = paddingTopmm;
  } else if (
    Number(yPosmm) + Number(heightmm) <= node.parent.heightmm &&
    Number(yPosmm) + Number(heightmm) >
      Number(node.parent.heightmm) - paddingBottommm
  ) {
    adjustedYPosmm =
      Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  let width = (widthmm * 3.7795275591).toString() + "px";
  let height = (heightmm * 3.7795275591).toString() + "px";

  const getDefaultValue = () => {
    switch (node.symbology) {
      case "Code128A":
        return node.defaultValueCode128A;
      case "Code128B":
        return node.defaultValueCode128B;
      case "Code39":
        return node.defaultValueCode39;
      case "DataMatrix":
        return node.defaultValueDataMatrix;
      case "QRBarcode":
        return node.defaultValueQRBarcode;
      default:
        return null;
    }
  };

  const style = {
    position: "absolute",
    left: x,
    top: y,
    border: "none",
  };

  return (
    <div
      className={styles.BarcodeComponent__barcodeContainer}
      position={position}
      style={style}
    >
      {symbology !== "DataMatrix" && symbology !== "QRBarcode" && (
        <BarcodeGeneratorComponent
          id={`svgBarcode-${node.nodeId}`}
          width={width}
          height={height}
          type={symbology}
          value={getDefaultValue()}
          displayText={{ text: " " }}
        ></BarcodeGeneratorComponent>
      )}
      {symbology === "DataMatrix" && (
        <DataMatrixGeneratorComponent
          id={`svgBarcode-${node.nodeId}`}
          width={width}
          height={height}
          value={getDefaultValue()}
          displayText={{ text: " " }}
        ></DataMatrixGeneratorComponent>
      )}
      {symbology === "QRBarcode" && (
        <QRCodeGeneratorComponent
          id={`svgBarcode-${node.nodeId}`}
          width={width}
          height={height}
          value={getDefaultValue()}
          displayText={{ text: " " }}
        ></QRCodeGeneratorComponent>
      )}
      {node.showValue && (
        <p className={styles.BarcodeComponent__defaultValue}>{value}</p>
      )}
    </div>
  );
};

export default BarcodeComponent;
