import React, { useEffect, useState, useContext } from "react";
import { NavbarContext } from "../../../../contexts/NavbarContext";
import InputCustomType from "../../../../components/InputCustomType/InputCustomType";
import SFDropDownList from "../../../../components/SFDropDownList/SFDropDownList";
import { ReactComponent as CheckboxChecked } from "../../svgs/checkbox-checked.svg";
import { ReactComponent as CheckboxUnchecked } from "../../svgs/checkbox-unchecked.svg";
import { ReactComponent as TrashIcon } from "../../../../assets/trash-icon.svg";
import getMappingJSONObj from "../getMappingJSONObj";
import getCanisterDataMappingDropdownList from "../getCanisterDataMappingDropdownList";
import styles from "./BarcodeProperties.module.css";

const BarcodeProperties = ({
  activeNode,
  nodes,
  onNodes,
  onShowDeleteNodeWarning,
  onInputFocused,
  labelType,
  barcodeValueError,
  onBarcodeValueError,
  onLabelModificationInProgress,
}) => {
  const [defaultValue, setDefaultValue] = useState("");

  const { handleLabelModificationInProgressViaContext } =
    useContext(NavbarContext);

  const getDefaultValue = () => {
    switch (activeNode.symbology) {
      case "Code128A":
        return activeNode.defaultValueCode128A;
        break;
      case "Code128B":
        return activeNode.defaultValueCode128B;
        break;
      case "Code39":
        return activeNode.defaultValueCode39;
        break;
      case "DataMatrix":
        return activeNode.defaultValueDataMatrix;
        break;
      case "QRBarcode":
        return activeNode.defaultValueQRBarcode;
        break;
      default:
        return null;
    }
  };

  useEffect(() => {
    const getSelectedSymbology = () => {
      const listIndex = dropdownListSymbology.findIndex(
        (item) => item.Value === activeNode.symbology
      );
      if (listIndex !== -1) {
        return dropdownListSymbology[listIndex].Item;
      } else {
        return "";
      }
    };

    let barcodeSvg = document.getElementById(`svgBarcode-${activeNode.nodeId}`)
      ?.firstChild.innerHTML;
    if (barcodeSvg) {
      if (barcodeValueError) {
        onBarcodeValueError("");
      }
    } else {
      onBarcodeValueError(
        `Invalid value for ${getSelectedSymbology(
          activeNode.symbology
        )} symbology`
      );
    }
  }, [defaultValue]);

  useEffect(() => {
    setDefaultValue(getDefaultValue());
  }, [
    activeNode.symbology,
    activeNode.defaultValueCode128A,
    activeNode.defaultValueCode128B,
    activeNode.defaultValueCode39,
    activeNode.defaultValueDataMatrix,
    activeNode.defaultValueQRBarcode,
  ]);

  const handleDataUpdate = (tempNodes) => {
    onNodes([...tempNodes]);
    onLabelModificationInProgress(true);
    handleLabelModificationInProgressViaContext(true);
  };

  const handleXPosition = (xPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].xPosmm = xPosition;
    handleDataUpdate(tempNodes);
  };

  const handleYPosition = (yPosition) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].yPosmm = yPosition;
    handleDataUpdate(tempNodes);
  };

  const handleWidth = (width) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].widthmm = width;
    handleDataUpdate(tempNodes);
  };

  const handleHeight = (height) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].heightmm = height;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion symbology dropdown
  const dropdownListSymbology = [
    { Item: "Code 128A", Value: "Code128A" },
    { Item: "Code 128B", Value: "Code128B" },
    { Item: "Code 39", Value: "Code39" },
    { Item: "Data Matrix", Value: "DataMatrix" },
    { Item: "QR Code", Value: "QRBarcode" },
  ];

  const dropdownSymbologyObj = dropdownListSymbology.find((item) => {
    return activeNode.symbology === item.Value;
  });

  const dropdownSymbologyObjItem = dropdownSymbologyObj.Item;

  // for SyncFusion symbology dropdown
  const handleSelectedSymbology = (
    selectedSymbology,
    selectedSymbologyValue
  ) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].symbology = selectedSymbologyValue;
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion Canister Data Mapping dropdown
  const handleCanisterDataMapping = (
    selectedFriendlyName,
    selectedFriendlyNamePath
  ) => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].mapping = getMappingJSONObj(
      selectedFriendlyName,
      selectedFriendlyNamePath,
      activeNode
    );
    handleDataUpdate(tempNodes);
  };

  // for SyncFusion textLines dropdown
  const dropdownListCanisterDataMapping = getCanisterDataMappingDropdownList();

  const handleDefaultValue = (value) => {
    let tempNodes = [...nodes];

    switch (activeNode.symbology) {
      case "Code128A":
        tempNodes[activeNode.nodeId].defaultValueCode128A = value;
        break;
      case "Code128B":
        tempNodes[activeNode.nodeId].defaultValueCode128B = value;
        break;
      case "Code39":
        tempNodes[activeNode.nodeId].defaultValueCode39 = value;
        break;
      case "DataMatrix":
        tempNodes[activeNode.nodeId].defaultValueDataMatrix = value;
      case "QRBarcode":
        tempNodes[activeNode.nodeId].defaultValueQRBarcode = value;
        break;
      default:
        return null;
    }
    handleDataUpdate(tempNodes);
  };

  const handleShowValue = () => {
    let tempNodes = [...nodes];
    tempNodes[activeNode.nodeId].showValue =
      !tempNodes[activeNode.nodeId].showValue;
    handleDataUpdate(tempNodes);
  };

  const handleRemoveNode = () => {
    onInputFocused(false);
    onShowDeleteNodeWarning(true);
  };

  const handleMouseEnter = () => {
    onInputFocused(true);
  };

  const handleStopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div>
      <div className={styles.BarcodeProperties__container}>
        <div
          className={styles.BarcodeProperties__trashIconContainer}
          onClick={handleRemoveNode}
        >
          <TrashIcon />
        </div>
        <div className={styles.BarcodeProperties__positionContainer}>
          <p className={styles.BarcodeProperties__generalParagraph}>
            Position <span>(mm)</span>
          </p>

          <div className={styles.BarcodeProperties__boxesContainer}>
            <div className={styles.BarcodeProperties__xBoxContainer}>
              <div
                className={styles.BarcodeProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.xPosmm}
                  onHandleUserInput={handleXPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.xPosmm,
                  }}
                />
              </div>
              <p className={styles.BarcodeProperties__generalParagraph}>x</p>
            </div>
            <div className={styles.BarcodeProperties__yBoxContainer}>
              <div
                className={styles.BarcodeProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.yPosmm}
                  onHandleUserInput={handleYPosition}
                  onMouseEnter={handleMouseEnter}
                  customType="number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.yPosmm,
                  }}
                />
              </div>
              <p className={styles.BarcodeProperties__generalParagraph}>y</p>
            </div>
          </div>
        </div>
        <div className={styles.BarcodeProperties__sizeContainer}>
          <p className={styles.BarcodeProperties__generalParagraph}>
            Size <span>(mm)</span>
          </p>
          <div className={styles.BarcodeProperties__boxesContainer}>
            <div className={styles.BarcodeProperties__widthBoxContainer}>
              <div
                className={styles.BarcodeProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.widthmm}
                  onHandleUserInput={handleWidth}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.widthmm,
                  }}
                />
              </div>
              <p className={styles.BarcodeProperties__generalParagraph}>
                width
              </p>
            </div>
            <div className={styles.BarcodeProperties__heightBoxContainer}>
              <div
                className={styles.BarcodeProperties__inputContainer}
                onClick={handleStopPropagation}
              >
                <InputCustomType
                  userInput={activeNode.heightmm}
                  onHandleUserInput={handleHeight}
                  onMouseEnter={handleMouseEnter}
                  customType="positive number"
                  enforceFocus={false}
                  maxValue={10000}
                  maxNumOfDecimals={1}
                  inputFields={{
                    type: "text",
                    value: activeNode.heightmm,
                  }}
                />
              </div>
              <p className={styles.BarcodeProperties__generalParagraph}>
                height
              </p>
            </div>
          </div>
        </div>

        {labelType === "Canister" && (
          <div className={styles.BarcodeProperties__canisterDataMapping}>
            <SFDropDownList
              id={"BarcodeProperites_canisterDataMapping"}
              label={"Canister Data"}
              defaultValue={activeNode.mapping.friendlyName}
              placeHolder={activeNode.mapping.friendlyName}
              dropDownList={dropdownListCanisterDataMapping}
              onSelection={handleCanisterDataMapping}
            />
          </div>
        )}

        <div className={styles.BarcodeProperties__symbologyContainer}>
          {barcodeValueError && (
            <div className={styles.BarcodeProperties__symbologyDropdownOverlay}>
              <div
                className={
                  styles.BarcodeProperties__symbologyDropdownArrowOverlay
                }
              ></div>
            </div>
          )}
          <SFDropDownList
            id={"TextProperites_symbology"}
            label={"Symbology"}
            defaultValue={dropdownSymbologyObjItem}
            placeHolder={"Symbology"}
            dropDownList={dropdownListSymbology}
            onSelection={handleSelectedSymbology}
          />
        </div>

        <div className={styles.BarcodeProperties__defaultValueContainer}>
          <p className={styles.BarcodeProperties__generalParagraph}>
            Default Value
          </p>
          <div
            className={styles.BarcodeProperties__inputContainer}
            onClick={handleStopPropagation}
          >
            <InputCustomType
              userInput={getDefaultValue()}
              onHandleUserInput={handleDefaultValue}
              onMouseEnter={handleMouseEnter}
              enforceFocus={false}
              inputFields={{
                type: "text",
                value: getDefaultValue(),
              }}
            />
          </div>
        </div>
        {barcodeValueError && (
          <p className={styles.BarcodeProperties__barcodeValueError}>
            {barcodeValueError}
          </p>
        )}
        <div className={styles.BarcodeProperties__showValueContainer}>
          <div
            className={styles.BarcodeProperties__showValueCheckBoxContainer}
            onClick={handleShowValue}
          >
            {activeNode.showValue ? <CheckboxChecked /> : <CheckboxUnchecked />}
          </div>
          <p className={styles.BarcodeProperties__generalParagraph}>
            Show Value
          </p>
        </div>
      </div>
    </div>
  );
};

export default BarcodeProperties;
