import React, { useEffect, useRef } from "react";
import styles from "./InputCustomType.module.css";

/* required props: userInput, onHandleUserInput */

/* 
Examples of  usage:

    <InputCustomType
      userInput={location}
      onHandleUserInput={handleLocation}
      customType="strip-multiple-spaces"
      enforceFocus={true}
      inputFields={{
        type: "text",
        value: location,
      }}
    />

    <InputCustomType
      userInput={hour}
      onHandleUserInput={handleHour}
      customType="date-time"
      styles={customInputStyles} 
      maxLength={2}
      maxValue={23}
      autoZero={true}
      inputFields={{
        type: "text",
        value: hour,
      }}
    />

    <InputCustomType
      userInput={quantity}
      onHandleUserInput={handleQuantity}
      customType="whole number"
      enforceFocus={true}
      maxValue={999999}
      inputFields={{
        type: "text",
        value: quantity,
      }}
    />
*/

const InputCustomType = (props) => {
  const inputElement = useRef(null);

  useEffect(() => {
    if (props.enforceFocus && inputElement.current) {
      inputElement.current.focus();
    }
  }, []);

  useEffect(() => {
    if (props.blankInput && props.onSetBlankInput) {
      props.onHandleUserInput("");
    }
  }, [props.blankInput]);

  const changeHandler = (e) => {
    if (props.blankInput && props.onSetBlankInput) {
      props.onSetBlankInput(false);
    }

    let valueEntered = e.target.value;
    let trimmedValue = e.target.value.trim();

    switch (props.customType) {
      case "comma-separated-list-of-numbers":
        const testInput = (input) => {
          const testList = input.split(",");
          let testPassed = true;
          const rx = new RegExp(/^\d+$/);
          try {
            testList.forEach((value, index) => {
              if (!(value === "" || value === " ")) {
                if (value.length === 2 && value[0] === "0") {
                  testList[index] = value.slice(1);
                }
                if (
                  isNaN(value) ||
                  !rx.test(parseInt(value)) ||
                  value.includes(".") ||
                  value.includes("  ") ||
                  value.includes(",  ") ||
                  (value.length >= 2 && value[0] === "0" && value[1] === "0")
                ) {
                  testPassed = false;
                }
              }
            });
          } finally {
            return testPassed;
          }
        };

        // note that trimmedValue for the comma separated list is a string like "5,7"
        if (testInput(valueEntered)) {
          const valueArray = valueEntered.split(",").map((value) => {
            if (!isNaN(value)) {
              let parsedValue = value.trim();
              // eliminate leading zero, if present
              if (parsedValue.length > 1 && parsedValue[0] === "0") {
                parsedValue = parsedValue.slice(1);
              }
              return parsedValue;
            } else return value;
          });
          const parsedValueEnteredString = valueArray.toString();
          props.onHandleUserInput(parsedValueEnteredString);
        }
        break;

      case "number":
        (trimmedValue === "." ||
          trimmedValue === "-." ||
          ((trimmedValue === "-" || !isNaN(trimmedValue)) &&
            (!props.maxNumOfDecimals ||
              trimmedValue.split(".").length <= 1 ||
              trimmedValue.split(".")[1].length <= props.maxNumOfDecimals))) &&
          props.onHandleUserInput(trimmedValue);
        break;
        break;

      case "positive number":
        (trimmedValue === "." ||
          (!isNaN(trimmedValue) &&
            Number(trimmedValue) >= 0 &&
            (!props.maxNumOfDecimals ||
              trimmedValue.split(".").length <= 1 ||
              trimmedValue.split(".")[1].length <= props.maxNumOfDecimals))) &&
          props.onHandleUserInput(trimmedValue);
        break;

      case "date-time":
        if (
          !isNaN(trimmedValue) &&
          Number(trimmedValue) >= 0 &&
          Number(trimmedValue) <= props.maxValue
        ) {
          if (
            props.autoZero &&
            trimmedValue !== "0" &&
            trimmedValue.length === 1
          ) {
            props.onHandleUserInput("0" + trimmedValue);
          } else if (
            trimmedValue[0] === "0" &&
            trimmedValue.length === props.maxLength + 1 &&
            Number(trimmedValue) <= props.maxValue
          ) {
            props.onHandleUserInput(
              trimmedValue.substring(1, props.maxLength + 1)
            );
          } else if (trimmedValue.length < props.maxLength + 1) {
            props.onHandleUserInput(trimmedValue);
          }
        }
        break;
      case "strip-multiple-spaces":
        const value = valueEntered.replace(/  +/g, " ");
        props.onHandleUserInput(value);
        break;
      case "whole number":
        if (
          !isNaN(trimmedValue) &&
          !(trimmedValue[0] === "0" && trimmedValue.length === 2) &&
          !trimmedValue.includes(".") &&
          Number(trimmedValue) >= 0 &&
          Number(trimmedValue) <= props.maxValue
        ) {
          props.onHandleUserInput(trimmedValue);
        } else if (
          !isNaN(trimmedValue) &&
          !trimmedValue.includes(".") &&
          trimmedValue[0] === "0" &&
          trimmedValue.length === 2
        ) {
          props.onHandleUserInput(trimmedValue.substring(1, 2));
        } else {
          let previousValue = props.userInput;
          props.onHandleUserInput(previousValue);
        }
        break;

      case "dollar value":
        let newValueEntered;

        let valueArray = trimmedValue?.split(".");

        if (valueArray[0].length === 3 && valueArray[0][1] === "0") {
          newValueEntered = "$" + valueArray[0][2];
          valueArray = newValueEntered;
          trimmedValue = newValueEntered;
        }

        if (
          !isNaN(trimmedValue.substring(1)) &&
          valueArray[0] !== "$." &&
          +trimmedValue.substring(1) < props.maxValue &&
          (valueArray.length < 2 || valueArray[1].length <= 2) &&
          valueArray[0] !== "$00"
        ) {
          props.onHandleUserInput(trimmedValue.substring(1));
        } else if (
          valueArray[0] === "$" &&
          valueArray.length === 2 &&
          valueArray[1].length === 0 &&
          valueArray[1] === ""
        ) {
          props.onHandleUserInput(trimmedValue.substring(1));
        }
        break;

      default:
        props.onHandleUserInput(valueEntered);
    }
  };

  // Revised on 12/06/2023 by RWD for use with PrintLabels
  // const blurHandler = () => {
  //   if (props.enforceFocus) {
  //     inputElement.current.focus();
  //   } else if (props?.blurHandler) {
  //     props.blurHandler();
  //   }
  // };

  const blurHandler = () => {
    if (props?.blurHandler) {
      props.blurHandler();
    }
  };

  const clickHandler = () => {
    if (props?.clickHandler) {
      props.clickHandler();
    }
  };

  const mouseEnterHandler = () => {
    if (props?.onMouseEnter) {
      props.onMouseEnter();
    }
  };

  return (
    <input
      className={props.styles ? null : styles.input}
      style={props.styles ? props.styles : null}
      autoComplete="off"
      ref={inputElement}
      onChange={changeHandler}
      onBlur={blurHandler}
      onClick={clickHandler}
      onMouseEnter={mouseEnterHandler}
      {...props.inputFields}
    ></input>
  );
};

export default InputCustomType;
