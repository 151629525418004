import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useContext,
} from "react";
import FillListController from "./FillListController";
import { ReactComponent as BackArrow } from "../../../assets/mobile-back-arrow.svg";
import { ReactComponent as CloseModalIcon } from "../../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../../assets/back-icon-mobile.svg";
import { ScreenContext } from "../../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./GetSite.module.css";

const GetSite = ({
  user,
  sites,
  onHandleSetCurrentAction,
  onHandleSetSites,
  onHandleSetSelectedSite,
  onClose,
}) => {
  const _controllerRef = useRef(null);

  const { handleShowSmScreenHeader, smallScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);

  const [sitesWithDevices, setSitesWithDevices] = useState([]);

  useEffect(() => {
    _controllerRef.current = new FillListController(user);
    _controllerRef.current.initializeSocket();
    _controllerRef.current.getSites(handleSites);

    return function cleanup() {
      _controllerRef.current.releaseSocket();
      _controllerRef.current = null;
    };
  }, [user]);

  const handleSites = useCallback((sites) => {
    onHandleSetSites(sites);
    const result = sites.filter((site) => site.devices.length > 0);
    setSitesWithDevices(result);
  }, []);

  const handleGoBack = () => {
    onHandleSetCurrentAction(0);
    handleShowSmScreenHeader(true);
    onClose();
  };

  const Header = smScreen ? (
    <section className={styles.getSite__headingGroup}>
      <div className={styles.getSite__backArrow} onClick={handleGoBack}>
        <BackArrowMobile className={styles.getSite__backArrow__icon} />
        <p className={styles.getSite__goBackTitle}>{"Back"}</p>
      </div>
      <p className={styles.getSite__title}>Replenishment</p>
    </section>
  ) : (
    <div className={styles.getSite__header}>
      <CloseModalIcon
        className={styles.getSite__closeIcon}
        onClick={() => {
          handleGoBack();
        }}
      />
      <BackArrow
        className={styles.getSite__backArrow}
        onClick={() => {
          handleGoBack();
        }}
      />
      <p className={styles.getSite__headerTitle}>Replenishment</p>
    </div>
  );

  let maxItemsNoScroll;
  maxItemsNoScroll = !smScreen ? 3 : 4;

  if (sitesWithDevices && sitesWithDevices.length > 0) {
    return (
      <div className={styles.getSite__container}>
        {Header}

        {sitesWithDevices && sitesWithDevices.length > 0 && (
          <div className={styles.getSite}>
            <h2 className={styles.getSite__heading}>
              {sitesWithDevices && sitesWithDevices.length > 0
                ? "Select Site"
                : null}
            </h2>
            <div
              className={
                !sitesWithDevices && sitesWithDevices.length > maxItemsNoScroll
                  ? styles["getSite__scrollArea"]
                  : smScreen
                  ? styles["getSite__scrollArea"]
                  : null
              }
            >
              <ul
                className={
                  sitesWithDevices && sitesWithDevices.length > maxItemsNoScroll
                    ? [
                        styles["getSite__list"],
                        styles["getSite__list--withScroll"],
                      ].join(" ")
                    : styles["getSite__list"]
                }
              >
                {sitesWithDevices.map((site, index) => {
                  return (
                    <li
                      key={site._id}
                      className={
                        !(
                          sitesWithDevices.length > maxItemsNoScroll &&
                          index === sites.length - 1
                        )
                          ? styles.getSite__item
                          : [
                              styles["getSite__item"],
                              styles["getSite__item--withScroll"],
                            ].join(" ")
                      }
                      onClick={() => {
                        onHandleSetSelectedSite(site);
                      }}
                    >
                      {site.name}
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.getSite}>
        <p>No Sites Found</p>
      </div>
    );
  }
};

export default GetSite;
