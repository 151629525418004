const getMappingJSONObj = (name, path, activeNode) => {
  switch (name) {
    case "Default Value":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Barcode":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Control Level":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Description":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Dosage Form":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Legend":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Manufacturer Abbreviation":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Manufacturer Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Motor Base":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Package Id":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Primary Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Product Id":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Secondary Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Strength":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "TallMan Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    case "Tertiary Name":
      return {
        friendlyName: name,
        path: path,
        value: activeNode.defaultValue,
      };
      break;
    default:
      return {
        friendlyName: "Name",
        path: "stockLocation.name",
        value: activeNode.defaultValue,
      };
  }
};

export default getMappingJSONObj;
