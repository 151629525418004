import React, { Fragment } from "react";
import styles from "./Table.module.css";

const TableSub = ({
  showHeadings,
  product,
  productStrength,
  rxNumber,
  packageId,
  physician,
  productComments,
  qtyNeeded,
}) => {
  return (
    <Fragment>
      {showHeadings && (
        <ul
          className={`${styles.subData} ${styles.subDataHeadings} ${styles.fontStyles}`}
        >
          <li className={`${styles.product} ${styles.bold500}`}>Product</li>
          {/* <li className={`${styles.productStrength} ${styles.bold500}`}>
            Strength
          </li> */}
          <li className={`${styles.rxNumber} ${styles.bold500}`}>Rx Number</li>
          <li className={`${styles.packageId} ${styles.bold500}`}>NDC</li>
          <li className={`${styles.physician} ${styles.bold500}`}>
            Prescriber
          </li>
          <li className={`${styles.totalQty} ${styles.bold500}`}>Total Qty</li>
          <li className={`${styles.qtyDestroyed} ${styles.bold500}`}>
            Qty Destroyed
          </li>
        </ul>
      )}
      <ul className={`${styles.subDataRows} ${styles.fontStyles}`}>
        <div className={styles.subDataRow}>
          <li className={styles.product}>
            {productStrength ? `${product}, ${productStrength}` : product}
          </li>
          {/* <li className={`${styles.productStrength} ${styles.alignRight}`}>
          {productStrength}
        </li> */}
          <li className={styles.rxNumber}>{rxNumber}</li>
          <li className={styles.packageId}>{packageId}</li>
          <li className={styles.physician}>{physician}</li>
          <li className={`${styles.totalQty} ${styles.alignRight}`}>
            {qtyNeeded}
          </li>
          <li className={styles.qtyDestroyed}>
            <span className={styles.qtyDestroyedLine}>____</span>
          </li>
        </div>
        <li className={styles.productComments}>{productComments}</li>
      </ul>
    </Fragment>
  );
};

export default TableSub;
