import React from "react";
import { BarcodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { QRCodeGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";
import { DataMatrixGeneratorComponent } from "@syncfusion/ej2-react-barcode-generator";

/* 
Valid symbology types 
    Code128A, Code128B, Code39, DataMatrix, QRBarcode

width and height props should be in millimeters and are converted below to pixels.    
*/

const BarcodeGenerator = ({
  id,
  symbology,
  value,
  widthmm,
  heightmm,
  text,
}) => {
  // Convert width and height from mm to pixels and put in string format
  let width = (widthmm * 3.7795275591).toString() + "px";
  let height = (heightmm * 3.7795275591).toString() + "px";

  if (symbology !== "DataMatrix" && symbology !== "QRBarcode") {
    return (
      <BarcodeGeneratorComponent
        id={id}
        width={width}
        height={height}
        type={symbology}
        value={value}
        displayText={{ text: text }}
      ></BarcodeGeneratorComponent>
    );
  } else if (symbology === "DataMatrix") {
    return (
      <DataMatrixGeneratorComponent
        id={id}
        width={width}
        height={height}
        value={value}
        displayText={{ text: text }}
      ></DataMatrixGeneratorComponent>
    );
  } else if (symbology === "QRBarcode") {
    return (
      <QRCodeGeneratorComponent
        id={id}
        width={width}
        height={height}
        value={value}
        displayText={{ text: text }}
      ></QRCodeGeneratorComponent>
    );
  } else return null;
};

export default BarcodeGenerator;
