import React from "react";
import { ReactComponent as CreateButtonIcon } from "../assets/create-button-icon.svg";
import styles from "./CreateButton.module.css";

const CreateButton = ({
  labelName,
  onClick,
  isEnabled,
  buttonNoFocus = false,
}) => {
  const grey = "#D9E1EC";
  const green = "#089BAB";

  return (
    <button style={{outline: 'none'}}
      className={
        !buttonNoFocus
          ? styles.createButton__container
          : `${styles.createButton__container} ${styles["createButton__container--noFocus"]}`
      }
      onClick={onClick}
    >
      <CreateButtonIcon
        className={styles.createButton__icon}
        stroke={isEnabled ? green : grey}
      />
      <p
        className={
          isEnabled
            ? styles.createButton__text
            : [
                styles["createButton__text"],
                styles["createButton__text--grey"],
              ].join(" ")
        }
      >
        {labelName}
      </p>
    </button>
  );
};

export default CreateButton;
