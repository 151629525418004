import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CSVLink } from "react-csv";
import { getPreferredFieldName } from "./lookups/getPreferredFieldName";
import Button from "../../components/Button";
import Spacer from "../../components/Spacer";
import backIcon from "../../assets/back_icon.svg";

//region CSS
const MyView = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const ReportExcelExporter = ({
  title,
  headers,
  groupedField,
  data,
  collectionSequence,
  onBack,
  columnSums,
  columnAverages,
  collectionFieldDictionary,
}) => {
  const [dataSet, setDataSet] = useState([]);

  let tableRowsData;

  if (!groupedField || groupedField === "No grouping") {
    tableRowsData = data;
  } else {
    tableRowsData = [];
    data.forEach((arrayOfArrays) => {
      arrayOfArrays.forEach((array) => {
        tableRowsData.push(array);
      });
    });
  }

  useEffect(() => {
    let ds = [];
    for (let row = 0; row < tableRowsData.length; row++) {
      let obj = {};
      let colIndex = 0;
      for (let col = 0; col < headers.length; col++) {
        const collectionName = collectionSequence[colIndex];
        const header = getPreferredFieldName(
          collectionName,
          headers[col],
          collectionFieldDictionary,
          false,
          false
        );
        obj[header] = tableRowsData[row][col];
        colIndex++;
      }
      ds.push(obj);
    }

    // Push column average and column sum at bottom
    let objSum = {};
    let sum;
    let objAverage = {};
    let average;
    let i;
    i = 0;

    for (let col = 0; col < headers.length; col++) {
      average = columnAverages[i];
      sum = columnSums[i];
      const header = headers[col];
      if (sum >= 0) {
        objSum[header] = sum
          .toFixed(2)
          .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
      }

      if (average >= 0) {
        objAverage[header] = average
          .toFixed(2)
          .replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, "$1");
      }

      i++;
    }

    ds.push(objSum);
    ds.push(objAverage);

    setDataSet(ds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, tableRowsData, collectionSequence, columnAverages, columnSums]);

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>Excel Exporter</ReportTitle>
        </TitleGroup>
      </Header>
      <Spacer unit={"px"} space={50} />
      <CSVLink data={dataSet}>
        <Button isPrimary={true} minWidth={"123px"} labelName={"Download"} />
      </CSVLink>
    </MyView>
  );
};

export default ReportExcelExporter;
