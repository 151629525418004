import ApiService from "./ApiService";
import axios from 'axios';
import CircularStructureStringify from 'circular-structure-stringify';

class FacilityService extends ApiService {
    static create = async (user, facility, includeLogo) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/facilities`;
            if (includeLogo) {
                url += `?image=${encodeURIComponent(includeLogo)}`;
            }

            let response = await axios.post(url, facility, rc);
            return response.data.facility;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static exists = async (user, name) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }

            let response = await axios.get(`${ApiService.BASE_URL}/accounts/${user.account._id}/facilities/exists?name=${encodeURIComponent(name)}`, rc);
            return response.data.exists;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static list = async (user, includeLogo) => {
        try{
            const rc = {
                headers: {'x-access-token': user.token}
            }
            let url = `${ApiService.BASE_URL}/accounts/${user.account._id}/facilities`;
            if (includeLogo) {
                url += `?image=${encodeURIComponent(includeLogo)}`;
            }

            let response = await axios.get(url, rc);
            return response.data.facilities;
        } catch(error) {
            ApiService.handleResponseError(error.response);
        }
    }

    static update = async (userLoggedIn, facility, includeLogo) => {
        try {
            const rc = {
                headers: {'x-access-token': userLoggedIn.token}
            }

            let json = CircularStructureStringify(facility);

            let url = `${ApiService.BASE_URL}/accounts/${userLoggedIn.account._id}/facilities/${facility._id}`;
            if (includeLogo) {
                url += `?image=${encodeURIComponent(includeLogo)}`;
            }

            let response = await axios.put(url, {facility: json}, rc);
            return response.data.facility;
        } catch (error) {
            ApiService.handleResponseError(error.response);
        }
    }
}

export default FacilityService;
