import React from "react";
import { View } from "@react-pdf/renderer";

const Rectangle = ({ node, children }) => {
  const position = node.nodeId;
  const widthmm = node.widthmm;
  const heightmm = node.heightmm;
  const xPosmm = node.xPosmm;
  const yPosmm = node.yPosmm;
  const borderOn = node.borderOn;
  const borderColor = node.borderColor;

  let adjustedXPosmm = xPosmm;
  let adjustedYPosmm = yPosmm;

  if (node.parent) {
    const paddingLeftmm = node.parent.padding.left * 0.2646; // convert px to mm
    const paddingRightmm = node.parent.padding.right * 0.2646; // convert px to mm
    const paddingTopmm = node.parent.padding.top * 0.2646; // convert px to mm
    const paddingBottommm = node.parent.padding.bottom * 0.2646; // convert px to mm

    if (Number(xPosmm) < paddingLeftmm) {
      adjustedXPosmm = paddingLeftmm;
    } else if (
      Number(xPosmm) + Number(widthmm) >
      Number(node.parent.widthmm) - paddingRightmm
    ) {
      adjustedXPosmm =
        Number(node.parent.widthmm) - Number(widthmm) - paddingRightmm;
    }

    if (Number(yPosmm) < paddingTopmm) {
      adjustedYPosmm = paddingTopmm;
    } else if (
      Number(yPosmm) + Number(heightmm) >
      Number(node.parent.heightmm) - paddingBottommm
    ) {
      adjustedYPosmm =
        Number(node.parent.heightmm) - Number(heightmm) - paddingBottommm;
    }
  }

  const x = adjustedXPosmm.toString() + "mm";
  const y = adjustedYPosmm.toString() + "mm";

  const style = {
    cursor: "pointer",
    position: node.nodeId === 0 ? "relative" : "absolute",
    left: x,
    top: y,
    width: `${widthmm}mm`,
    height: `${heightmm}mm`,
    overflow: "hidden",
  };

  return (
    <View
      position={position}
      // style={
      //   borderOn
      //     ? { ...style, border: `1px solid ${borderColor}` }
      //     : { ...style, border: `1px dotted rgb(167, 168, 162)` }
      // }
      style={
        borderOn
          ? { ...style, border: `1px solid ${borderColor}` }
          : { ...style, border: `transparent` }
      }
    >
      {children}
    </View>
  );
};

export default Rectangle;
