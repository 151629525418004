import React, { createContext, useState } from "react";

export const InventoryContext = createContext();

export function InventoryProvider(props) {
  const [selectedSite, setSelectedSite] = useState(null);
  const updateSelectedSite = (pharmacy) => setSelectedSite(pharmacy);

  const [selectedDevice, setSelectedDevice] = useState(null);
  const updateSelectedDevice = (pharmacy) => setSelectedDevice(pharmacy);

  const [selectedPharmacy, setSelectedPharmacy] = useState("");
  const updateSelectedPharmacy = (pharmacy) => setSelectedPharmacy(pharmacy);

  const [selectedFilters, setSelectedFilters] = useState([]);
  const updateSelectedFilters = (array) => setSelectedFilters(array);

  const [fromDashboardGraphClick, setFromDashboardGraphClick] = useState([]);
  const updateFromDashboardGraphClick = (value) =>
    setFromDashboardGraphClick(value);

  const [receivedStockItems, setReceivedStockItems] = useState([]);
  const updateReceivedStockItems = (value) => setReceivedStockItems(value);

  const [recordedStockItemId, setRecordedStockItemId] = useState(-1);
  const updateRecordedStockItemId = (value) => setRecordedStockItemId(value);

  const [recordedStockItemDescription, setRecordedStockItemDescription] =
    useState("");
  const updateRecordedStockItemDescription = (value) =>
    setRecordedStockItemDescription(value);

  return (
    <InventoryContext.Provider
      value={{
        selectedSite,
        updateSelectedSite,
        selectedDevice,
        updateSelectedDevice,
        selectedPharmacy,
        updateSelectedPharmacy,
        selectedFilters,
        updateSelectedFilters,
        fromDashboardGraphClick,
        updateFromDashboardGraphClick,
        receivedStockItems,
        updateReceivedStockItems,
        recordedStockItemId,
        updateRecordedStockItemId,
        recordedStockItemDescription,
        updateRecordedStockItemDescription,
      }}
    >
      {props.children}
    </InventoryContext.Provider>
  );
}
