import React, { Fragment } from "react";
import { PDFViewer, Document, Page, Text, View } from "@react-pdf/renderer";
import backIcon from "../../assets/back_icon.svg";
import { getPreferredFieldName } from "./lookups/getPreferredFieldName";
import styled from "styled-components";

//region CSS
const MyView = styled.div`
  display: flex;
  @media (min-width: 641px) and (max-width: 1007px) {
    min-width: 100vw;
  }
  @media (min-width: 1008px) {
    width: 100%;
  }
  flex-direction: column;
  box-sizing: border-box;
  padding: 30px 20px;
  max-height: 1024px;
`;
const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  min-height: 75px;
  border-bottom: 1px solid #a7bbcf;
`;
const TitleGroup = styled.div`
  display: flex;
  align-items: center;
  width: 304px;
  min-width: 304px;
`;
const BackButton = styled.img`
  cursor: pointer;
  width: 30px;
  height: 30px;
  margin-right: 20px;
`;
const ReportTitle = styled.p`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 33px;
  color: #454b60;
`;
//endregion

const PDFReportViewer = ({
  title,
  headers,
  groupedField,
  data,
  collectionSequence,
  onBack,
  columnSums,
  columnAverages,
  selectionRange,
  showAVGRow,
  showSUMRow,
  collectionFieldDictionary,
}) => {
  const pageWidth = 792;
  const columnWidthSwitchOver = 750;
  const columnWidth = pageWidth / headers.length - 1;

  const charWidthInPx = 6.4;

  let columnWidthArray = headers.map((heading) => {
    let headingArray = [heading];
    if (heading.indexOf(".") !== -1) {
      // headingArray = heading.split(".");
    } else if (heading.indexOf(" ") !== -1) {
      headingArray = heading.split(" ");
    }

    if (headingArray.length > 1) {
      return headingArray[0].length * charWidthInPx;
    } else {
      return heading.length * charWidthInPx;
    }
  });

  let tableRowsData;

  if (!groupedField || groupedField === "No grouping") {
    tableRowsData = data;
  } else {
    tableRowsData = [];
    data.forEach((arrayOfArrays) => {
      arrayOfArrays.forEach((array) => {
        tableRowsData.push(array);
      });
    });
  }

  tableRowsData.forEach((dataRow, dataIndex) => {
    dataRow.forEach((rawValue, valueIndex) => {
      let value = rawValue;

      if (typeof rawValue === "string") {
        value = rawValue.substring(0, 750);
        if (rawValue.length > 750) {
          value += "...";
        }
      }

      let valueMaxWidth = columnWidthArray[valueIndex];
      let valueArray = [value.toString().trim()];
      let indexFound = -1;

      if (value.length > 10 && typeof value === "string") {
        indexFound = value.indexOf(",");
        // if (indexFound === -1) {
        //   indexFound = value.indexOf("-");
        // }
        // if (indexFound === -1 && value.split(" ").length < 3) {
        //   indexFound = value.indexOf(" ");
        // }
      }

      if (indexFound !== -1) {
        tableRowsData[dataIndex][valueIndex] = valueArray.map((value) => value);
      }
      const stringArray = value.toString().trim().split("\n");

      stringArray.forEach((subString) => {
        if (subString.length * charWidthInPx > columnWidthArray[valueIndex]) {
          valueMaxWidth =
            subString.length * charWidthInPx <= 350
              ? subString.length * charWidthInPx
              : 350;
          columnWidthArray[valueIndex] = valueMaxWidth;
        }
      });
      // });
    });
  });

  let totalColumnWidth = 0;
  columnWidthArray.forEach((columnWidth) => {
    totalColumnWidth += columnWidth;
  });

  let headersWrapped = headers.map((heading) => {
    let headingArray = [];
    if (heading.indexOf(".") !== -1) {
      // headingArray = heading.split(".");
    } else if (heading.indexOf(" ") !== -1) {
      headingArray = heading.split(" ");
    }
    if (headingArray.length > 1) {
      return headingArray[0] + "\n" + headingArray[1];
    } else {
      return heading;
    }
  });

  const getDateString = (month, day, fullYear) => {
    if (month.length === 1) {
      month = "0" + month;
    }

    if (day.length === 1) {
      day = "0" + day;
    }

    return `${month}/${day}/${fullYear}`;
  };

  let startDate = null;
  let endDate = null;

  if (selectionRange && selectionRange?.startDate && selectionRange?.endDate) {
    const fullYearStart = selectionRange.startDate.getFullYear().toString();
    const monthStart = (selectionRange.startDate.getMonth() + 1).toString();
    const dayStart = selectionRange.startDate.getDate().toString();

    const fullYearEnd = selectionRange.endDate.getFullYear().toString();
    const monthEnd = (selectionRange.endDate.getMonth() + 1).toString();
    const dayEnd = selectionRange.endDate.getDate().toString();

    startDate = getDateString(monthStart, dayStart, fullYearStart);
    endDate = getDateString(monthEnd, dayEnd, fullYearEnd);
  }

  const Report = () => (
    <Document title={title}>
      <Page
        wrap
        size={"LETTER"}
        orientation={"landscape"}
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          boxSizing: "border-box",
          paddingTop: 20,
          paddingBottom: 20,
          paddingLeft: totalColumnWidth > columnWidthSwitchOver ? 6 : 20,
          paddingRight: totalColumnWidth > columnWidthSwitchOver ? 0 : 20,
        }}
      >
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Text
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 12,
              color: "#121A2D",
              margin: 0,
              padding: 0,
              paddingLeft:
                totalColumnWidth > columnWidthSwitchOver ? "20px" : "10px",
            }}
          >
            {title}
          </Text>
          <Text
            style={{
              fontStyle: "normal",
              fontWeight: 500,
              fontSize: 12,
              color: "#121A2D",
              margin: 0,
              padding: 0,
              paddingRight:
                totalColumnWidth > columnWidthSwitchOver ? "20px" : "10px",
            }}
          >
            {startDate && endDate && `${startDate} - ${endDate}`}
          </Text>
        </View>

        <View
          fixed
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "row",
            alignItems: "flex-end",
            borderBottom: "2px solid #089BAB",
            paddingTop: "12px",
            paddingBottom: "12px",
          }}
        >
          {headersWrapped.map((header, index) => {
            const collectionName = collectionSequence[index];
            return (
              <Text
                key={index}
                style={{
                  display: "flex",
                  flexDiretion: "column",
                  justifyContent: "flex-end",
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize:
                    totalColumnWidth < columnWidthSwitchOver ? "10px" : "10px",
                  color: "#121A2D",
                  width:
                    totalColumnWidth > columnWidthSwitchOver
                      ? columnWidthArray[index]
                      : columnWidth,
                  margin: 0,
                  padding: 0,
                  paddingRight: "3px",
                  height: "100%",
                }}
              >
                {getPreferredFieldName(
                  collectionName,
                  header,
                  collectionFieldDictionary,
                  false,
                  false
                )}
              </Text>
            );
          })}
        </View>
        {tableRowsData.map((row, r) => {
          return (
            <View
              key={r}
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                paddingTop: "5px",
                paddingBottom: "5px",
                backgroundColor: r % 2 ? "#EBEDF8" : "#ffffff",
              }}
            >
              {row.map((text, t) => {
                let textWithoutLeadingNewline;
                if (text[0] === "\n") {
                  textWithoutLeadingNewline = text.slice(1);
                } else {
                  textWithoutLeadingNewline = text;
                }
                return (
                  <Text
                    key={t}
                    style={{
                      margin: 0,
                      padding: 0,
                      paddingRight: "5px",
                      fontStyle: "normal",
                      fontWeight: 300,
                      fontSize:
                        totalColumnWidth < columnWidthSwitchOver
                          ? "10px"
                          : "9px",
                      color: "#121A2D",
                      width:
                        totalColumnWidth > columnWidthSwitchOver
                          ? columnWidthArray[t]
                          : columnWidth,
                    }}
                  >
                    {textWithoutLeadingNewline}
                  </Text>
                );
              })}
            </View>
          );
        })}

        {showSUMRow && (
          <Fragment>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 12,
                  color: "#089BAB",
                  margin: 0,
                  padding: 0,
                  paddingTop: 12,
                  paddingBottom: 6,
                }}
              >
                Sum
              </Text>
            </View>
            <View
              fixed
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "2px solid #089BAB",
                paddingTop: 10,
              }}
            >
              {columnSums.map((total, index) => {
                return (
                  <View
                    key={index}
                    fixed
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    render={({ pageNumber, totalPages }) =>
                      pageNumber === totalPages && (
                        <View>
                          <Text
                            style={{
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: 12,
                              color: "#121A2D",
                              width: columnWidth,
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            {total >= 0
                              ? total
                                  .toFixed(2)
                                  .replace(
                                    /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
                                    "$1"
                                  )
                              : "--"}
                          </Text>
                        </View>
                      )
                    }
                  />
                );
              })}
            </View>
          </Fragment>
        )}

        {showAVGRow && (
          <Fragment>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  fontStyle: "normal",
                  fontWeight: 500,
                  fontSize: 12,
                  color: "#089BAB",
                  margin: 0,
                  padding: 0,
                  paddingTop: 12,
                  paddingBottom: 6,
                }}
              >
                Average
              </Text>
            </View>
            <View
              fixed
              style={{
                display: "flex",
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                borderTop: "2px solid #089BAB",
                paddingTop: 10,
              }}
            >
              {columnAverages.map((total, index) => {
                return (
                  <View
                    key={index}
                    fixed
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                    render={({ pageNumber, totalPages }) =>
                      pageNumber === totalPages && (
                        <View>
                          <Text
                            style={{
                              fontStyle: "normal",
                              fontWeight: 700,
                              fontSize: 12,
                              color: "#121A2D",
                              width: columnWidth,
                              margin: 0,
                              padding: 0,
                            }}
                          >
                            {total >= 0
                              ? total
                                  .toFixed(2)
                                  .replace(
                                    /([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,
                                    "$1"
                                  )
                              : "--"}
                          </Text>
                        </View>
                      )
                    }
                  />
                );
              })}
            </View>
          </Fragment>
        )}

        <Text
          style={{
            margin: 0,
            fontStyle: "normal",
            fontWeight: 300,
            fontSize: 10,
            color: "#121A2D",
            position: "absolute",
            bottom: 6,
            left: 0,
            right: 0,
            textAlign: "center",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );

  return (
    <MyView>
      <Header>
        <TitleGroup>
          <BackButton src={backIcon} onClick={onBack} />
          <ReportTitle>PDF Viewer</ReportTitle>
        </TitleGroup>
      </Header>
      {/*PDF Area*/}
      <PDFViewer style={{ height: "100%" }}>
        <Report />
      </PDFViewer>
    </MyView>
  );
};

export default PDFReportViewer;
