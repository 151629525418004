export const getPreferredFieldName = (
  collectionName,
  fieldName,
  collectionFieldDictionary = {},
  showCollection = true,
  showNameUnderscores = false
) => {
  let assignedName;

  if (
    collectionName in collectionFieldDictionary &&
    collectionFieldDictionary[collectionName][fieldName]
  ) {
    let preferredFieldName = showNameUnderscores
      ? collectionFieldDictionary[collectionName][fieldName]
          .split(" ")
          .join("_")
      : collectionFieldDictionary[collectionName][fieldName];

    showCollection
      ? (assignedName = `${collectionName}: ${preferredFieldName}`)
      : (assignedName = preferredFieldName);
  } else {
    showCollection
      ? (assignedName = `${collectionName}: ${fieldName}`)
      : (assignedName = fieldName);
  }

  return assignedName;
};
