const ev54CanisterManagement_reportObj = {
  title: "",
  isEnterprise: "",
  isStandard: false,
  ignoreDateRange: true,
  collections: ["stockLocation", "stockedItem", "product"],
  fields: [
    {
      collection: "stockLocation",
      dataType: "String",
      name: "name",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "stockLocation",
      dataType: "String",
      name: "type.type",
      query: [
        {
          operation: "EQUALS TO",
          value: "EV54_CANISTER",
        },
      ],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "primaryName",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "product",
      dataType: "String",
      name: "packageId",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "stockedItem",
      dataType: "Number",
      name: "quantity",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "stockedItem",
      dataType: "String",
      name: "lotNumber",
      query: [],
      sum: false,
      average: false,
    },
    {
      collection: "stockedItem",
      dataType: "Date",
      name: "expirationDate",
      query: [],
      sum: false,
      average: false,
    },
  ],
  functionFields: [],
  sites: [],
  groupedField: "No grouping",
  showCustomReport: false,
  customizedReport: "",
};

export default ev54CanisterManagement_reportObj;
