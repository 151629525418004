import React, { createContext, useEffect, useState } from "react";

export const ScreenContext = createContext();

export function ScreenProvider(props) {
  const [showSmScreenHeader, setShowSmScreenHeader] = useState(false);

  // For Searches
  const [contextFilterText, setContextFilterText] = useState("");

  const handleShowSmScreenHeader = (boolVal) => {
    return setShowSmScreenHeader(boolVal);
  };

  const handleContextFilterText = (strVal) => {
    return setContextFilterText(strVal);
  };

  const smallScreen = { maxWidth: 640 };
  const mediumScreen = { minWidth: 641, maxWidth: 1007 };
  const largeScreen = { minWidth: 1008 };

  useEffect(() => {
    handleContextFilterText("");
  }, [smallScreen]);

  return (
    <ScreenContext.Provider
      value={{
        showSmScreenHeader,
        handleShowSmScreenHeader,
        smallScreen,
        mediumScreen,
        largeScreen,
      }}
    >
      {props.children}
    </ScreenContext.Provider>
  );
}
