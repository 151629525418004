import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import { ReactComponent as CloseIcon } from "../../assets/x.svg";
import { ReactComponent as BackArrowMobile } from "../../assets/back-icon-mobile.svg";
import ErrorBanner from "../../components/ErrorBanner";
import Spacer from "../../components/Spacer";
import Button from "../../components/Button";
import Address from "../../components/Address";
import validate from "../../validation/FacilityValidationRules";
import TextInput from "../../components/TextInput";
import ImageSelector from "../../components/ImageSelector";
import PhoneList from "../../components/PhoneList/PhoneList";
import ImageService from "../../services/ImageService";
import FacilityService from "../../services/FacilityService";
import ConfigService from "../../services/ConfigService";
import DropdownInput from "../../components/DropDownInput/DropdownInput";
import { useTranslation } from "react-i18next";
import WaitIndicator from "../../components/WaitIndicator";
import { useNavigate } from "react-router-dom";
import Accordion from "../../components/Accordion/Accordion";
import Checkbox from "../../components/Checkbox";
import { ScreenContext } from "../../contexts/ScreenContext";
import { useMediaQuery } from "react-responsive";
import styles from "./CreateFacility.module.css";

const CreateFacility = ({ user, facility }) => {
  const navigate = useNavigate();

  const { t } = useTranslation("createFacility");

  const isNew = facility == null;

  const [copyShippingAddressToBilling, setCopyShippingAddressToBilling] =
    useState(false);
  const copyShippingAddressToBillingRef = useRef(null);
  copyShippingAddressToBillingRef.current = copyShippingAddressToBilling;

  const [copyShippingAddressToMailing, setCopyShippingAddressToMailing] =
    useState(false);
  const copyShippingAddressToMailingRef = useRef(null);
  copyShippingAddressToMailingRef.current = copyShippingAddressToMailing;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [errors, setErrors] = useState({});
  const [name, setName] = useState("");
  const [facilityId, setFacilityId] = useState("");
  const [labelGroupCode, setLabelGroupCode] = useState("");
  const [logo, setLogo] = useState(null);
  const [primaryContact, setPrimaryContact] = useState({});
  const [phoneNumbers, setPhoneNumbers] = useState([
    { type: { type: "mobile", description: "Mobile" }, number: "" },
  ]);
  const [shippingAddress, setShippingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [billingAddress, setBillingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [mailingAddress, setMailingAddress] = useState({
    sameAsShipping: false,
    street1: "",
    street2: "",
    city: "",
    state: null,
    zipcode: "",
    country: null,
  });
  const [packagingMethod, setPackagingMethod] = useState(
    facility?.packagingMethod || {
      type: "POUCH",
      description: "Pouch",
    }
  );
  const [pendingFile, setPendingFile] = useState(null);
  const [packagingMethodList, setPackagingMethodList] = useState([]);

  const { smallScreen, mediumScreen, largeScreen } = useContext(ScreenContext);
  const smScreen = useMediaQuery(smallScreen);
  const mdScreen = useMediaQuery(mediumScreen);
  const lgScreen = useMediaQuery(largeScreen);

  useEffect(() => {
    if (copyShippingAddressToBilling) {
      copyShippingToBilling();
    }
  }, [copyShippingAddressToBilling]);

  useEffect(() => {
    if (copyShippingAddressToMailing) {
      copyShippingToMailing();
    }
  }, [copyShippingAddressToMailing]);

  useEffect(() => {
    if (facility) {
      setName(facility.name);
      setLogo(facility.logo);
      setPrimaryContact(facility.primaryContact);
      setPhoneNumbers(facility.phoneNumbers);
      setShippingAddress(facility.shippingAddress);
      setBillingAddress(facility.billingAddress);
      setMailingAddress(facility.mailingAddress);
      setPackagingMethod(facility.packagingMethod);
      setFacilityId(facility.facilityId || "");
      setLabelGroupCode(facility.labelGroupCode || "")
    }

    return () => {
      console.log("unmounting CreatePharmacySite");
    };
  }, [facility]);

  const isSameAddress = (add1, add2) => {
    return (
      add1?.street1 === add2?.street1 &&
      add1?.street2 === add2?.street2 &&
      add1?.city === add2?.city &&
      add1?.state === add2?.state &&
      add1?.zipcode === add2?.zipcode
    );
  };

  const copyShippingToBilling = () => {
    let clonedObj = { ...shippingAddress };
    setBillingAddress(clonedObj);
  };

  const copyShippingToMailing = () => {
    let clonedObj = { ...shippingAddress };
    setMailingAddress(clonedObj);
  };

  useEffect(() => {
    ConfigService.packagingMethods()
      .then((packagingMethods) => {
        setPackagingMethodList(packagingMethods);
      })
      .catch((error) => setError(error));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isSubmitting) {
      setIsSubmitting(true);
      let validationErrors = validate({
        name: name,
        primaryContact: primaryContact,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        logo: logo,
      });

      setErrors(validationErrors);
    }
  };

  const handleBillingCheckboxClick = () => {
    setCopyShippingAddressToBilling((prevState) => {
      return !prevState;
    });
  };

  const handleMailingCheckboxClick = () => {
    setCopyShippingAddressToMailing((prevState) => {
      return !prevState;
    });
  };

  const handleProgressUpdate = useCallback((e) => {}, []);

  const uploadPendingFile = useCallback(async () => {
    return await ImageService.create(user, pendingFile, handleProgressUpdate);
  }, [user, pendingFile, handleProgressUpdate]);

  const saveData = useCallback(() => {
    async function doSaveData() {
      try {
        if (isNew) {
          let exists = await FacilityService.exists(user, name);
          if (exists) {
            setIsSubmitting(false);
            setError(t("nameExistsMsg"));
            return;
          }

          let imageDoc = null;
          if (pendingFile) {
            imageDoc = await uploadPendingFile();
          }

          let facilityDoc = await FacilityService.create(
            user,
            {
              facility: {
                name: name,
                facilityId: facilityId,
                logo: imageDoc,
                primaryContact: primaryContact,
                phoneNumbers: phoneNumbers,
                shippingAddress: shippingAddress,
                billingAddress: billingAddress,
                mailingAddress: mailingAddress,
                packagingMethod: packagingMethod,
                labelGroupCode: labelGroupCode
              },
            },
            true
          );

          if (facilityDoc) {
            navigate("/settings/facilities", {
              state: { refresh: true },
              replace: true,
            });
          } else {
            setError(t("error1"));
          }
        } else {
          let imageDoc = facility.logo;
          if (pendingFile) {
            imageDoc = await uploadPendingFile();
          }

          let modifiedFacility = {
            ...facility,
            ...{
              name: name,
              facilityId: facilityId,
              primaryContact: primaryContact,
              phoneNumbers: phoneNumbers,
              shippingAddress: shippingAddress,
              billingAddress: billingAddress,
              mailingAddress: mailingAddress,
              logo: imageDoc,
              packagingMethod: packagingMethod,
              labelGroupCode: labelGroupCode
            },
          };
          let faciltyDoc = await FacilityService.update(
            user,
            modifiedFacility,
            true
          );
          if (faciltyDoc) {
            navigate(-1, { state: { refresh: true } });
          } else {
            setError(t("error2"));
          }
        }
      } catch (error) {
        setError(error);
      }
      setIsSubmitting(false);
    }
    doSaveData();
  }, [
    name,
    facilityId,
    user,
    primaryContact,
    phoneNumbers,
    shippingAddress,
    billingAddress,
    mailingAddress,
    uploadPendingFile,
    facility,
    isNew,
    pendingFile,
    packagingMethod,
    labelGroupCode
  ]);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      saveData();
    } else if (Object.keys(errors).length > 0) {
      setError(t("error3"));
      setIsSubmitting(false);
    }
  }, [saveData, errors, isSubmitting]);

  const addFile = async (file) => {
    setPendingFile(file);
  };

  const handleOnClose = () => {
    navigate(-1);
  };

  const deleteImage = async (image) => {
    return ImageService.delete(user, logo);
  };

  const ImageSelectorVar = (
    <ImageSelector
      isSingle={true}
      placeholder={t("logoPlaceholderText")}
      title={t("logoTitle")}
      buttonLabel={t("logoButtonLabel")}
      images={logo ? [logo] : []}
      pendingFile={pendingFile}
      onAdd={(file) => addFile(file)}
      onRemove={(image) => {
        deleteImage(image)
          .then(() => setLogo(null))
          .catch((error) => setError(error));
      }}
    />
  );

  const goBackHandler = () => {
    navigate(-1);
  };

  if (isSubmitting) {
    return <WaitIndicator />;
  } else {
    return (
      <div className={styles.createFacility__overlay}>
        {smScreen && error && (
          <div className={styles.createFacility__errorBannerContainer}>
            <ErrorBanner message={error} onClose={() => setError(null)} />
          </div>
        )}

        <section className={styles.createFacility__view}>
          {smScreen && (
            <section className={styles.createFacility__header}>
              <div
                className={styles.createFacility__backArrow}
                onClick={goBackHandler}
              >
                <BackArrowMobile
                  className={styles.createFacility__backArrowIcon}
                />
                <p className={styles.createFacility__goBackTitle}>
                  {t("goBackTitle")}
                </p>
              </div>
              <p className={styles.createFacility__title}>
                {isNew
                  ? t("addNewLabel")
                  : `${t("updateLabel")} ${facility.name}`}
              </p>
            </section>
          )}

          {!smScreen && (
            <section className={styles.createFacility__header}>
              <p className={styles.createFacility__title}>
                {isNew
                  ? t("addNewLabel")
                  : `${t("updateLabel")} ${facility.name}`}
              </p>
              <div className={styles.createFacility__formCloseContainer}>
                <CloseIcon
                  fill={"#121A2D"}
                  style={{ cursor: "pointer" }}
                  onClick={handleOnClose}
                />
              </div>
            </section>
          )}

          {!smScreen && error && (
            <div className={styles.createFacility__errorBannerContainer}>
              <ErrorBanner message={error} onClose={() => setError(null)} />
            </div>
          )}
          <div className={styles.createFacility__scroll}>
            <div className={styles.createFacility__accordions}>
              <div
                className={[
                  styles["createFacility__accordion"],
                  styles["createFacility__accordion--0"],
                ].join(" ")}
              >
                <Accordion title={t("tab0")} openByDefault={true}>
                  <div className={styles.createFacility__row}>
                    <div className={styles.createFacility__col}>
                      <TextInput
                        type={"text"}
                        onChange={(val) => setName(val)}
                        focus={true}
                        value={name}
                        validationText={errors.name}
                        placeholder={""}
                        isEnabled={true}
                        labelName={t("facilityNameLabel")}
                      />

                      {!smScreen && ImageSelectorVar}

                      {lgScreen && <Spacer space={30} unit={"px"} />}
                    </div>
                    {lgScreen && <Spacer space={5} unit={"%"} />}
                    {mdScreen && <Spacer space={30} unit={"px"} />}
                    <div className={styles.createFacility__col}>
                      <div
                        className={
                          mdScreen
                            ? styles.createFacility__col
                            : styles.createFacility__row
                        }
                      >
                        <div
                          className={styles.createFacility__col}
                          style={
                            smScreen || mdScreen
                              ? { width: "100%" }
                              : { width: "48%" }
                          }
                        >
                          <TextInput
                            type={"text"}
                            onChange={(val) => {
                              setPrimaryContact((prevState) => {
                                return { ...prevState, name: val };
                              });
                            }}
                            focus={false}
                            value={
                              primaryContact ? primaryContact.name || "" : ""
                            }
                            validationText={errors.primaryContactName || ""}
                            placeholder={""}
                            isEnabled={true}
                            labelName={t("primaryContactNameLabel")}
                          />
                        </div>

                        <Spacer space={4} unit={"%"} />

                        <div
                          className={styles.createFacility__col}
                          style={
                            smScreen || mdScreen
                              ? { width: "100%" }
                              : { width: "48%" }
                          }
                        >
                          <TextInput
                            type={"email"}
                            onChange={(val) => {
                              setPrimaryContact((prevState) => {
                                return { ...prevState, emailAddress: val };
                              });
                            }}
                            focus={false}
                            value={
                              primaryContact
                                ? primaryContact.emailAddress || ""
                                : ""
                            }
                            validationText={errors.emailAddress || ""}
                            placeholder={""}
                            isEnabled={true}
                            labelName={t("primaryContactEmailAddress")}
                          />
                        </div>

                        {smScreen && ImageSelectorVar}
                      </div>

                      <Spacer space={smScreen ? 0 : 30} unit={"px"} />

                      <PhoneList
                        phoneNumbers={phoneNumbers}
                        readonly={false}
                        errors={errors.phoneNumbers}
                        // height={error ? "200px" : "160px"}
                        height={"310px"}
                        onAdd={(phoneNumber) => {
                          setPhoneNumbers((arr) => {
                            return [...arr, phoneNumber];
                          });
                        }}
                        onRemove={(index) => {
                          let newArray = [...phoneNumbers];
                          newArray.splice(index, 1);
                          setPhoneNumbers(newArray);
                        }}
                        onChange={(index, phoneNumber) => {
                          let newArray = [...phoneNumbers];
                          newArray[index] = phoneNumber;
                          setPhoneNumbers(newArray);
                        }}
                      />
                    </div>
                  </div>
                </Accordion>
              </div>

              <div
                className={[
                  styles["createFacility__accordion"],
                  styles["createFacility__accordion--1"],
                ].join(" ")}
              >
                <Accordion title={t("tab1")}>
                  <Address
                    errors={errors.shippingAddress}
                    address={shippingAddress}
                    onChange={(address) => {
                      if (copyShippingAddressToMailingRef.current) {
                        copyShippingToMailing();
                      }
                      if (copyShippingAddressToBillingRef.current) {
                        copyShippingToBilling();
                      }
                      setShippingAddress((prevState) => {
                        return { ...prevState, ...address };
                      });
                    }}
                  />
                </Accordion>
              </div>

              <div className={styles.createFacility__groupedAccordion}>
                <div
                  className={[
                    styles["createFacility__accordion"],
                    styles["createFacility__accordion--2"],
                  ].join(" ")}
                >
                  <Accordion title={t("tab2")}>
                    <Address
                      errors={errors.billingAddress}
                      address={billingAddress}
                      onChange={(address) => {
                        setBillingAddress((prevState) => {
                          return { ...prevState, ...address };
                        });
                      }}
                    />
                  </Accordion>
                </div>

                <div
                  className={[
                    styles["createFacility__checkboxContainer"],
                    styles["createFacility__checkboxContainer--2"],
                  ].join(" ")}
                >
                  <Checkbox
                    labelName={
                      smScreen
                        ? t("sameAsShippingLabelMobile")
                        : t("sameAsShippingLabel")
                    }
                    isOn={copyShippingAddressToBilling}
                    onCheck={() => handleBillingCheckboxClick()}
                    labelColor="#089bab"
                  />
                </div>
              </div>

              <div className={styles.createFacility__groupedAccordion}>
                <div
                  className={[
                    styles["createFacility__accordion"],
                    styles["createFacility__accordion--3"],
                  ].join(" ")}
                >
                  <Accordion title={t("tab3")}>
                    <Address
                      address={mailingAddress}
                      onChange={(address) => {
                        setMailingAddress((prevState) => {
                          return { ...prevState, ...address };
                        });
                      }}
                    />
                  </Accordion>
                </div>
                <div
                  className={[
                    styles["createFacility__checkboxContainer"],
                    styles["createFacility__checkboxContainer--3"],
                  ].join(" ")}
                >
                  <Checkbox
                    labelName={
                      smScreen
                        ? t("sameAsShippingLabelMobile")
                        : t("sameAsShippingLabel")
                    }
                    isOn={copyShippingAddressToMailing}
                    onCheck={() => handleMailingCheckboxClick()}
                    labelColor="#089bab"
                  />
                </div>
              </div>

              <div className={styles.createFacility__AccordionWithTopMargin}>
                <div
                  className={[
                    styles["createFacility__accordion"],
                    styles["createFacility__accordion--4"],
                  ].join(" ")}
                >
                  <Accordion title={t("tab4")}>
                    <div className={styles.createFacility__row}>
                      <DropdownInput
                        value={packagingMethod?.description || ""}
                        labelName={t("packagingMethodLabel")}
                        validationText={""}
                        isEnabled={true}
                        placeholder={""}
                        onSelection={(listItem) => {
                          setPackagingMethod(listItem);
                        }}
                        listElements={packagingMethodList}
                      />
                      {packagingMethod?.type === "POUCH" && (
                        <>
                          <Spacer space={20} unit={"px"} />
                          <TextInput
                            isEnabled={true}
                            type={"text"}
                            value={facilityId}
                            labelName={"TruPak Location ID"}
                            onChange={(val) => setFacilityId(val)}
                          />
                        </>
                      )}
                      {packagingMethod?.type === "POUCH" && (
                          <>
                            <Spacer space={20} unit={"px"} />
                            <TextInput
                                isEnabled={true}
                                type={"text"}
                                value={labelGroupCode}
                                labelName={"TruPak Label Group"}
                                onChange={(val) => setLabelGroupCode(val)}
                            />
                          </>
                      )}
                    </div>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>

          <section className={styles.createFacility__footer}>
            <Button
              labelName={t("cancelButtonLabel")}
              isPrimary={false}
              isDisabled={false}
              onClick={() => navigate(-1)}
              minWidth={smScreen ? 122 : null}
            />
            <Spacer space={20} unit={"px"} />
            <Button
              labelName={isNew ? t("addButtonLabel") : t("updateButtonLabel")}
              isPrimary={true}
              isDisabled={false}
              onClick={handleSubmit}
              minWidth={smScreen ? 122 : 213}
            />
          </section>
        </section>
      </div>
    );
  }
};

export default CreateFacility;
