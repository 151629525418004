class Node {
  constructor(obj) {
    this._isSelected = false;
    this._isExpanded = false;
    this._parent = null;
    this._children = [];
    this._productAssociations = [];
    this._obj = obj;
    this._topParentId = -1;
  }

  get obj() {
    return this._obj;
  }

  get objId() {
    return this._obj._id;
  }

  set obj(val) {
    this._obj = val;
  }

  set parent(val) {
    this._parent = val;
  }
  get parent() {
    return this._parent;
  }

  set isSelected(val) {
    this._isSelected = val;
  }
  get isSelected() {
    return this._isSelected;
  }

  set isExpanded(val) {
    this._isExpanded = val;
  }
  get isExpanded() {
    return this._isExpanded;
  }

  set topParentId(val) {
    this._topParentId = val;
  }

  get topParentId() {
    return this._topParentId;
  }

  addproductAssociations(productsArray) {
    this._productAssociations = productsArray;
  }

  addChild(node) {
    this._children.push(node);
  }

  removeChildren() {
    this._children = [];
  }

  removeChild(node) {
    const index = this._children.find((o) => o === node);
    this._children.splice(index, 1);
  }

  get children() {
    return this._children;
  }

  get productAssociations() {
    return this._productAssociations;
  }
}

export default Node;
