import axios from "axios";
import ProductService from "../../services/ProductService";
import ConfigService from "../../services/ConfigService";
import ProductController from "../../controllers/ProductController";

class FdaNdcProductSearchController {

    constructor(user) {
        this.user = user;
        console.log(`FdaNdcProductSearchController instantiated`);
        this.baseUrl = "https://api.fda.gov/drug/ndc.json?api_key=dbbRgtlEuUffgrIQc4K4dDMaSVOD8hWY7pBjCZXf";
    }

    searchByProductNdc = (search, callback) => {
        const url = `${this.baseUrl}&search=product_ndc:"${search}"+packaging.package_ndc:"${search}"+generic_name:"${search}"+brand_name:"${search}"&sort=product_ndc:asc&limit=100`;
        console.log(url);
        axios.get(url)
            .then(response=>{
                if(response.data.results?.length > 0){
                    callback(null, response.data.results);
                }

            })
            .catch(error=>{
                console.log(error.response.statusText);
            })
    }

    addProduct = async (product, packagedProduct, callback) => {
        if(await this.productExists(this.getId(packagedProduct.package_ndc))){
            callback(`${packagedProduct.package_ndc} already exists`);
        } else {
            const data = await this.getData(product, packagedProduct);
            callback(null, data);
        }
    }
    getId = (ndc) => {
        return ndc.replace(new RegExp("-+","g"),"");
    }
    getData = async (product, packagedProduct) => {
        const productController = new ProductController(this.user);
        const packageId = productController.productFieldController.convertTo11DigitNDC(packagedProduct.package_ndc).replace(new RegExp("-+","g"),"");
        const productId = productController.productFieldController.convertTo9DigitNDC(packageId);

        return {
            packageId: packageId,
            productId: productId,
            humanReadableId: packagedProduct.package_ndc,
            productCategory: await this.getProductCategory("DRUG"),
            primaryName: productController.productFieldController.getPrimaryName(product.generic_name),
            secondaryName: productController.productFieldController.getSecondaryName(product.brand_name),
            strength: productController.productFieldController.getStrength(this.getStrength(product.active_ingredients)),
            otherIds: this.getOrderIds(product),
            ingredients: this.getIngredients(product),
            manufacturer: {
                abbrName: productController.productFieldController.getManufacturerAbbreviation(product.openfda.manufacturer_name ? product.openfda.manufacturer_name[0] : ""),
                fullName: product.openfda.manufacturer_name ? product.openfda.manufacturer_name[0] : ""
            },
            dosageForm: {
                abbrName: "",
                fullName: product.dosage_form
            },
            route: {
                abbrName: "",
                fullName: product.route[0]
            },
            revision: 1,
            images: [],
            defaultImage: null,
            packageDescription: packagedProduct.description,
            packageQuantity: Number.parseInt(packagedProduct.description.split(" ")[0]), // todo: need to parse quantity
            comments: "Created from FDA NDC lookup",
            legend: product.product_type === "HUMAN PRESCRIPTION DRUG" ? {type: "rx", description: "RX"} : {type: "otc", description: "OTC"},
            controlLevel: await this.getControlLevel(product.dea_schedule),
        }
    }

    getStrength = (activeIngredients) => {
        // 200 mg/1 example
        // 10 g/100mL
        if(activeIngredients.length === 1){
            let a = activeIngredients[0].strength.split(" "); // 10, g/100mL
            let b = a[1].split("/"); // g, 100mL
            let c = b[1] === "1" ? b[0] : a[1];
            return a[0] + c;
        } else {
            let strength = "";
            for(let i = 0; i < activeIngredients.length; i++){
                let a = activeIngredients[i].strength.split(" "); // 200, mg/1
                let b = a[1].split("/"); // mg, 1
                let c = b[1] === "1" ? b[0] : a[1];
                if(strength.length === 0){
                    strength = a[0] + c;
                } else {
                    strength += "-" + a[0] + c;
                }
            }
            return strength;
        }
    }

    getIngredients = (product) => {
        let ingredients = [];
        if(product.active_ingredients){
            for(let i = 0; i < product.active_ingredients.length; i++){
                const ingredient = product.active_ingredients[i];
                ingredients.push(`${ingredient.name} ${ingredient.strength}`);
            }
        }

        return ingredients;
    }

    getOrderIds = (product) => {
        let ids = [];
        if(product.openfda.upc){
            for(let i = 0; i < product.openfda.upc.length; i++){
                const id = product.openfda.upc[i];
                ids.push(id);
            }
        }
        return ids;
    }

    getControlLevel = async (description) => {
        if(description){
            const controlLevels = await ConfigService.controlLevels("us")
            return controlLevels.find(o => o.description.toUpperCase() === description.toUpperCase());
        } else {
            return {type: 0, description: 'Not Assigned'}
        }
    }

    getProductCategory = async (categoryName) => {
        const cats = await ProductService.getProductCategories(this.user);
        return cats.find(o => o.name.toUpperCase() === categoryName.toUpperCase());
    }


    productExists = async (ndc) => {
        return await ProductService.productExists(this.user, ndc);
    }
}

export default FdaNdcProductSearchController;
